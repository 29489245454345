import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { TabMenu } from 'primereact/tabmenu';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';

import { addShortcut, dateString, isValidUUID, sendNotification, trans, ucfirst } from 'utilities';
import { buildModuleTabs, getModuleContext, getModuleTabIndex, NotFound } from 'pages';
import { useFormState, useThemeState } from 'states';
import { isAdmin, isClient, isExpert, IRequestParams } from 'services';

import { forEach } from 'lodash';
import appUri from 'config/appUri.json';

export const ModuleWrapper = (props: any) => {
  const { appState, apim, tabs, buildTabContent, subResource, subResourceId, modulesMenu } = props;
  const { t, navigate } = apim.di();
  const { id, module, company, year, event, type, tab } = useParams();
  const formState = useFormState();
  const themeState = useThemeState();
  const dossier = appState.dossier();
  const [moduleTabs, setModuleTabs] = useState<any[]>([]);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  // Define vars from current context.
  const { baseUri, rewritedUri, editUri, header } = getModuleContext(appState, {id, module, tab, company, year, event, type, subResource, subResourceId});

  // Default tabs behaviour, if a custom behaviour is needed (i.e. role restricted tabs) pass 'tabs' prop
  useEffect(() => {
    if (!tabs && module) {
      setModuleTabs(buildModuleTabs(modulesMenu, module, navigate));
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (tab) {
      setActiveIndex(getModuleTabIndex(tab));
    }
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // Default Dossier Page Header.
  useEffect(() => {
    appState.setPageActions(
      isClient() ?
        [{ label: trans(t, 'missionRequest'), icon: 'plus', to: appUri.dos.missions.request.replace(':id', id ?? '-') }]
        : [
          { label: trans(t, 'edit'), icon: 'pencil', to: editUri },
          { label: trans(t, 'ged'), icon: 'briefcase', to: appUri.dos.page.replace(':id', id ?? '-') + '/ged' },
          { label: ucfirst(trans(t, 'mission', 2)), icon: 'chart-bar', to: appUri.dos.missions.history.replace(':id', id ?? '-') },
          { label: trans(t, 'short.add'), icon: 'bookmark', command: () => addShortcut(t, formState, appState) },
          {
            label: trans(t, 'form|dossier.notificationSend'),
            icon: 'pi pi-bell',
            command: () => sendNotification(apim, [dossier.user])
          }
        ]);
  }, [id, module, tab, appState.civilYear(), appState.exercise(), type]); // eslint-disable-line react-hooks/exhaustive-deps

  // Rewrite URL if needed.
  useEffect(() => {
    if ('' === rewritedUri) return;
    if (window.location.pathname === rewritedUri) return;

    window.history.replaceState(null, '', rewritedUri);
  }, [id, module, tab, company, year, event, appState.mission()?.id]); // eslint-disable-line react-hooks/exhaustive-deps



  const exerciceFiscalHeader = () => {
    // if appState year doesn't exist in this module, display greyed out year

    const filteredExercicesFiscaux = (appState.company()?.exercicesFiscaux || [])
      .filter((ex: any) => isExpert() || isAdmin() ? ex.active === true : ex.validated === true)
      .sort((a: any, b: any) => a.year > b.year ? -1 : 1);

    let options = filteredExercicesFiscaux;
    if (!filteredExercicesFiscaux.find((exerciceFiscal: any) => exerciceFiscal.year === appState.exercise(true))) {
      const startDate = options?.[0]?.start ? new Date(options?.[0]?.start) : '';
      const endDate = options?.[0]?.end ? new Date(options?.[0]?.end) : '';
      options.unshift({
        year: appState.exercise(true),
        start: startDate ? dateString(`${startDate.getUTCDate()}/${startDate.getUTCMonth()+1}/${appState.exercise(true)}`) :`${appState.exercise(true)}-01-01T00:00:00+00:00`,
        end: endDate ? dateString(`${endDate.getUTCDate()}/${endDate.getUTCMonth()+1}/${appState.exercise(true)}`) :`${appState.exercise(true)}-12-31T00:00:00+00:00`,
        active: false
      })
    }

    const defaultEFItemTemplate = (option: any) => (option) ?
      <div className={'flex align-items-center'}>
        {option?.active ? <>
            <div>{option?.year} -</div>
            <small className={'pl-2'}>{`${dateString(option?.start)} ${trans(t, 'au')} ${dateString(option?.end)}`}</small>
          </>
          : <>
            <div className={'text-color-secondary'}>{option?.year} -</div>
            <small className={'pl-2 text-color-secondary'}>{`${dateString(option?.start)} ${trans(t, 'au')} ${dateString(option?.end)} (${trans(t, 'nonExisting')})`}</small>
          </>
        }
      </div> : <span>{trans(t, 'form|select')}</span>
    ;
    const defaultEFValueTemplate = (option: any) => (option?.active) ? <div>{option?.year}</div> : <div className={'text-color-secondary'}>{option?.year}</div>;
    return (
      <>
        {(appState.dossier()?.companies ?? []).length > 0 && (
          <div className={'flex-none flex p-2'}>
            <div className={'field p-inputgroup m-0'}>
              <span className={'p-inputgroup-addon'}><i className={'pi pi-building'}></i></span>
              <span className={'p-float-label'}>
              <Dropdown id={'a8-company-select'} value={appState.company()?.id}
                        options={appState.dossier()?.companies || []} placeholder={trans(t, 'form|select')}
                        optionLabel={'company.latestVersion.raisonSociale'} optionValue={'company.id'}
                        onChange={(e) => {
                          appState.setCompany(e.value);
                          navigate(baseUri.replace(':company', e.value).replace(':year', appState.exercise()));
                        }}/>
              <label htmlFor={'a8-company-select'} className={'text-ucfirst'} >{trans(t, 'entreprise')}</label>
            </span>
            </div>
          </div>
        )}
        {appState.company()?.exercicesFiscaux.length > 0 && (
          <div className={'flex-none flex p-2 align-items-center'}>
            <div className={'field p-inputgroup m-0'}>
              <span className={'p-float-label'}>
                <Dropdown id={'a8-exercise-select'} value={appState.exercise(true)} optionLabel={'year'} optionValue={'year'} placeholder={trans(t, 'form|select')}
                          options={options} itemTemplate={defaultEFItemTemplate} valueTemplate={defaultEFValueTemplate}
                          onChange={(e) => {
                            appState.setExercise(e.value.toString());
                            appState.setCivilYear(e.value.toString()); // Let's keep these 2 synchronized ATM.
                            navigate(baseUri.replace(':company', appState.company()?.id).replace(':year', e.value.toString()));
                          }}/>
                <label htmlFor={'a8-exercise-select'} className={'text-ucfirst'} >{trans(t, 'exercise')}</label>
              </span>
              <span className={'p-inputgroup-addon'}><i className={'pi pi-calendar'}></i></span>
            </div>
            {appState.exercise() !== null && appState.exerciseDurationMonths() !== 12 && (
              <Tag severity='info' className={'a8-exercise-duration-months ml-3 min-w-max border-round-xl'} rounded>
                {appState.exerciseDurationMonths() + ' ' + trans(t, 'month_plural')}
              </Tag>
            )}
          </div>
        )}
      </>
    )};

  const civilYearHeader = () => {
    const getCivilYears = () => ([].concat(appState.dossier()?.civilYears || [])).sort((a: number, b: number) => a > b ? -1 : 1).map((c: number) => c.toString());
    let defaultValue = appState.civilYear();
    let options = getCivilYears();

    const nonExistingYear = options.length > 0 && !options.includes(appState.civilYear())
    // if appState year doesn't exist in this module, display greyed out year
    if (nonExistingYear) {
      options.unshift(appState.civilYear())
      defaultValue = appState.civilYear();
    }
    const indexedOptions = options.map((option, index) => ({ label: option, value: option, index }));

    const defaultCivilValueTemplate = (option: any) => (
      option?.index === 0  && nonExistingYear? <div className={'text-color-secondary'}>{option?.label}</div>
        : <div>{option?.label}</div>
    );

    const defaultCivilItemTemplate = (option: any) => (
      option?.index === 0 && nonExistingYear ?
        <div className={'flex align-items-center'}><div className={'text-color-secondary'}>{option?.label}</div><small className={'pl-2 text-color-secondary'}>{`(${trans(t, 'nonExisting')})`}</small></div>
        : <div>{option?.label}</div>
    );

    return <>
      {getCivilYears().length > 0 && (
        <div className={'flex-none flex p-2'}>
          <div className={'field p-inputgroup'}>
            <span className={'p-inputgroup-addon'}><i className={'pi pi-calendar'}></i></span>

            <span className={'p-float-label'}>
                <Dropdown id={'a8-civilYears-select'} value={defaultValue} options={indexedOptions} placeholder={trans(t, 'form|select')}
                          itemTemplate={defaultCivilItemTemplate} valueTemplate={defaultCivilValueTemplate}
                          onChange={(e) => {
                            appState.setCivilYear(e.value);
                            appState.setExercise(e.value); // Let's keep these 2 synchronized ATM.
                            navigate(baseUri.replace(':year', e.value));
                          }}/>
              <label htmlFor={'a8-civilYear-select'} className={'text-ucfirst'} >{trans(t, 'civilYear')}</label>
            </span>
          </div>
        </div>
      )}
    </>;
  };

  const getPatrimoines = useCallback(() => (appState.patrimoines() ?? [])
      .sort((a: any, b: any) => a.date > b.date ? -1 : 1)
      .map((p: any) => ({
        ...p,
        ...{ label: dateString(p.date, 'DD/MM/YYYY') }
      }))
    , [appState.patrimoines()]); // eslint-disable-line react-hooks/exhaustive-deps

  const patrimoineHeader = () => {
    let defaultValue = appState.patrimoine();
    // if appState year doesn't exist in this module, select most recent available year by default
    if ((appState.patrimoines() ?? []).length > 0 && (appState?.civilYear() || appState?.exercise())) {
      const appStateYear = appState.civilYear() || appState.exercise();
      const dossierYear = getPatrimoines().find((patrimoine: any) => new Date(patrimoine.date).getFullYear().toString() === appStateYear)?.id;
      if (dossierYear) {
        defaultValue = dossierYear;
      } else {
        defaultValue = getPatrimoines().sort((a: any, b: any) => b.date - a.date)[0]?.id;
      }
    }

    return (
      <>
        {(appState.patrimoines() ?? []).length > 0 && (
          <div className={'flex-none flex p-2'}>
            <div className={'field p-inputgroup'}>
              <span className={'p-inputgroup-addon'}><i className={'pi pi-calendar'}></i></span>

              <span className={'p-float-label'}>
                <Dropdown id={'a8-patrimoine-select'} value={defaultValue} options={getPatrimoines()} placeholder={trans(t, 'form|select')}
                          optionValue={'id'} optionLabel={'label'}
                          onChange={(e) => {
                            const patrimoineYear = new Date(getPatrimoines().find((patrimoine: any) => patrimoine.id === e.value)?.date).getFullYear()?.toString();
                            appState.setPatrimoine(e.value);
                            if (patrimoineYear) {
                              appState.setCivilYear(patrimoineYear);
                              appState.setExercise(patrimoineYear);
                            }
                          }}/>
              <label htmlFor={'a8-patrimoine-select'} className={'text-ucfirst'} >{trans(t, 'situationAu')}</label>
            </span>
            </div>
          </div>
        )}
      </>
    )
  };

  const prevoyanceHeader = () => {
    let defaultValue = appState.prevoyance();
    // if appState year doesn't exist in this module, select most recent available year by default
    if (appState?.civilYear() || appState?.exercise()) {
      const appStateYear = appState.civilYear() || appState.exercise();
      const dossierYear = appState.prevoyances().find((prevoyance: any) => prevoyance.year.toString() === (appStateYear))?.id;
      if (dossierYear) {
        defaultValue = dossierYear;
      } else {
        defaultValue = appState.prevoyances().sort((a: any, b: any) => b.year - a.year)[0]?.id;
      }
    }

    return (
      <>
        {(appState.prevoyances() ?? []).length > 0 && (
          <div className={'flex-none flex p-2'}>
            <div className={'field p-inputgroup'}>
              <span className={'p-inputgroup-addon'}><i className={'pi pi-calendar'}></i></span>

              <span className={'p-float-label'}>
                <Dropdown id={'a8-prevoyance-select'} value={defaultValue} options={appState.prevoyances()} placeholder={trans(t, 'form|select')}
                          optionValue={'id'} optionLabel={'year'} onChange={(e) => {
                  const prevoyanceYear = appState.prevoyances().find((prevoyance: any) => prevoyance.id === e.value)?.year?.toString();
                  appState.setPrevoyance(e.value);
                  if (prevoyanceYear) {
                    appState.setCivilYear(prevoyanceYear);
                    appState.setExercise(prevoyanceYear);
                  }
                }}/>
              <label htmlFor={'a8-prevoyance-select'} className={'text-ucfirst'} >{trans(t, 'situationEn')}</label>
            </span>
            </div>
          </div>
        )}
      </>
    )
  };

  const [missionTypes, setMissionTypes] = useState<any[]>([]);
  useEffect(() => {
    apim.fetchEntity({
      resourceType: 'missionTypes',
      setter: setMissionTypes,
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const missionHeader = () => {
    const missionsWithMissionTypes = (appState.dossier()?.missions ?? []).map((mission: any) => {
      const missionType = missionTypes.find((missionType: any) => missionType["@id"] === mission.missionType);
      const missionTypeCode =  missionType?.code?.toLowerCase();
      return {
        ...mission,
        missionType: missionTypeCode
      };
    });

    const missions: any[] = (missionsWithMissionTypes.filter((mission: any) => mission?.missionType === (type || module)));

    return (
      <>
        {missions?.length > 0 && (
          <div className={'flex-none flex p-2'}>
            <div className={'field p-inputgroup'}>
              <span className={'p-inputgroup-addon'}><i className={'pi pi-calendar'}></i></span>
              <span className={'p-float-label'}>
              <Dropdown
                id={'a8-missions-select'}
                value={appState.mission()?.id}
                placeholder={trans(t, 'form|select')}
                options={missions}
                optionValue={'id'}
                onChange={(e) => {
                  appState.setMission(e.value);
                  navigate(baseUri.replace(':mission', e.value));
                }}
              />
              <label htmlFor={'a8-mission-select'} className={'text-ucfirst'}>
                {trans(t, 'mission')}
              </label>
            </span>
            </div>
          </div>
        )}
      </>
    );
  };

  const dossierPHeader = () => {
    const defaultDPItemTemplate = (option: any) => (option) ?
      <div className={'flex align-items-center'}>
        <div>{option?.label} -</div>
        <small className={'pl-2'}>{option?.company}</small>
      </div> : <span>{trans(t, 'form|select')}</span>
    ;

    const getDossiersP = () => {
      const _e: any[] = [];
      forEach(appState.dossier()?.companies ?? [], (dc: any) => {
        if (!isValidUUID(dc?.company?.id)) return;

        forEach(dc.company.events ?? [], ((dp: any) => {
          _e.push({
            value: dc.company?.id + '||' + dp.id,
            date: dp.date,
            label: dateString(dp.date),
            labelFull: dateString(dp.date) + ' - ' + dc.company?.latestVersion?.raisonSociale,
            company: dc.company?.latestVersion?.raisonSociale
          });
        }));
      });

      return _e;
    }

    return <>
      {(appState.dossier()?.companies || []).length > 0 && (
        <div className={'flex-none flex p-2'}>
          <div className={'field p-inputgroup'}>
            <span className={'p-inputgroup-addon'}><i className={'pi pi-calendar'}></i></span>
            <span className={'p-float-label'}>
              <Dropdown id={'a8-event-select'} value={appState.company()?.id + '||' + appState.event()?.id} placeholder={trans(t, 'form|select')}
                        options={getDossiersP().sort((a: any, b: any) => a.date > b.date ? -1 : 1)} optionValue={'value'} optionLabel={'labelFull'} itemTemplate={defaultDPItemTemplate}
                        onChange={(e) => {
                          const _split: string[] = e.value.split('||');

                          if (_split.length > 1) {
                            appState.setCompany(_split[0]);
                            appState.setEvent(_split[1]);
                            navigate(baseUri.replace(':company', _split[0]).replace(':event', _split[1]));
                          }
                        }}/>
              <label htmlFor={'a8-company-select'} className={'text-ucfirst'} >{trans(t, 'dossierPermanent')}</label>
            </span>
          </div>
        </div>
      )}
    </>;
  };

  if (!buildTabContent) return <NotFound asBlock/>;

  const buildHeader = () => {
    switch (header) {
      case '': return <></>;
      case 'civilYearHeader': return civilYearHeader();
      case 'dossierPHeader': return dossierPHeader();
      case 'missionHeader': return missionHeader();
      case 'patrimoineHeader': return patrimoineHeader();
      case 'prevoyanceHeader': return prevoyanceHeader();
      default: return exerciceFiscalHeader();
    }
  }

  const buildTabsMenu = () => {
    return tabs ? (
      <TabMenu id={'a8-module-tabs'} model={tabs} activeIndex={activeIndex}  onTabChange={(e) => setActiveIndex(e.index)} />
    ) : (
      <TabMenu id={'a8-module-tabs'} model={moduleTabs} activeIndex={activeIndex}  onTabChange={(e) => setActiveIndex(e.index)} />
    )
  }

  return (
    <div className='card height-100 fadein animation-duration-500 flex flex-column'>
      {themeState.isBiggerDesktop() ? (
        <div className={'a8-page-header flex flex-wrap'}>
          {buildHeader()}
          <div className={'flex-auto flex p-2 justify-content-end'}>
            {buildTabsMenu()}
          </div>
        </div>
      ) : (
        <>
          <div className={'a8-page-re flex flex-wrap justify-content-center col-12'}>
            {buildTabsMenu()}
          </div>
          <div className={'a8-page-header flex flex-wrap justify-content-center mt-5'}>
            {buildHeader()}
          </div>
        </>
      )}
      {buildTabContent()}
    </div>
  );
};
