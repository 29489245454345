export interface IContract {
  id: string | null,
  fiscalRegime: string | null,
  nomCompagnie: string | null,
  nomContrat: string | null,
  cotisationPriseEnChargeParEntreprise: boolean | null,
  montantCotisation: number | null,
  chargesSociales: number | null,
  active: boolean | null,
  created: string | null,
  updated: string | null,
  deleted: string | null
}

export const emptyContract: IContract = {
  id: null,
  fiscalRegime: null,
  nomCompagnie: null,
  nomContrat: null,
  cotisationPriseEnChargeParEntreprise: null,
  montantCotisation: null,
  chargesSociales: null,
  active: null,
  created: null,
  updated: null,
  deleted: null,
} as unknown as IContract;

export interface IDossierSocialData {
  id: string | null,
  dossier: string | null,
  year: number | null,
  contractStatusRetraite: string | null,
  contractStatusPrevoyance: string | null,
  contractStatusSante: string | null,
  tauxMoyenImposition: number | null,
  tauxMarginalImposition: number | null,
  ir: number | null,
  cotisationsSocialesObligatoires: number | null,
  versementsContratsFacultatifs: number | null,
  assietteCotisationsSociales: number | null,
  montantDisponibleMadelinEtPer: number | null,
  montantUtilisable: number | null,
  volumeDisponible: number | null,
  cotisationsObligatoiresRetraite: number | null,
  partEfficiente: number | null,
  prelevementTotalNetReel: number | null,
  revenuFiscalDeReference: number | null,
  salairesSalarie: number | null,
  salairesAssimileSalarie: number | null,
  pensions: number | null,
  rentesViageres: number | null,
  remunerationsDirigeants: number | null,
  bic: number | null,
  bnc: number | null,
  ba: number | null,
  revenusFonciers: number | null,
  revenusMobiliers: number | null,
  plusValuesImmo: number | null,
  fraisReels: boolean | null,
  montantFraisReels: number | null,
  validated: boolean | null,
  suspended: boolean | null,
  active: boolean | null,
  author: string | null,
  created: string | null,
  updated: string | null,
  deleted: string | null,
  contracts: IContract[] | null
}

export const emptyDossierSocialData: IDossierSocialData = {
  id: null,
  dossier: null,
  year: null,
  contractStatusRetraite: null,
  contractStatusPrevoyance: null,
  contractStatusSante: null,
  tauxMoyenImposition: null,
  tauxMarginalImposition: null,
  ir: null,
  cotisationsSocialesObligatoires: null,
  versementsContratsFacultatifs: null,
  assietteCotisationsSociales: null,
  montantDisponibleMadelinEtPer: null,
  montantUtilisable: null,
  volumeDisponible: null,
  cotisationsObligatoiresRetraite: null,
  partEfficiente: null,
  prelevementTotalNetReel: null,
  revenuFiscalDeReference: null,
  salairesSalarie: null,
  salairesAssimileSalarie: null,
  pensions: null,
  rentesViageres: null,
  remunerationsDirigeants: null,
  bic: null,
  bnc: null,
  ba: null,
  revenusFonciers: null,
  revenusMobiliers: null,
  plusValuesImmo: null,
  fraisReels: null,
  montantFraisReels: null,
  validated: null,
  suspended: null,
  active: null,
  author: null,
  created: null,
  updated: null,
  deleted: null,
  contracts: []
} as unknown as IDossierSocialData;

export interface IDossierFiscalData {
  id: string | null,
  company: string | null,
  fiscalYear: number | null,
  valeurRemunerationNette: number | null,
  tauxRemunerationNette: number | null,
  valeurCotisationsSocialesObligatoires: number | null,
  tauxCotisationsSocialesObligatoires: number | null,
  valeurCotisationsSocialesFacultatives: number | null,
  tauxCotisationsSocialesFacultatives: number | null,
  valeurPrelevementsSociaux: number | null,
  tauxPrelevementsSociaux: number | null,
  valeurAvantagesDivers: number | null,
  tauxAvantagesDivers: number | null,
  valeurImpotsSociete: number | null,
  tauxImpotsSociete: number | null,
  valeurResultatNet: number | null,
  tauxResultatNet: number | null,
  valeurRessourcesTotales: number | null,
  nscsSurDiv: number | null,
  categorieRevenus: string | null,
  regimeImposition: string | null,
  regimeImpositionReel: string | null,
  regimeTva: string | null,
  resultatNetDonneesEconomiques: number | null,
  operationTemporaireIr: true,
  reductionImpotMadelin: true,
  finEngagement: string | null,
  regimeSocietesMeresFiliales: boolean | null,
  active: boolean | null,
  author: string | null,
  created: string | null,
  updated: string | null,
  deleted: string | null,
}

export const emptyDossierFiscalData: IDossierFiscalData = {
  id: null,
  company: null,
  fiscalYear: null,
  valeurRemunerationNette: null,
  tauxRemunerationNette: null,
  valeurCotisationsSocialesObligatoires: null,
  tauxCotisationsSocialesObligatoires: null,
  valeurCotisationsSocialesFacultatives: null,
  tauxCotisationsSocialesFacultatives: null,
  valeurPrelevementsSociaux: null,
  tauxPrelevementsSociaux: null,
  valeurAvantagesDivers: null,
  tauxAvantagesDivers: null,
  valeurImpotsSociete: null,
  tauxImpotsSociete: null,
  valeurResultatNet: null,
  tauxResultatNet: null,
  valeurRessourcesTotales: null,
  nscsSurDiv: null,
  categorieRevenus: null,
  regimeImposition: null,
  regimeImpositionReel: null,
  regimeTva: null,
  resultatNetDonneesEconomiques: null,
  operationTemporaireIr: null,
  reductionImpotMadelin: null,
  finEngagement: null,
  regimeSocietesMeresFiliales: null,
  active: true,
  author: null,
  created: null,
  updated: null,
  deleted: null,
} as unknown as IDossierFiscalData;
