import { AxiosResponse } from 'axios';
import { IRequestParams, subscribe } from 'services';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import Cookies, { CookieAttributes } from 'js-cookie';

import { encryptData, decryptData, removeLocalStorageItem, isValidUUID, uuidFromIri } from 'utilities';
import { forEach } from 'lodash';

import appConfig from 'config/appConfig.json';
import apimConfig from 'config/apimConfig.json';
import appUri from 'config/appUri.json';

const debugMode = process.env.REACT_APP_DEBUG_MODE ?? '0';
const apimUrl = process.env.REACT_APP_APIM_URL ?? '';
const appId = process.env.REACT_APP_ID ?? '';
const domain = process.env.REACT_APP_DOMAIN ?? 'atome8.fr';

export const getTokens = () => {
  const jsonUser = getUserFromLocalStorage();
  return (jsonUser && jsonUser.tokens) ? {
    accessToken: jsonUser.tokens.accessToken ?? null,
    refreshToken: jsonUser.tokens.refreshToken ?? null,
    expire: jsonUser.tokens.expire ?? null
  } : null;
};

export const getPreviousTokens = () => {
  const jsonUser = getUserFromLocalStorage();
  return (jsonUser && jsonUser.previousTokens) ? {
    accessToken: jsonUser.previousTokens.accessToken ?? null,
    refreshToken: jsonUser.previousTokens.refreshToken ?? null,
    expire: jsonUser.previousTokens.expire ?? null
  } : null;
};

export const ensureTokens = async (client: any) => {
  const tokens: any = getTokens();
  const now: number = Math.floor(Date.now() / 1000);

  if (tokens?.expire && (Number(tokens?.expire) > now)) return;

  const forceRedirect = () => {
    cleanLocalStorage();
    return window.location.replace(appUri.sys.login);
  }

  if (!tokens?.refreshToken) return forceRedirect();

  const res: AxiosResponse = await client.post(
    apimUrl + '/token/refresh',
    JSON.stringify({ refreshToken: tokens?.refreshToken })
  );

  if (!res?.data?.refreshToken || !res?.data?.token) return forceRedirect();

  const jsonUser = getUserFromLocalStorage();
  if (jsonUser && jsonUser.tokens) {
    const payload = jwtDecode<JwtPayload>(res?.data?.token);
    if (payload && payload.exp) {
      jsonUser.tokens = {
        accessToken: res.data.token,
        refreshToken: res.data.refreshToken,
        expire: payload.exp
      };

      updateSession(jsonUser);
    } else return forceRedirect();
  } else return forceRedirect();
};

export const login = (apim: any) => {
  apim.call({
    resourceType: 'authorization',
    action: 'login',
    auth: false,
    data: {
      redirectionUrl:
        window.location.protocol +
        '//' +
        window.location.host +
        apimConfig.callbacks.login
    },
    success: (res: AxiosResponse) => {
      if (res.data && res.data.authorizationUrl) {
        window.location = res.data.authorizationUrl;
      }
    },
  } as IRequestParams);
};

export const accountLink = (apim: any, userId: string|null, type: string = 'msgraph') => {
  if (!userId) return;

  apim.call({
    resourceType: 'fid',
    action: 'link',
    notif: false,
    data: {
      type,
      userId,
      redirectionUrl:
        window.location.protocol +
        '//' +
        window.location.host +
        apimConfig.callbacks.accountLink
    },
    success: (res: AxiosResponse) => {
      if (res.data && res.data.authorizationUrl) {
        window.location = res.data.authorizationUrl;
      }
    }
  } as IRequestParams);
};

export const loginCallback = (apim: any, code: string, state: string, userStateWrapper: any) => {
  apim.call({
    resourceType: 'authorization',
    action: 'loginCallback',
    auth: false,
    data: { code: code, state: state },
    success: (res: AxiosResponse) => {
      if (res?.data && res?.data?.user && res?.data?.jwt) {
        loginCallbackSuccess(apim, userStateWrapper, res);
      }
    },
  } as IRequestParams);
};

export const loginCallbackSuccess = (apim: any, userStateWrapper: any, response: any) => {
  const { navigate } = apim.di();

  cleanLocalStorage();
  userStateWrapper.populateUserFromProvider(response.data);

  // Handle SSO events.
  subscribe(['/users/' + response?.data?.user?.id], ((m: any) => {
    if (!m.data) return;

    const data = JSON.parse(m.data);
    // Only login/out events ATM.
    if (!['login', 'logout'].includes(data?.event)) return;
    // Do not handle event from same source!
    if ('microservice:' + appId === data.source) return;

    // Handle logout from other service (GT).
    if ('logout' === data?.event) {
      cleanLocalStorage();
      userStateWrapper.logoutUser();
    }

    // Simply redirect to home in order to refresh session status.
    window.location.replace(appUri.home);
  }))

  navigate(apimConfig.callbacks.loginSuccess);
}

export const accountLinkCallback = (apim: any, type: string, code: string, state: string, sessionState: string, userStateWrapper: any) => {
  const { navigate } = apim.di();

  if (!userId) return navigate(appUri.home);

  apim.call({
    resourceType: 'fid',
    action: 'linkCallback',
    notif: false,
    data: { code, state, sessionState, type, userId: userId() },
    success: (res: AxiosResponse) => {
      if (res?.data && res?.data?.jwt) {
        navigate(appUri.usr.edit.replace(':id', userId()));
      }
    },
  } as IRequestParams);
};

export const logout = (apim: any, userStateWrapper: any) => {
  apim.call({
    resourceType: 'authorization',
    action: 'logout',
    data: {
      redirectionUrl: window.location.protocol + '//' + window.location.host + apimConfig.callbacks.logout
    },
    notifSuccess: false,
    success: (res: AxiosResponse) => {
      cleanLocalStorage();
      userStateWrapper.logoutUser();

      if (res?.data && res?.data?.logoutUrl) {
        window.location = res.data.logoutUrl;
      }
    }
  } as IRequestParams);
};

export const accountUnlink = (apim: any, type: string, userStateWrapper: any) => {}

export const impersonate = (apim: any, userStateWrapper: any, targetUser: any) => {
  apim.call({
    resourceType: 'impersonation',
    action: 'impersonate',
    notifSuccess: false,
    data: {
      targetUser: targetUser.id,
    },
    success: (res: AxiosResponse) => {
      if (res?.data && res?.data?.user && res?.data?.jwt) {
        impersonateCallbackSuccess(apim, userStateWrapper, res);
      }
    },
  } as IRequestParams);
};

export const impersonateCallbackSuccess = (apim: any, userStateWrapper: any, response: any) => {
  cleanLocalStorage();
  userStateWrapper.populateUserFromProvider(response.data);

  // Handle SSO events.
  subscribe(['/users/' + response?.data?.user?.id], ((m: any) => {
    if (!m.data) return;

    const data = JSON.parse(m.data);
    // Only login/out events ATM.
    if (!['login', 'logout'].includes(data?.event)) return;
    // Do not handle event from same source!
    if ('microservice:' + appId === data.source) return;

    // Handle logout from other service (GT).
    if ('logout' === data?.event) {
      cleanLocalStorage();
      userStateWrapper.logoutUser();
    }

    // Simply redirect to home in order to refresh session status.
    window.location.replace(appUri.home);
  }))

  // Simply redirect to callback page in order to refresh session status.
  window.location.replace(apimConfig.callbacks.impersonateSuccess);
}

export const isImpersonating = (): boolean => {
  const user = getUserFromLocalStorage();
  return isValidUUID(user.previousUserId);
};

export const cancelImpersonation = (apim: any, userStateWrapper: any): void => {
  const currentUser = getUserFromLocalStorage();
  const previousTokens: any = getPreviousTokens();
  if (!isValidUUID(currentUser.previousUserId)) {
    return;
  }

  apim.fetchEntity({
    resourceType: 'users',
    id: uuidFromIri(currentUser.previousUserId),
    notifSuccess: false,
    success: (res: AxiosResponse) => {
      if (res?.data) {
        const previousUser = res?.data;
        const responseData: any = {
          user: previousUser,
          accessToken: previousTokens.accessToken,
          refreshToken: previousTokens.refreshToken,
          metadata: {
            dossier: currentUser.dossier,
            societesExperts: currentUser.societesExperts,
          }
        };

        cleanLocalStorage();
        userStateWrapper.populateUserFromProvider(responseData);

        // Handle SSO events.
        subscribe(['/users/' + previousUser.id], ((m: any) => {
          if (!m.data) return;

          const data = JSON.parse(m.data);
          // Only login/out events ATM.
          if (!['login', 'logout'].includes(data?.event)) return;
          // Do not handle event from same source!
          if ('microservice:' + appId === data.source) return;

          // Handle logout from other service (GT).
          if ('logout' === data?.event) {
            cleanLocalStorage();
            userStateWrapper.logoutUser();
          }

          // Simply redirect to home in order to refresh session status.
          window.location.replace(appUri.home);
        }))

        // Simply redirect to callback page in order to refresh session status.
        window.location.replace(apimConfig.callbacks.impersonateSuccess);
      }
    }
  } as IRequestParams).then();
};

export const getUserFromLocalStorage = () => {
  const c: string = Cookies.get(appConfig.keys.session) ?? '';
  if ('' === c) return {};

  return JSON.parse('1' !== debugMode ? decryptData(c) : c);
}

// @TODO MGD move these getters into userState
export const userId = () => getUserFromLocalStorage()?.id ?? null;
export const userDossierId = () => getUserFromLocalStorage()?.dossier ?? null;
export const isClient = () => hasRole('ROLE_CLIENT') && !isExpert() && !isAdmin();
export const isExpert = () => hasRole('ROLE_EXPERT') || isExpertAdmin();
export const isExpertAdmin = () => hasRole('ROLE_EXPERT_ADMIN');
export const isAdmin = () => hasRole('ROLE_ADMIN') || isSuperAdmin();
export const isSuperAdmin = () => hasRole('ROLE_SUPER_ADMIN');

export const getRoles = () => {
  const user = getUserFromLocalStorage();
  if (user) {
    return (user.roles ?? [])
      .map((r: any) => r.toUpperCase());
  }

  return false;
};

export const hasRole = (role: string) => {
  const user = getUserFromLocalStorage();
  if (user) {
    return (user.roles ?? [])
      .map((r: any) => r.toUpperCase())
      .includes(role.toUpperCase());
  }

  return false;
};

export const cleanLocalStorage = () => {
  // Let's clean a bit local storage
  forEach(Object.keys(localStorage), (key: string) => {
    if (!key.startsWith('a8')) return;

    removeLocalStorageItem(key, false);
  });
}

export const updateSession = (user: any) => {
  const expiresAt = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
  const body: string = '1' !== debugMode ? encryptData(JSON.stringify(user)) : JSON.stringify(user);

  Cookies.set(appConfig.keys.session, body, {
    // httpOnly: true, // <-- Interdit l’utilisation du cookie côté client. A ne pas mettre pour A8 du coup..!
    secure: true,
    expires: expiresAt,
    sameSite: 'lax',
    path: '/',
    domain
  } as CookieAttributes);

  return user;
}

export const resetSession = () => Cookies.remove(appConfig.keys.session, { path: '/', domain });
