import { trans } from 'utilities';
import { isClient } from 'services';
import { useAppState } from 'states';

import appUri from 'config/appUri.json';

export const MissionsMenu = (props: any) => {
  const { apim } = props;
  const { t } = apim.di();
  const appState = useAppState();

  return isClient() ? [
    {
      label: trans(t, 'menu|menu.missions.title'),
      items: [
        {
          label: trans(t, 'menu|menu.nouvelle_mission'),
          icon: 'plus-circle',
          to: appUri.dos.missions.request.replace(':id', appState.dossier()?.id)
        },
        {
          label: trans(t, 'menu|menu.mes_missions'),
          icon: 'chart-bar',
          to: appUri.dos.missions.history.replace(':id', appState.dossier()?.id)
        }
      ]
    }
  ] : []
};
