import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import { asDate, iri, trans, ucfirst } from 'utilities';
import { amountCell, amountEditor, DatatableWrapper, dateTimeCell, maskEditor } from 'components';
import { IRequestParam, IRequestParams } from 'services';

export const ContractCotisationsDatatable = (props: any) => {
  const { apim, data, context, contractType, contractResourceType, title, editMode, refresher } = props;
  const { t } = apim.di();

  const resourceType: string = 'contractCotisations';
  const params: IRequestParam[] = [{label: contractType, value: data?.id}];

  const addData: any = {};
  addData[contractType] = iri(contractResourceType, data?.id);

  const [souscripteurs, setSouscripteurs] = useState<any[]>([]);
  useEffect(() => {
    apim.fetchEntities({
      resourceType: 'contractSouscripteurs',
      cache: false,
      params: [{label: contractType, value: data?.id}],
      setter: setSouscripteurs
    } as IRequestParams).then();
  }, [data, context, refresher]); // eslint-disable-line react-hooks/exhaustive-deps

  const getTitle = (souscripteur: any) => ucfirst(souscripteur?.personnePhysique?.firstName ?? souscripteur?.personnePhysique?.lastName ?? '').substring(0, 3) + '.';
  const getSouscripteur1 = useMemo(() => souscripteurs.length > 0 ? getTitle(souscripteurs[0]) ?? 'P1' : 'P1'
  , [souscripteurs]); // eslint-disable-line react-hooks/exhaustive-deps
  const getSouscripteur2 = useMemo(() => souscripteurs.length > 1 ? getTitle(souscripteurs[1]) ?? 'P2' : ''
    , [souscripteurs]); // eslint-disable-line react-hooks/exhaustive-deps

  const headerGroup = souscripteurs.length > 1 ? (
      <ColumnGroup>
        <Row>
          <Column header={ucfirst(trans(t, 'table|patrimoine_headers.historique'))} colSpan={3} alignHeader={'center'}/>
          <Column header={ucfirst(trans(t, 'table|patrimoine_headers.age'))} colSpan={2} alignHeader={'center'}/>
          <Column header={ucfirst(trans(t, 'table|patrimoine_headers.exo.757990'))} colSpan={2} alignHeader={'center'}/>
          <Column header={ucfirst(trans(t, 'table|patrimoine_headers.tax.757'))} colSpan={2} alignHeader={'center'}/>
          <Column header={ucfirst(trans(t, 'table|patrimoine_headers.exo.757'))} colSpan={2} alignHeader={'center'}/>
          <Column header={ucfirst(trans(t, 'table|patrimoine_headers.solde.990'))} colSpan={2} alignHeader={'center'}/>
        </Row>
        <Row>
          <Column header={ucfirst(trans(t, 'table|patrimoine_headers.montantBrut'))} alignHeader={'center'}/>
          <Column header={ucfirst(trans(t, 'table|patrimoine_headers.montantNet'))} alignHeader={'center'}/>
          <Column header={ucfirst(trans(t, 'table|patrimoine_headers.date'))} alignHeader={'center'}/>

          <Column header={getSouscripteur1} alignHeader={'center'}/>
          <Column header={getSouscripteur2} alignHeader={'center'}/>

          <Column header={getSouscripteur1} alignHeader={'center'}/>
          <Column header={getSouscripteur2} alignHeader={'center'}/>
          <Column header={getSouscripteur1} alignHeader={'center'}/>
          <Column header={getSouscripteur2} alignHeader={'center'}/>
          <Column header={getSouscripteur1} alignHeader={'center'}/>
          <Column header={getSouscripteur2} alignHeader={'center'}/>
          <Column header={getSouscripteur1} alignHeader={'center'}/>
          <Column header={getSouscripteur2} alignHeader={'center'}/>
        </Row>
      </ColumnGroup>
    ) : (
    <ColumnGroup>
      <Row>
        <Column header={ucfirst(trans(t, 'table|patrimoine_headers.historique'))} colSpan={3} alignHeader={'center'}/>
        <Column header={souscripteurs.length > 0 ? souscripteurs[0]?.personnePhysique?.fullName ?? getSouscripteur1 ?? 'P1' : 'P1'} colSpan={5} alignHeader={'center'}/>
      </Row>
      <Row>
        <Column header={ucfirst(trans(t, 'table|patrimoine_headers.montantBrut'))} alignHeader={'center'}/>
        <Column header={ucfirst(trans(t, 'table|patrimoine_headers.montantNet'))} alignHeader={'center'}/>
        <Column header={ucfirst(trans(t, 'table|patrimoine_headers.date'))} alignHeader={'center'}/>

        <Column header={ucfirst(trans(t, 'table|patrimoine_headers.age'))} alignHeader={'center'}/>
        <Column header={ucfirst(trans(t, 'table|patrimoine_headers.exo.757990'))} alignHeader={'center'}/>
        <Column header={ucfirst(trans(t, 'table|patrimoine_headers.tax.757'))} alignHeader={'center'}/>
        <Column header={ucfirst(trans(t, 'table|patrimoine_headers.exo.757'))} alignHeader={'center'}/>
        <Column header={ucfirst(trans(t, 'table|patrimoine_headers.solde.990'))} alignHeader={'center'}/>
      </Row>
    </ColumnGroup>
  );

  const calcAge = (date: Date, birthDate: Date) => moment(birthDate).diff(date, 'years') + ' ' + trans(t, 'year', 2);
  const ageBody = (rowData: any) => souscripteurs.length > 0 ? calcAge(asDate(souscripteurs[0].personnePhysique?.birthDate), asDate(rowData?.date)) : null;
  const age2Body = (rowData: any) => souscripteurs.length > 1 ? calcAge(asDate(souscripteurs[1].personnePhysique?.birthDate), asDate(rowData?.date)) : null;

  const renderTable = useCallback(() => (
    <DatatableWrapper resourceType={resourceType} tableKey={'versements-retraits'} title={title ?? trans(t, 'historique.versementsRetraits')}
                      headerColumnGroup={headerGroup} noFilters noGlobalFilter paginator={false} params={params} editMode={editMode} additionalData={addData}
                      editFields={['date', 'montantBrut', 'montantNet', 'montantExonere757_990', 'montantExonere757_990_p2', 'montantTaxe757', 'montantTaxe757_p2', 'montantExonere757', 'montantExonere757_p2', 'montantSoldeTaxe990', 'montantSoldeTaxe990_p2']}>
      <Column field={'montantBrut'} body={(rowData: any) => amountCell(rowData.montantBrut)} align={'center'}
              editor={(options) => amountEditor(options, {label: trans(t, 'form|patrimoine.contracts.default.montantBrut')})}/>
      <Column field={'montantNet'} body={(rowData: any) => amountCell(rowData.montantNet)} align={'center'}
              editor={(options) => amountEditor(options, {label: trans(t, 'form|patrimoine.contracts.default.montantNet')})}/>
      <Column field={'date'} body={(rowData: any) => dateTimeCell(rowData.date, {format: 'DD/MM/YYYY'})} align={'center'}
              editor={(options) => maskEditor(options, {label: trans(t, 'form|patrimoine.contracts.default.date'), isDate: true})}/>

      <Column field={'age'} body={ageBody} align={'center'}/>
      {souscripteurs.length > 1 && (
        <Column field={'age'} body={age2Body} align={'center'}/>
      )}

      <Column field={'montantExonere757_990'} body={(rowData: any) => amountCell(rowData.montantExonere757_990)} align={'center'}
              editor={(options) => amountEditor(options, {label: trans(t, 'form|patrimoine.contracts.default.exo.757990')})}/>
      {souscripteurs.length > 1 && (
        <Column field={'montantExonere757_990_p2'} body={(rowData: any) => amountCell(rowData.montantExonere757_990_p2)} align={'center'}
                editor={(options) => amountEditor(options, {label: trans(t, 'form|patrimoine.contracts.default.exo.757990')})}/>
      )}
      <Column field={'montantTaxe757'} body={(rowData: any) => amountCell(rowData.montantTaxe757)} align={'center'}
              editor={(options) => amountEditor(options, {label: trans(t, 'form|patrimoine.contracts.default.tax.757')})}/>
      {souscripteurs.length > 1 && (
        <Column field={'montantTaxe757_p2'} body={(rowData: any) => amountCell(rowData.montantTaxe757_p2)} align={'center'}
                editor={(options) => amountEditor(options, {label: trans(t, 'form|patrimoine.contracts.default.tax.757')})}/>
      )}
      <Column field={'montantExonere757'} body={(rowData: any) => amountCell(rowData.montantExonere757)} align={'center'}
              editor={(options) => amountEditor(options, {label: trans(t, 'form|patrimoine.contracts.default.exo.757')})}/>
      {souscripteurs.length > 1 && (
        <Column field={'montantExonere757_p2'} body={(rowData: any) => amountCell(rowData.montantExonere757_p2)} align={'center'}
                editor={(options) => amountEditor(options, {label: trans(t, 'form|patrimoine.contracts.default.exo.757')})}/>
      )}
      <Column field={'montantSoldeTaxe990'} body={(rowData: any) => amountCell(rowData.montantExonere757_990)} align={'center'}
              editor={(options) => amountEditor(options, {label: trans(t, 'form|patrimoine.contracts.default.solde.990')})}/>
      {souscripteurs.length > 1 && (
        <Column field={'montantSoldeTaxe990_p2'} body={(rowData: any) => amountCell(rowData.montantExonere757_990_p2)} align={'center'}
                editor={(options) => amountEditor(options, {label: trans(t, 'form|patrimoine.contracts.default.solde.990')})}/>
      )}
    </DatatableWrapper>
  ), [souscripteurs]); // eslint-disable-line react-hooks/exhaustive-deps

  return (renderTable());
};
