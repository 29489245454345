import React, { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Fieldset } from 'primereact/fieldset';

import { dialog, iri, isValidUUID, trans, triggerFormReset, triggerFormSubmit, ucfirst } from 'utilities';
import { APIListField, CheckboxField, FormWrapper, LocaleField, PasswordField, SimplePasswordField, TextField } from 'forms';
import { accountLink, IRequestParams, isAdmin, isExpertAdmin, isSuperAdmin } from 'services';
import { DatatableWrapper, rolesEditor } from 'components';

export const UserForm = (props: any) => {
  const { apim, t, control, onFieldChange, errorMessage, formKeyPrefix, getValues, setError, clearErrors } = props;

  const [refresher, setRefresher] = useState<number>(Date.now);
  const resourceType = 'userGroups';
  const userId: string | null = getValues()?.id;
  const activeLinks: string[] | null = Object.values(getValues()?.activeLinks ?? {});

  const lazyConfig = {sortField: 'group.label', sortOrder: 1};
  const dtParams = [
    { label: 'user', value: userId},
    { label: 'group.name', value: 'SOCIETE_EXPERT_'},
    { label: 'role.name[]', value: 'ROLE_EXPERT'},
    { label: 'role.name[]', value: 'ROLE_EXPERT_ADMIN'},
    { label: 'expand[]', value: 'user_group:read_group'},
    { label: 'expand[]', value: 'group:read'},
    { label: 'expand[]', value: 'user_group:read_role'},
    { label: 'expand[]', value: 'role:read_list'}
  ];

  const groupBodyTemplate = (rowData: any) => rowData?.group?.label ?? rowData?.group?.name;
  const roleBodyTemplate = (rowData: any) => trans(t, rowData?.role ? 'system|role.' + rowData?.role?.name.toLowerCase() : 'N/R');

  const onNew = () => {
    dialog(t, {
      header: trans(t,'form|user.linkAdd'),
      icon: 'none',
      message: renderAddForm(),
      accept: () => triggerFormSubmit('a8-form-user-add-group-add'),
      reject: () => triggerFormReset('a8-form-user-add-group-reset')
    });
  };

  // Handle submission then refresh table rows.
  const onAddSubmit = (formData: any) => {
    if (!userId || !isValidUUID(formData?.group) || !isValidUUID(formData?.role)) return;

    // Check if user already linked to this dossier.
    apim.fetchEntities({
      resourceType,
      params: [
        {label: 'group', value: formData.group},
        {label: 'user', value: userId}
      ],
      success: (res: AxiosResponse) => {
        if (res?.data && res.data['hydra:member'] && res.data['hydra:member'].length > 0)
          return apim.displayError(trans(t, 'form|errors.alreadyExists.summary'), trans(t, 'form|errors.alreadyExists.detail'));

        apim.postEntity({
          resourceType,
          data: {
            group: iri('groups', formData.group),
            user: iri('users', userId),
            role: iri('roles', formData.role),
          },
          success: () => setRefresher(Date.now())
        } as IRequestParams).then();
      }
    } as IRequestParams).then();
  };

  const hookRowEdit = (_rows: any[], _row: any) => ({
    formattedRows: _rows,
    patched: {userGroups: {role: _row?.role?.id ? iri('roles', _row.role.id) : null}},
    id: _row?.id
  });

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderAddForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid w-12'} resourceType={resourceType} formKeyPrefix={'add_uese_dialog'} cancelLink multiple hideReload
                   resetClass={'a8-form-user-add-group-reset'} submitClass={'a8-form-user-add-group-add'} onSubmit={onAddSubmit}
                   context={{
                     roles: ['ROLE_EXPERT', 'ROLE_EXPERT_ADMIN'],
                     user: userId,
                     displayGroup: true,
                     displayRole: true,
                     rolesAvailables: ['ROLE_EXPERT', 'ROLE_EXPERT_ADMIN']
                   }}/>
    , [userId]); // eslint-disable-line react-hooks/exhaustive-deps

  // Wrap DT render into a useCallback to enable refresher feature.
  const renderDataTable = useCallback(() =>
      <DatatableWrapper
        resourceType={'userGroups'} tableKey={'expert-cabinets'} title={' '} parentClasses={[]} params={dtParams} lazyConfig={lazyConfig} hookRowEdit={hookRowEdit}
        noFilters noGlobalFilter editMode={isAdmin() || isExpertAdmin()} noAdd={!isAdmin()} addTitle={trans(t, 'se.add')} paginator={false} onNew={onNew}>
        <Column field={'group'} header={trans(t, 'societeExpertName')} body={groupBodyTemplate}/>
        <Column field={'role.id'} header={trans(t, 'societeExpertRole')} body={roleBodyTemplate}
                editor={(options) => rolesEditor(options, {
                  fieldKey: 'role',
                  label: trans(t, 'role'),
                  rolesAvailables: ['ROLE_EXPERT', 'ROLE_EXPERT_ADMIN']
                })}/>
      </DatatableWrapper>
    , [refresher]); // eslint-disable-line react-hooks/exhaustive-deps

  const emailValidatedDescription = () => {
    if (!isValidUUID(userId)) return null;

    return getValues()?.emailVerified ?
      (<span className={'block pt-2'}><span className={'underline'}>{ucfirst(trans(t, 'note'))}</span>: {ucfirst(trans(t, 'em.verified'))} <i className={'pi pi-check-circle text-xs pl-1'} style={{color: 'green', fontSize: '1.2rem'}}></i> </span>)
      :
      (<span className={'block pt-2'}><span className={'underline'}>{ucfirst(trans(t, 'note'))}</span>: {ucfirst(trans(t, 'em.not_verified'))} <i className={'pi pi-times-circle text-xs pl-1'} style={{color: 'red', fontSize: '1.2rem'}}></i></span>)
      ;
  };

  return <>
    <Fieldset legend={ucfirst(trans(t, 'profile'))}>
      <div className={'col'}>
        <div className={'grid'}>
          <APIListField listKey={'civilities'} fieldKey={'civility'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                        label={ucfirst(trans(t, 'civility'))}/>

          <TextField fieldKey={'firstName'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={ucfirst(trans(t, 'firstName'))}/>

          <TextField fieldKey={'lastName'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={ucfirst(trans(t, 'lastName'))}/>
        </div>

        <div className={'grid'}>
          <TextField fieldKey={'phone1'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={ucfirst(trans(t, 'phone1'))} addon={'phone'}/>

          <TextField fieldKey={'phone2'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={ucfirst(trans(t, 'phone2'))} addon={'phone'}/>
        </div>

        <div className={'grid'}>
          <LocaleField fieldKey={'locale'} control={control} onFieldChange={onFieldChange} disabled
                       errorMessage={errorMessage} label={ucfirst(trans(t, 'locale.title'))}/>
        </div>
      </div>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'user'))} className={'my-5'}>
      <div className={'col'}>
        <div className={'grid'}>
          <TextField fieldKey={'username'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={ucfirst(trans(t, 'username'))} addon={'hashtag'}
                     rules={{
                       required: trans(t, 'form|requiredField'),
                       minLength: {value: 5, message: ucfirst(trans(t, 'form|fieldTooShort', 1, '5'))},
                       maxLength: {value: 20, message: ucfirst(trans(t, 'form|usernameHelp', 1, '20'))},
                     }} description={ucfirst(trans(t, 'form|usernameHelp'))}/>
          <TextField fieldKey={'email'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                     label={ucfirst(trans(t, 'email'))} addon={'at'} description={emailValidatedDescription()}/>
        </div>

        <div className={'grid'}>
          <PasswordField fieldKey={'plainPassword'} control={control} onFieldChange={onFieldChange} setError={setError}
                         errorMessage={errorMessage} clearErrors={clearErrors} label={ucfirst(trans(t, 'password'))}/>

          <SimplePasswordField fieldKey={'confirmPassword'} control={control} onFieldChange={onFieldChange} setError={setError} compare={getValues()['plainPassword']}
                               errorMessage={errorMessage} clearErrors={clearErrors} label={ucfirst(trans(t, 'pwd.confirmation'))}/>
        </div>
      </div>
    </Fieldset>

    {'expert' === formKeyPrefix && isValidUUID(userId) && (
      <Fieldset legend={ucfirst(trans(t, 'societeExpert', 2))} className={'my-5'}>
        <div className={'grid'}>
          <div className={'col-12'}>
            {renderDataTable()}
          </div>
        </div>
      </Fieldset>
    )}

    <Fieldset legend={ucfirst(trans(t, 'privacy'))} className={'mb-4'}>
      <div className={'grid'}>
        <div className={'col-12 md:col-6'}>
          <CheckboxField fieldKey={'supportAccessAgreement'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                         label={ucfirst(trans(t, 'supportAccessAgreement'))} help={trans(t, 'supportAccessAgreementMessage')} classes={'col'}/>
        </div>
        <div className={'col-12 md:col-6'}>
          <CheckboxField fieldKey={'gdprAgreement'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                         label={ucfirst(trans(t, 'gdprAgreement'))} help={trans(t, 'gdprAgreementMessage')} classes={'col'}/>
        </div>
      </div>
    </Fieldset>

    {userId && isSuperAdmin() && (
      <Fieldset legend={ucfirst(trans(t, 'accountLinks'))} className={'mb-4'}>
        <div className={'flex flex-wrap gap-1 mb-3'}>
          <Button type={'button'} className={'account-link-button text-ucfirst'} label={trans(t, 'msGraphLinkBtn')} icon={'pi pi-microsoft'} onClick={() => accountLink(apim, userId, 'msgraph')}/>
          {(activeLinks ?? []).includes('msgraph') && (
            <Button type={'button'} icon={'pi pi-times'} rounded text severity={'danger'} aria-label={trans(t, 'msGraphUnlinkBtn')} /* onClick={  @todo  } */ />
          )}
          <Button type={'button'} className={'account-link-button ml-5 text-ucfirst'} label={trans(t, 'googleLinkBtn')} icon={'pi pi-google'} onClick={() => accountLink(apim, userId, 'google')} disabled/>
          {(activeLinks ?? []).includes('google') && (
            <Button type={'button'} icon={'pi pi-times'} rounded text severity={'danger'} aria-label={trans(t, 'googleUnlinkBtn')} /* onClick={  @todo  } */ />
          )}
        </div>
      </Fieldset>
      )}

    {(isAdmin() || isExpertAdmin()) && (
      <CheckboxField fieldKey={'active'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={ucfirst(trans(t, 'active'))} />
    )}
  </>
};
