import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { TFunction } from 'i18next';
import { TabMenu } from 'primereact/tabmenu';
import { Message } from 'primereact/message';

import { IRequestParams, isAdmin, isClient, useApim, isSuperAdmin } from 'services';
import { useAppState, useFormState, useUserState } from 'states';
import { PageLoader } from 'components';
import { Error, NotFound } from 'pages';
import { DirigeantFormRouter } from 'forms';
import { addShortcut, dialog, impersonateDialog, isValidUUID, trans, ucfirst, uuidFromIri } from 'utilities';

import { Dirigeant } from './Dirigeant';
import { DirigeantFoyer } from './DirigeantFoyer';
import { DirigeantCompanies } from './DirigeantCompanies';

import { includes, isEmpty, forEach } from 'lodash';
import appUri from 'config/appUri.json';

export const DirigeantRouter = (props: any) => {
  const { action } = props;
  const { id, tab } = useParams();

  const apim = useApim();
  const userState = useUserState();

  const { t, navigate } = apim.di();
  const location = useLocation();
  const appState = useAppState();
  const formState = useFormState();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  // Let's detect if we are in "edit/add" mode
  // then render the appropriate display (edit form or module dashboard).
  const editMode = appUri.reservedKeywords.edit === action;
  const addMode = appUri.reservedKeywords.add === action;

  // Handle tabs.
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const tabs: any[] = [];
  if (id) {
    const baseUri = appUri.usr.dir.page.replace(':id', id || '_');
    tabs.push({label: trans(t, 'menu|tab.general'), command: () => navigate(baseUri)});

    forEach(appUri.usr.dir.tabs.filter((_t: string) => _t !== 'notifications'), (ta) => {
      tabs.push({label: trans(t, 'menu|tab.' + ta), command: () => navigate(baseUri + '/' + ta)});
    });
  }

  // Validate URL & build tabs.
  const checks = useCallback(() => {
    return isUrlValid(t, id, tab);
  }, [id, tab]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!checks()) setNotFound(true);

    let ai = 0;
    forEach(appUri.usr.dir.tabs, (ta, index) => {
      if (tab === ta) ai = index + 1;
    });

    if (ai !== activeIndex) setActiveIndex(ai);
  }, [id, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // Let's detect if API calls from now have to be refreshed (cache expiration).
  const [searchParams] = useSearchParams();
  const lastUpdate = searchParams.get('maj');
  const fetchData = useCallback(() => {
    if (!id || editMode || addMode) return;

    setLoading(true);
    // Cascade fetch : user then related dossier then Personne Physique.
    apim.fetchEntity({
      resourceType: 'users',
      id: id,
      cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
      setErrored,
      setNotFound,
      error: () => setLoading(false),
      success: (res: AxiosResponse) => {
        apim.fetchEntities({
          resourceType: 'dossiers',
          params: [
            { label: 'user', value: res.data?.id },
            { label: 'expand[]', value: 'dossier:read_personne_physique' }
          ],
          setErrored,
          cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
          error: () => setLoading(false),
          success: (resDos: AxiosResponse) => {
            if (
              !resDos?.data ||
              !resDos.data['hydra:member'] ||
              resDos.data['hydra:member'].length === 0 ||
              !resDos.data['hydra:member'][0].personnePhysique
            ) {
              setLoading(false);

              return setData({user: res?.data, personnePhysique: null});
            }

            if (!appState.dossier()?.id) {
              appState.setDossier(resDos.data['hydra:member'][0]);
            }

            apim.fetchEntity({
              resourceType: 'personnesPhysiques',
              id: uuidFromIri(resDos.data['hydra:member'][0].personnePhysique),
              setErrored,
              cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
              setLoading,
              success: (resPer: AxiosResponse) => {
                if (!resPer?.data?.id) return setNotFound(true);

                setData({ user: res?.data, personnePhysique: resPer?.data });
              }
            } as IRequestParams).then();
          }
        } as IRequestParams).then();
      }
    } as IRequestParams).then();
  }, [id, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!data || !id || (data.id !== id) || lastUpdate) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [id, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update Page Header.
  useEffect(() => {
    let pageTitle: string = ucfirst(trans(t, 'dirigeant')) + ' ' + (data?.personnePhysique ? data?.personnePhysique?.firstName + ' ' + data?.personnePhysique?.lastName : '');
    if (editMode || addMode) {
      pageTitle += ' | ' + trans(t, editMode ? 'edit' : 'adding');
      appState.setPageActions([]);
    } else {
      const newPA: any[] = [{ label: trans(t, 'dir.edit'), icon: 'pencil', to: appUri.usr.dir.edit.replace(':id', id || '_') }];
      if (isSuperAdmin()) {
        newPA.push({label: trans(t, 'dir.impersonate'), icon: 'users', command: () => impersonateDialog(t, apim, userState, data.user)});
      }
      if (!isClient()) {
        newPA.push({ label: trans(t, 'short.add'), icon: 'bookmark', command: () => addShortcut(t, formState, appState) });
      }
      if (isAdmin()) {
        newPA.push({ label: trans(t, 'dir.add'), icon: 'plus', to: appUri.usr.dir.add, className:'bg-green-500' });
      }
      if (isSuperAdmin()) {
        newPA.push({ label: trans(t, 'dir.delete'), icon: 'trash', className:'bg-red-500', command: () =>
            dialog(t, {
              message: ucfirst(trans(t, 'system|confirmations.user.delete')),
              accept: () => {
                if (data?.user?.id) {
                  apim.deleteEntity({
                    resourceType: 'users',
                    id: data?.user?.id,
                    success: () => {
                      navigate(appUri.usr.dir.list);
                    },
                  } as IRequestParams);
                }
              },
              acceptClassName: 'p-button-danger',
              rejectClassName: 'p-button-text p-button-primary'
            })
        });

      }
      appState.setPageActions(newPA);
    }
    appState.setPageTitle(pageTitle);
    appState.setBreadcrumb([{ label: trans(t, 'dirigeant', 2), to: appUri.usr.dir.list }, { label: data?.personnePhysique ? data?.personnePhysique?.firstName + ' ' + data?.personnePhysique?.lastName : trans(t, 'dirigeant') }]);
  }, [tab, activeIndex, data, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildTabContent = useCallback(() => {
    switch (activeIndex) {
      case 2: return <DirigeantCompanies apim={apim} data={data}/>;
      case 1: return <DirigeantFoyer apim={apim} data={data}/>;
      default: return <Dirigeant apim={apim} data={data}/>;
    }
  }, [id, tab, activeIndex, data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (editMode || addMode) return <DirigeantFormRouter id={id} tab={tab} appState={appState} apim={apim} location={location} action={action}/>
  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (loading) return <PageLoader/>;

  return (
    <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
      <div className={'a8-page-header flex flex-wrap'}>
        <div className={'flex-auto flex p-2 justify-content-end pt-0'}>
          {tabs.length > 1 && <TabMenu className={'pb-2'} model={tabs} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/>}
        </div>
      </div>

      {!isValidUUID(appState.dossier()?.id) && isClient() && (
        <Message className={'mb-4 flex justify-content-center'} severity={'warn'} text={trans(t, 'form|warning.dossier-not-exists')} />
      )}

      {buildTabContent()}
    </div>
  );
};

const isUrlValid = (t: TFunction, id: string | undefined, tab: string | undefined) => {
  // Ensure entity UUID.
  return isValidUUID(id) && includes([...[undefined], ...appUri.usr.dir.tabs], tab);
};
