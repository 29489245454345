import React, { useCallback } from 'react';
import { Button } from 'primereact/button';

import { trans } from 'utilities';
import { PageLoader } from 'components';
import { FormWrapper } from 'forms';

import { forEach, replace } from 'lodash';
import appUri from 'config/appUri.json';

export const DossierSettings = (props: any) => {
  const { data, apim } = props;
  const { t } = apim.di();

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid w-12'} formKeyPrefix={'dossier_settings'} resourceType={'dossiers'} cancelLink multiple
                   redirectUri={replace(appUri.dos.page, ':id', data?.id || '_') + '?maj=' + Date.now()} data={data} />
    , [data?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'a8-form dossier'}>
      {data ? (
        <>
          {renderForm()}

          <div className={'flex md:justify-content-end justify-content-center w-12 pt-5 pb-5'}>
            <div className={'m-2 mr-5 flex flex-row'}>
              <Button type={'reset'} className={'a8-form-cancel text-ucfirst mx-2 p-2'} label={trans(t, 'cancel')} severity={'danger'} text icon='pi pi-cancel' onClick={() => {
                // Let's manually trigger cancel.
                const resets = document.getElementsByClassName('a8-form-cancel');
                if (resets.length > 0) {
                  forEach(resets, (formReset) => {
                    (formReset as HTMLInputElement).click();
                  });
                }
              }} />
              <Button type={'submit'} className={'a8-form-submit text-ucfirst pr-6 mx-2'} label={trans(t, 'save')} icon='pi pi-check' onClick={() => {
                // Let's manually trigger submit.
                const submits = document.getElementsByClassName('a8-form-submit');
                if (submits.length > 0) {
                  forEach(submits, (formSubmit) => {
                    (formSubmit as HTMLInputElement).click();
                  });
                }
              }} />
            </div>
          </div>
        </>
      ) : (
        <PageLoader />
      )}
    </div>
  );
};
