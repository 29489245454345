import { AxiosError, AxiosResponse } from 'axios';

import { IRequestParams } from 'services';
import { dialog, getErrorMessage, iri, isValidUUID, onApiError, trans } from 'utilities';

import { isEmpty, omit, pick } from 'lodash';

// Nested entities helpers.
// @see AssociatesPersonnesPhysiquesDatatable, DirigeantsPersonnesPhysiquesDatatable, ...

export const handlePersonnePhysiqueAddress = (pp: any, apim: any, header?: any) => {
  if (!pp?.address) return;

  const address: any = pp.address;

  if (address.id) {
    apim.patchEntity({
      resourceType: 'personnesPhysiquesAddresses',
      id: address.id,
      headers: header ?? '',
      data: {
        address1: address.address1,
        address2: address.address2,
        postalCode: address.postalCode,
        city: address.city,
      }
    } as IRequestParams).then();
  } else {
    if (!pp?.id) return;

    apim.postEntity({
      resourceType: 'personnesPhysiquesAddresses',
      data: address,
      headers: header ?? '',
      success: (resAddress: AxiosResponse) => {
        if (!resAddress?.data) return;
        // Then PATCH pp to link it with this newly created address
        apim.patchEntity({
          resourceType: 'personnesPhysiques',
          id: pp?.id,
          data: { address: iri('personnesPhysiquesAddresses', resAddress.data.id) }
        } as IRequestParams).then();
      }
    } as IRequestParams).then();
  }
}

export const handlePersonnePhysiqueRelatedEntity = (apim: any, context: any, linkedEntityResourceType: string, patched: any, setLoading: any | null = null, callback: any | null = null, header?: any) => {
  const resourceType = 'personnesPhysiques';
  if (!patched || !patched[resourceType] || !patched[resourceType].id) return setLoading(false);

  const terminate = (finalData: any) => {
    if (callback) {
      callback(finalData);
    }
  };

  if (patched[linkedEntityResourceType] && patched[linkedEntityResourceType].id) {
    if (isEmpty(omit(patched[linkedEntityResourceType], ['id']) as any)) {

      return terminate(patched[linkedEntityResourceType]);
    }

    apim.patchEntity({
      // Just an exception to handle here with these parents/children as same resource.
      resourceType: 'personnesPhysiquesParents' === linkedEntityResourceType ? 'personnesPhysiquesChildren' : linkedEntityResourceType,
      id: patched[linkedEntityResourceType].id,
      headers: header ?? '',
      data: omit(patched[linkedEntityResourceType], ['id']),
      setLoading: setLoading,
      success: (res: AxiosResponse) => terminate(res?.data)
    } as IRequestParams).then();
  } else {
    let overrides: any = {
      personnePhysique: iri(resourceType, patched[resourceType].id),
      companyData: iri('dossierCompanyData', context.id)
    };
    switch (linkedEntityResourceType) {
      case 'personnesPhysiquesSpouses':
        overrides = {
          spouseB: iri(resourceType, patched[resourceType].id),
          spouseA: iri('personnesPhysiques', context.personnePhysiqueId ?? context.id)
        };
        break;
      case 'personnesPhysiquesChildren':
        overrides = {
          child: iri(resourceType, patched[resourceType].id),
          parent: iri('personnesPhysiques', context.personnePhysiqueId ?? context.id)
        };
        break;
      case 'personnesPhysiquesParents':
        overrides = {
          parent: iri(resourceType, patched[resourceType].id),
          child: iri('personnesPhysiques', context.personnePhysiqueId ?? context.id)
        };
        break;
      case 'prevoyanceContractBeneficiairies':
        overrides = {
          contractData: iri('prevoyanceContractsData', context?.data?.id),
          type: context?.type
        };

        if (context?.type === 'personne_morale') {
          overrides.personneMorale = iri('personnesMorales', patched[resourceType].id);
        } else {
          overrides.personnePhysique = iri('personnesPhysiques', patched[resourceType].id);
        }
        break;
      case 'employeesPersonnesPhysiques':
        overrides = {
          ...overrides,
          company: iri('dossierCompanies', context.companyId)
        };
        break;

      case 'creditBeneficiaires':
        overrides = {
          credit: iri('credits', context?.credit?.id),
          type: context?.type
        };

        if (context?.type === 'personne_morale') {
          overrides.personneMorale = iri('personnesMorales', patched[resourceType].id);
        } else {
          overrides.personnePhysique = iri('personnesPhysiques', patched[resourceType].id);
        }
        break;
      case 'contractBeneficiaires':
        overrides = { type: context?.type };
        overrides[context?.contractType ?? 'contractAssuranceVie'] = iri(context?.contractResourceType ?? 'contractsAssuranceVie', context?.contract?.id);

        if (context?.type === 'personne_morale') {
          overrides.personneMorale = iri('personnesMorales', patched[resourceType].id);
        } else {
          overrides.personnePhysique = iri('personnesPhysiques', patched[resourceType].id);
        }
        break;
      case 'donationBeneficiaires':
        overrides = {
          donation: iri('donations', context?.donation?.id),
          type: context?.type
        };

        if (context?.type === 'personne_morale') {
          overrides.personneMorale = iri('personnesMorales', patched[resourceType].id);
        } else {
          overrides.personnePhysique = iri('personnesPhysiques', patched[resourceType].id);
        }
        break;
      case 'patrimoineHeritiers':
        overrides = {
          patrimoine: iri('patrimoines', context?.patrimoine?.id),
          personnePhysique: iri('personnesPhysiques', patched[resourceType].id),
        };
        break;
      case 'economicDataAccounts':
        overrides = { economicData: iri('economicData', context?.id) };

        if (context?.type === 'personne_morale') {
          overrides.personneMorale = iri('personnesMorales', patched[resourceType].id);
        } else {
          overrides.personnePhysique = iri('personnesPhysiques', patched[resourceType].id);
        }
        break;
    }

    apim.postEntity({
      resourceType: 'personnesPhysiquesParents' === linkedEntityResourceType ? 'personnesPhysiquesChildren' : linkedEntityResourceType,
      data: {
        ...patched[linkedEntityResourceType] || {},
        ...overrides
      },
      headers: header ?? '',
      setLoading: setLoading,
      success: (res: AxiosResponse) => terminate(res?.data)
    } as IRequestParams).then();
  }
};

export const onPersonnePhysiqueAddSubmit = (
  apim: any, context: any, formData: any, rows: any[], setRows: any,
  linkedEntityResourceType: string, setLoading: any,
  earlyCallback: any | null = null, // <-- use this to exit early using a custom callback
  header?: any,
) => {
  if (isEmpty(formData)) return;

  const resourceType = 'personnesPhysiques';
  // Existing PP selected, just create / link a new "associate" entity.
  if (formData?.personnePhysiqueId) {
    setLoading(true);
    // @TODO (mgd) Check duplicates first ?

    // Load the corresponding entity.
    apim.fetchEntity({
      resourceType: resourceType,
      id: formData?.personnePhysiqueId,
      notif: false,
      setLoading: setLoading,
      success: (res: AxiosResponse) => earlyCallback ? earlyCallback(res?.data) : handlePersonnePhysiqueRelatedEntity(apim, context, linkedEntityResourceType, {personnesPhysiques: res?.data}, setLoading, (linkedEntity: any) => {
        const _rows: any[] = [...rows];
        _rows.push({...linkedEntity, ...{ personnePhysique: res.data }});
        setRows(_rows);
      }, header),
    } as IRequestParams).then();

    return;
  }

  // Non-existing PP selected : try to find a match into the database first or create a new entity else.
  if (isEmpty(formData.firstName) || isEmpty(formData.lastName) || isEmpty(formData.birthDate)) return;
  setLoading(true);

  const createPersonnePhysique = () => {
    apim.postEntity({
      resourceType: resourceType,
      data: omit(formData, ['personnePhysiqueId']),
      notif: false,
      headers: header ?? '',
      // Insert here personnePhysiqueData fields.
      success: (res: AxiosResponse) => apim.postEntity({
        resourceType: 'personnesPhysiquesData',
        data: {...pick(formData, ['firstName', 'lastName']), ...{ personnePhysique: iri(resourceType, res?.data?.id) }},
        headers: header ?? '',
        notif: false,
        success: () => earlyCallback ? earlyCallback(res?.data) : handlePersonnePhysiqueRelatedEntity(apim, context, linkedEntityResourceType, {personnesPhysiques: res?.data}, setLoading, (linkedEntity: any) => {
          const _rows: any[] = [...rows];
          _rows.push({...linkedEntity, ...{ personnePhysique: res.data }});
          setRows(_rows);
        }, header),
        error: () => setLoading(false)
      } as IRequestParams).then(),
      error: () => setLoading(false)
    } as IRequestParams).then();
  };

  // Try to match existing entity first.
  apim.fetchEntities({
    resourceType: resourceType,
    notif: false,
    params: [
      {label: 'firstName', value: formData.firstName},
      {label: 'lastName', value: formData.lastName},
      {label: 'birthDate', value: formData.birthDate},
    ],
    success: (res: AxiosResponse) => {
      if (!res?.data || (res.data['hydra:member'] || []).length === 0) return createPersonnePhysique();

      earlyCallback ? earlyCallback(res.data['hydra:member'][0]) : handlePersonnePhysiqueRelatedEntity(apim, context, linkedEntityResourceType, {personnesPhysiques: res.data['hydra:member'][0]}, setLoading, (linkedEntity: any) => {
        const _rows: any[] = [...rows];
        _rows.push({...linkedEntity, ...{ personnePhysique: res.data['hydra:member'][0] }});
        setRows(_rows);
      }, header)
    },
    error: () => setLoading(false)
  } as IRequestParams).then();
};

export const onPersonneMoraleAddSubmit = (apim: any, context: any, formData: any, rows: any[], setRows: any, asAssociate: boolean | null = false, setLoading: any, header?: any) => {
  if (isEmpty(formData)) return;

  setLoading(true);
  const resourceType = 'dossierCompanies';

  // Existing company selected.
  if (formData.companyId) {
    // Check duplicates first.
    const checkContext: any[] = (asAssociate ? context?.associates : context?.dirigeantPersonnesMorales) || [];
    const checkAlreadyExists = checkContext.filter((c: any) => c?.dirigeantPersonnesMorales?.company?.id === formData.companyId || c?.company?.id === formData.companyId);
    if (checkAlreadyExists.length > 0) {
      const { t } = apim.di();
      setLoading(false);
      return dialog(t, {
        message: trans(t, 'duplicate_' + (asAssociate ? 'shareholder' : 'dirigeant'))
      });
    }

    // Then let's find if entity already exists in "the other array" (associates if dirigeant / dirigeant if associate).
    const checkOther: any[] = (asAssociate ? context?.dirigeantPersonnesMorales : context?.associates) || [];
    const checkOtherExists = checkOther.filter((c: any) => c?.dirigeantPersonnesMorales?.company?.id === formData.companyId || c?.company?.id === formData.companyId);
    if (checkOtherExists.length > 0) {
      const p: any = asAssociate ? {
        companies: checkOtherExists[0].company,
        dirigeantsPersonnesMorales: checkOtherExists[0]
      } : {
        companies: checkOtherExists[0].dirigeantPersonnesMorales?.company || checkOtherExists[0].company,
        dirigeantsPersonnesMorales: checkOtherExists[0].dirigeantPersonnesMorales
      };

      return handlePersonneMoraleRelatedEntities(apim, context, p, asAssociate, setLoading, (finalRow: any) => {
        const _rows: any[] = [...rows];
        _rows.push(finalRow);
        setRows(_rows);
        setLoading(false);
      }, header);
    }

    // Load the corresponding entity, the create / link a new "associate" entity.
    apim.fetchEntity({
      resourceType: resourceType,
      id: formData.companyId,
      notif: false,
      success: (res: AxiosResponse) => {
        // Cascade if we found a match.
        if (res?.data?.id) return handlePersonneMoraleRelatedEntities(apim, context, {companies: res?.data}, asAssociate, setLoading, (finalRow: any) => {
          const _rows: any[] = [...rows];
          _rows.push(finalRow);
          setRows(_rows);
          setLoading(false);
        }, header);
      },
      error: () => setLoading(false)
    } as IRequestParams).then();

    return;
  }

  // Non-existing company selected : try to find a match into the database first or create a new entity else.
  if (isEmpty(formData.siren)) return;

  const createCompany = () => {
    apim.postEntity({
      resourceType: resourceType,
      data: omit(formData, ['companyId']),
      headers: header ?? '',
      notif: false,
      success: (res: AxiosResponse) => handlePersonneMoraleRelatedEntities(apim, context, {companies: res?.data}, asAssociate, setLoading, (finalRow: any) => {
        const _rows: any[] = [...rows];
        _rows.push(finalRow);
        setRows(_rows);
        setLoading(false);
      }, header),
      error: () => setLoading(false)
    } as IRequestParams).then();
  };

  // Try to match existing entity first.
  apim.fetchEntities({
    resourceType: resourceType,
    notif: false,
    params: [{label: 'siren', value: formData.siren}],
    success: (res: AxiosResponse) => {
      if (res?.data && (res.data['hydra:member'] || []).length > 0) return handlePersonneMoraleRelatedEntities(apim, context, {companies: res.data['hydra:member'][0]}, asAssociate, setLoading, (finalRow: any) => {
        const _rows: any[] = [...rows];
        _rows.push(finalRow);
        setRows(_rows);
        setLoading(false);
      }, header);

      // Fallback to a PAPPERS search.
      apim.call({
        resourceType: 'companies',
        action: 'autocomplete',
        method: 'get',
        params: [{ label: 'siren', value: formData.siren }],
        notif: false,
        success: (res: AxiosResponse) => {
          if (!res?.data || (res.data['hydra:member'] || []).length === 0 || !res.data['hydra:member'][0].siren) return createCompany();

          // Ping APIM to process this company.
          apim.call({
            resourceType: 'companies',
            action: 'create',
            headers: header ?? '',
            data: { siren: formData.siren },
            notif: false,
            success: (res: AxiosResponse) => {
              if (res?.data.id) {
                handlePersonneMoraleRelatedEntities(apim, context, {companies: res?.data}, asAssociate, setLoading, (finalRow: any) => {
                  const _rows: any[] = [...rows];
                  _rows.push(finalRow);
                  setRows(_rows);
                  setLoading(false);
                }, header)
              }
            },
            error: (error: AxiosError) => {
              onApiError(apim.t, error, apim.toast('error'), getErrorMessage(apim.t, error));
              setLoading(false);
            },
          } as IRequestParams).then();
        },
        error: (error: AxiosError) => {
          onApiError(apim.t, error, apim.toast('error'), getErrorMessage(apim.t, error));
          setLoading(false);
        },
      } as IRequestParams).then();
    },
    error: (error: AxiosError) => {
      onApiError(apim.t, error, apim.toast('error'), getErrorMessage(apim.t, error));
      setLoading(false);
    },
  } as IRequestParams).then();
};

export const handlePersonneMoraleRelatedEntities = (apim: any, context: any, patched: any, asAssociate: boolean | null = false, setLoading: any, callback: any, header: any) => {
  const resourceType = 'dossierCompanies';
  const company: any = patched?.companies;
  if (!company || !company.id) return setLoading(false);

  const terminate = (row: any | null) => {
    setLoading(false);

    // @see src/forms/societe/client/wizard/Step4.tsx
    callback(asAssociate ? {
      ...row,
      ...patched?.dirigeantsPersonnesMorales || {},
      ...{ associateId: row.id, active: row.active }
    } : row);
  };

  const createAndLinkEntityType = (linkedEntityResourceType: string, data: any, dpm: any | null = null) => {
    apim.postEntity({
      resourceType: linkedEntityResourceType,
      data: data,
      headers: header ?? '',
      success: (res: AxiosResponse) => terminate(res?.data),
      error: () => setLoading(false)
    } as IRequestParams).then();
  }

  // Early exist if particular cases.
  switch (context['@type']) {
    case 'Dossier':
      return createAndLinkEntityType('dossierCompaniesLinks', {
        company: iri('dossierCompanies', company.id),
        dossier: iri('dossiers', context.id)
      });

    case 'Patrimoine':
      return isValidUUID(context?.credit?.id) ? createAndLinkEntityType('creditBeneficiaires', {
        personneMorale: iri('dossierCompanies', company.id),
        credit: iri('credits', context?.credit?.id),
        type: 'personne_morale'
      }) : createAndLinkEntityType('donationBeneficiaires', {
        personneMorale: iri('dossierCompanies', company.id),
        donation: iri('donations', context?.donation?.id),
        type: 'personne_morale'
      });

    case 'PrevoyanceContract':
      return createAndLinkEntityType('prevoyanceContractBeneficiairies', {
        personneMorale: iri('dossierCompanies', company.id),
        contractData: iri('prevoyanceContractsData', context?.data?.id),
        type: context?.type
      });
  }

  // Cascade created 'dirigeantPersonneMorale' then 'associate' (if needed).
  if (patched?.dirigeantsPersonnesMorales?.id) {
    return createAndLinkEntityType('associates', {
      dirigeantPersonneMorale: iri('dirigeantsPersonnesMorales', patched?.dirigeantsPersonnesMorales?.id),
      companyData: iri('dossierCompanyData', context.id)
    }, patched?.dirigeantsPersonnesMorales);
  }

  return asAssociate ? createAndLinkEntityType('associates', {
    company: iri(resourceType, company.id),
    companyData: iri('dossierCompanyData', context.id)
  }) : createAndLinkEntityType('dirigeantsPersonnesMorales', {
    company: iri(resourceType, company.id),
    companyData: iri('dossierCompanyData', context.id)
  });
};
