import React, { useEffect } from 'react';

import { trans, ucfirst } from 'utilities';
import { useAppState } from 'states';
import { isAdmin, useApim } from 'services';
import { SocietesExpertDatatable } from 'components';

import appUri from 'config/appUri.json';

export const SocietesExpertsList = () => {
  const appState = useAppState();
  const apim = useApim();
  const { t } = apim.di();

  // Update Page Header.
  useEffect(() => {
    appState.setPageTitle(trans(t, 'menu|pages.title.societe.experts'));
    appState.setBreadcrumb([{ label: ucfirst(trans(t, 'societeExpert', 2)) }]);

    const newPA: any[] = [];
    if (isAdmin()) {
      newPA.push({ label: trans(t, 'se.add'), icon: 'plus', to: appUri.cie.exp.add, className:'bg-green-500' });
    }
    appState.setPageActions(newPA);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <SocietesExpertDatatable tableKey={'societes-experts'} noFilters title={trans(t, 'menu|pages.title.societe.experts')} apim={apim}/>;
};
