import React, { useCallback, useEffect, useState } from 'react';

import { Column } from 'primereact/column';
import { SpeedDial } from 'primereact/speeddial';
import { Tooltip } from 'primereact/tooltip';

import { dialog, trans, ucfirst } from 'utilities';
import { DatatableWrapper, dateTimeCell, MissionHypothesisLabel, PageLoader, Username, validCell } from 'components';
import { useAppState } from 'states';
import { IRequestParams, isAdmin, isClient, isSuperAdmin } from 'services';

import appUri from 'config/appUri.json';
import { trimStart } from 'lodash';
import { useSearchParams } from 'react-router-dom';

export const MissionsDatatable = (props: any) => {
  const { tableKey, title, apim } = props;
  const { t, navigate } = apim.di();
  const appState = useAppState();
  const [societesExperts, setSocietesExperts] = useState<any[]>([]);
  const [missions, setMissions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const dossier: any = appState.dossier();

  let newParams = [
    { label: 'dossier', value: dossier?.id },
    { label: 'expand[]', value: 'mission:read_hypothesis' },
    { label: 'expand[]', value: 'mission_type:read' },
    { label: 'expand[]', value: 'mission_hypothesis:read' },
  ];

  const lazyC = {

      sortField: searchParams.has('sortField') ? searchParams.get('sortField') : 'created',
      sortOrder: searchParams.has('sortOrder') ? (searchParams.get('sortOrder') === 'ASC' ? 1 : -1) : -1,

  };

  const labelBodyTemplate = (rowData: any) => {
    return rowData?.label || 'N/A';
  }
  const missionTypeBodyTemplate = (rowData: any) => {
    return rowData?.missionType.description || 'N/A';
  }
  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);
  const deletedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.deleted);
  const updatedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.updated);
  const createdBodyTemplate = (rowData: any) => dateTimeCell(rowData?.created);
  const validatedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.validated);
  const hypothesisBodyTemplate = (rowData: any) => {
    return <MissionHypothesisLabel apim={apim} missionId={rowData?.id} />
  }
  const authorBodyTemplate = (rowData: any) => {
    return <Username apim={apim} user={rowData?.author} />
  };
  const dateBodyTemplate = (rowData: any) => dateTimeCell(rowData?.date, {
    format: 'DD/MM/YYYY',
  });
  const societeExpertBodyTemplate = (rowData: any) => {
    // Transform SocieteExpert id into raison sociale
    const societeExpertId = rowData?.societeExpert;
    const societeExpert = societesExperts?.find((expert: any) => expert.id === societeExpertId);
    return societeExpert ? societeExpert?.raisonSociale : 'N/A';
  };

  useEffect(() => {
    setLoading(true);

    apim.fetchEntities({
      resourceType: 'societesExperts',
      setter: setSocietesExperts,
    } as IRequestParams).then();

    apim.fetchEntities({
      resourceType: 'missions',
      cache: false,
      params: [
        { label: 'dossier', value: dossier?.id },
        { label: 'expand[]', value: 'mission_type:read' },
      ],      setLoading: setLoading,
      setter: setMissions,
    } as IRequestParams).then();

  }, [dossier?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const onNew = () => {
    appState.setMission(null);
    navigate(appUri.dos.edit.landing
        .replace(':id', dossier?.id)
      + '/missions/new');
  };

  const onRowClick = async (rowData: any) => {
    appState.setMission(rowData?.id);
    if (rowData?.missionType?.code === 'RETRAITE') {
      navigate((trimStart(appUri.dos.page) + appUri.dos.pageMissionRetraite)
        .replace(':id', dossier?.id)
        .replace(':module', rowData?.missionType?.code?.toLowerCase())
        .replace(':mission', rowData?.id)
        .replace(':tab', 'obligatoire'));

    } else {
      navigate((trimStart(appUri.dos.page) + appUri.dos.pageMission)
        .replace(':id', dossier?.id)
        .replace(':module', 'missions')
        .replace(':type', rowData?.missionType?.code?.toLowerCase())
        .replace(':mission', rowData?.id));
    }
  };

  const actionsBodyTemplate = (rowData: any) => {
    let items = [];

    if (!isClient()) {
      items.push({
        label: ucfirst(trans(t, 'system|actions.edit')),
        icon: 'pi pi-pencil',
        className: 'bg-indigo-500',
        command: () => {
          appState.setMission(rowData?.id)
          navigate(appUri.dos.edit.missions.uri.replace(':id', dossier?.id).replace(':type', rowData?.missionType?.code?.toLowerCase()) + '/' + rowData?.id);
        }
      });

      items.push({
        label: rowData?.validated ? ucfirst(trans(t, 'system|actions.invalidate')) : ucfirst(trans(t, 'system|actions.validate')),
        icon: rowData?.validated ? 'pi pi-eye-slash' : 'pi pi-eye',
        className: 'bg-orange-500',
        command: () => {
          dialog(t, {
            message: rowData?.validated ? ucfirst(trans(t, 'system|confirmations.missions.invalidate')) : ucfirst(trans(t, 'system|confirmations.missions.validate')),
            accept: () => {
              if (rowData?.id) {
                const validated = rowData.validated ? null : new Date();
                const action = validated ? 'invalidate' : 'validate';

                apim.patchEntity({
                  resourceType: 'missions',
                  id: rowData.id,
                  data: { validated: validated },
                  notifSuccess: {
                    summary: trans(t, 'notification|missions.' + action + '.summary'),
                    details: trans(t, 'notification|missions.' + action + '.details'),
                  },
                  success: () => {
                    rowData.validated = validated;
                  },
                } as IRequestParams);
              }
            },
            acceptClassName: 'p-button-danger',
            rejectClassName: 'p-button-text p-button-primary'
          });
        }
      });
    }

    if (isAdmin()) {
      items.push({
        label: rowData?.active ? ucfirst(trans(t, 'system|actions.disable')) : ucfirst(trans(t, 'system|actions.enable')),
        icon: rowData?.active ? 'pi pi-ban' : 'pi pi-check',
        className: 'bg-orange-500',
        command: () => {
          if (rowData?.id) {
            const active = !rowData.active;
            const action = active ? 'enable' : 'disable';

            apim.patchEntity({
              resourceType: 'missions',
              id: rowData.id,
              data: { active: active },
              notifSuccess: {
                summary: trans(t, 'notification|missions.' + action + '.summary'),
                details: trans(t, 'notification|missions.' + action + '.details'),
              },
              success: () => {
                rowData.active = active;
              },
            } as IRequestParams);
          }
        }
      });

      if (rowData.deleted === null || rowData.deleted === undefined) {
        items.push({
          label: ucfirst(trans(t, 'system|actions.archive')),
          icon: 'pi pi-folder',
          className: 'bg-red-500',
          command: () => {
            dialog(t, {
              message: ucfirst(trans(t, 'system|confirmations.missions.archive')),
              accept: () => {
                if (rowData?.id) {
                  const deleted = new Date();
                  apim.patchEntity({
                    resourceType: 'missions',
                    id: rowData?.id,
                    data: {
                      active: false,
                      deleted: deleted
                    },
                    notifSuccess: {
                      summary: trans(t, 'notification|missions.archive.summary'),
                      details: trans(t, 'notification|missions.archive.details'),
                    },
                    success: () => {
                      if (isSuperAdmin()) {
                        rowData.deleted = deleted;
                        rowData.active = false;
                      }
                    },
                  } as IRequestParams);
                }
              },
              acceptClassName: 'p-button-danger',
              rejectClassName: 'p-button-text p-button-primary'
            });
          }
        });
      }
    }

    if (isSuperAdmin()) {
      items.push({
        label: ucfirst(trans(t, 'system|actions.delete')),
        icon: 'pi pi-trash',
        className: 'bg-red-500',
        command: () => {
          dialog(t, {
            message: ucfirst(trans(t, 'system|confirmations.missions.delete')),
            accept: () => {
              if (rowData?.id) {
                apim.deleteEntity({
                  resourceType: 'missions',
                  id: rowData?.id,
                  success: () => {
                    setMissions(data => data.filter(item => item.id !== rowData?.id));
                    appState.setMission(null);
                  },
                } as IRequestParams);
              }
            },
            acceptClassName: 'p-button-danger',
            rejectClassName: 'p-button-text p-button-primary'
          });
        }
      })

      if (rowData.deleted !== null && rowData.deleted !== undefined) {
        items.push({
          label: ucfirst(trans(t, 'system|actions.unarchive')),
          icon: 'pi pi-folder-open',
          className: 'bg-red-500',
          command: () => {
            dialog(t, {
              message: ucfirst(trans(t, 'system|confirmations.missions.unarchive')),
              accept: () => {
                if (rowData?.id) {
                  apim.patchEntity({
                    resourceType: 'missions',
                    id: rowData?.id,
                    data: {
                      active: true,
                      deleted: null
                    },
                    notifSuccess: {
                      summary: trans(t, 'notification|missions.unarchive.summary'),
                      details: trans(t, 'notification|missions.unarchive.details'),
                    },
                    success: () => {
                      rowData.deleted = null;
                      rowData.active = true;
                    },
                  } as IRequestParams);
                }
              },
              acceptClassName: 'p-button-danger',
              rejectClassName: 'p-button-text p-button-primary'
            });
          }
        });
      }
    }

    return <>
      <Tooltip target={'.a8-speedial-datatable .p-speeddial-action'} position={'top'} mouseTrack/>
      <SpeedDial className={'a8-speedial-datatable relative z-5'} model={items} direction={'left'} transitionDelay={40} showIcon={'pi pi-ellipsis-v'} hideIcon={'pi pi-times'} buttonClassName={'p-button-text'} />
    </>
  }

  const renderDT = useCallback(() =>
      <DatatableWrapper resourceType={'missions'} tableKey={tableKey || 'missions'} params={newParams} lazyConfig={lazyC}
                        title={title || trans(t, 'menu|pages.title.missions')} onNew={onNew} noAdd={isClient()} onRowClick={onRowClick}>
        <Column field={'label'} header={trans(t, 'name')} sortable align={'center'} body={labelBodyTemplate}
                style={{ width: '225px' }}/>
        <Column field={'missionType'} header={trans(t, 'missionType')} sortable align={'center'}
                body={missionTypeBodyTemplate} style={{ width: '250px' }}/>
        <Column field={'hypothesis'} header={trans(t, 'validatedHypothesis')} sortable align={'center'}
                body={hypothesisBodyTemplate} style={{ width: '250px' }}/>
        <Column field={'date'} header={trans(t, 'date')} sortable align={'center'} body={dateBodyTemplate} style={{ width: '150px' }}/>
        <Column field={'societeExpert'} header={trans(t, 'societeExpert')} sortable align={'center'}
                body={societeExpertBodyTemplate} style={{ width: '150px' }}/>
        <Column field={'author'} header={trans(t, 'author')} sortable align={'center'} body={authorBodyTemplate}
                style={{ width: '150px' }}/>
        <Column field={'updated'} header={trans(t, 'updated')} sortable align={'center'} body={updatedBodyTemplate}
                style={{ width: '100px' }}/>
        <Column field={'created'} header={trans(t, 'created')} sortable align={'center'} body={createdBodyTemplate}
                style={{ width: '100px' }}/>
        {isSuperAdmin() &&
          <Column field={'deleted'} header={trans(t, 'deleted')} sortable
                  align={'center'} body={deletedBodyTemplate} style={{width: '100px'}}/>
        }
        {!isClient() &&
          <Column field={'validated'} header={trans(t, 'validated_date')} sortable
                  align={'center'} body={validatedBodyTemplate} style={{width: '100px'}}/>
        }
        {!isClient() &&
          <Column field={'active'} header={trans(t, 'active')} sortable
                  dataType={'boolean'} align={'center'} body={activeBodyTemplate} style={{width: '100px'}}/>
        }
        {!isClient() ?
          <Column header={trans(t, 'system|action', 2)} align={'right'} body={actionsBodyTemplate} style={{width: '80px', maxWidth: '80px'}}/>
          : null}

      </DatatableWrapper>
    , [missions]); // eslint-disable-line react-hooks/exhaustive-deps
  if (loading) return <PageLoader/>;

  return (
    <>
      {renderDT()}
    </>
  );
}
