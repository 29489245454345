import React, { useEffect, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Chip } from 'primereact/chip';
import { Divider } from 'primereact/divider';
import { Fieldset } from 'primereact/fieldset';

import { Error, NotFound } from 'pages';
import { ContractBeneficiairesDatatable, ContractCotisationsDatatable, ContractSouscripteursDatatable, IndicatorSimple, PageLoader, SimpleText, Userpicture } from 'components';
import { IRequestParams, isClient } from 'services';
import { dateString, isValidUUID, trans, ucfirst } from 'utilities';

import appUri from 'config/appUri.json';

export const PatrimoineContrat = (props: any) => {
  const { apim, appState, type, id } = props;
  const { t} = apim.di();
  const dossier: any = appState?.dossier();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);
  const [bank, setBank] = useState<any>();

  const getResourceType = useMemo(() => {
    switch (type) {
      case 'capitalisation': return 'contractsCapitalisation';
      case 'retraite': return 'contractsRetraite';
      default: return 'contractsAssuranceVie';
    }
  }, [dossier?.id, type, id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => fetchData(), [dossier?.id, id]); // eslint-disable-line react-hooks/exhaustive-deps
  const fetchData = () => {
    if ((data?.id === id) || !isValidUUID(id)) return;

    setLoading(true);
    apim.fetchEntity({
      resourceType: getResourceType,
      id: id,
      setLoading,
      setErrored,
      setNotFound,
      success: (res: AxiosResponse) => {
        setData(res.data)
        apim.fetchEntity({
          resourceType: 'banks',
          id: res.data?.bank,
          params: [{ label: 'order[name]', value: 'asc' }, { label: 'limit', value: 250 }],
          setter: setBank,
          setLoading
        } as IRequestParams).then();
      }
    } as IRequestParams).then();
  }

  // Override Page Header (defaults into ModuleWrapper, then PatrimoineRouter).
  useEffect(() => {
    appState.setBreadcrumb([
      isClient()
        ? { label: trans(t, 'dossier', 2) }
        : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier.title, to: appUri.dos.page.replace(':id', dossier?.id) },
      { label: trans(t, 'menu|menu.schema_flux.patrimoine_biens'), to: appUri.dos.page.replace(':id', dossier?.id) + '/patrimoine/biens' },
      { label: data?.label ?? data?.company?.latestVersion?.raisonSociale ?? trans(t, 'contract') }
    ]);
  }, [dossier?.id, id, type, data?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (loading) return <PageLoader/>;

  return (
    <div className={'flex flex-column md:flex-row flex-wrap fadein animation-duration-500 mb-5'}>

      <div className={'flex flex-column md:flex-row px-3 w-12'}>
        <div className={'flex w-12 md:w-6 py-4 px-3'}>
          <IndicatorSimple color={'primary'} dark bgImage title={trans(t, 'form|patrimoine.contracts.default.label')} text={data?.label}/>
        </div>
        <div className={'flex w-12 md:w-6 py-4 px-3'}>
          <IndicatorSimple color={'primary'} dark bgImage title={trans(t, 'form|patrimoine.contracts.default.number')} text={data?.number}/>
        </div>
      </div>

      <Fieldset className={'my-5 w-12'} legend={ucfirst(trans(t, 'general'))} toggleable>
        <div className={'flex flex-column md:flex-row px-3'}>
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText title={trans(t, 'form|patrimoine.contracts.default.type')} value={data?.type} method={'list'} methodParams={{listKey: 'contractSupportTypes'}}/>
          </div>
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText title={trans(t, 'form|patrimoine.contracts.default.openingDate')} value={data?.openingDate} method={'date'}/>
          </div>
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText title={trans(t, 'form|patrimoine.contracts.default.bank')} text={bank?.name}/>
          </div>
        </div>
      </Fieldset>

      <div className={'flex w-12 flex-wrap gap-4 py-4 justify-content-center'}>
        <Chip key={'avantageMatrimonialPreciput'} label={trans(t, 'form|patrimoine.contracts.default.avantageMatrimonialPreciput')} icon={'pi pi-' + (true === data?.clausePreciput ? 'check' : 'times')}
              style={{ backgroundColor: true === data?.clausePreciput ? '#E3F2FD' : '' }}/>
        <Chip key={'denouementSecondDeces'} label={trans(t, 'form|patrimoine.contracts.default.denouementSecondDeces')} icon={'pi pi-' + (true === data?.denouementSecondDeces ? 'check' : 'times')}
              style={{ backgroundColor: true === data?.denouementSecondDeces ? '#E3F2FD' : '' }}/>
      </div>

      <Fieldset className={'my-5 w-12'} legend={ucfirst(trans(t, 'economic'))} toggleable>
        <div className={'flex flex-column md:flex-row px-3'}>
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText value={data?.montantFondEuro} title={trans(t, 'form|patrimoine.contracts.default.montantFondEuro')}/>
          </div>
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText value={data?.montantFondUniteCompte} title={trans(t, 'form|patrimoine.contracts.default.montantFondUniteCompte')}/>
          </div>
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText value={data?.montantFondUniteCompteImmobilier} title={trans(t, 'form|patrimoine.contracts.default.montantFondUniteCompteImmobilier')}/>
          </div>

          {getResourceType !== 'contractsRetraite' && (
            <div className={'flex flex-grow-1 py-4 px-3'}>
              <SimpleText value={data?.montantVersementsAnnuels} title={trans(t, 'form|patrimoine.contracts.default.montantVersementsAnnuels')}/>
            </div>
          )}
        </div>

        {getResourceType === 'contractsRetraite' && (
          <div className={'grid w-full mt-5'}>

            <div className={'col-4'}>
              <h6 style={{ color: 'var(--primary-color)' }}>{trans(t, 'form|patrimoine.contracts.default.phaseEpargne')}</h6>
              <div className={'grid w-full'}>
                <div className={'flex flex-grow-1 py-4 px-3'}>
                  <SimpleText value={data?.montantVersementsAnnuelsPerso} title={trans(t, 'form|patrimoine.contracts.default.montantVersementsAnnuelsPerso')}/>
                </div>
                <div className={'flex flex-grow-1 py-4 px-3'}>
                  <SimpleText value={data?.montantVersementsAnnuelsPro} title={trans(t, 'form|patrimoine.contracts.default.montantVersementsAnnuelsPro')}/>
                </div>
              </div>
            </div>

            <div className={'col-2'}>
              <Divider layout={'vertical'}>
                <i className={'pi pi-arrow-right'} style={{ color: 'var(--primary-color)', fontSize: '2rem' }} />
              </Divider>
            </div>

            <div className={'col-6'}>
              <h6 style={{ color: 'var(--primary-color)' }}>{trans(t, 'form|patrimoine.contracts.default.phaseRetraite')} </h6>
              <div className={'grid w-full'}>
                <div className={'flex flex-grow-1 py-4 px-3'}>
                  <SimpleText value={data?.closingDate} title={trans(t, 'form|patrimoine.contracts.default.denouementDate')} method={'date'}/>
                </div>
                <div className={'flex flex-grow-1 py-4 px-3'}>
                  <SimpleText title={trans(t, 'form|patrimoine.contracts.default.denouementType')} value={data?.denouementType} method={'list'} methodParams={{listKey: 'contractDenouementTypes'}}/>
                </div>
                <div className={'flex flex-grow-1 py-4 px-3'}>
                  <SimpleText value={data?.montantRenteAnnuelle} title={trans(t,  data?.denouementType !== 'capital' ? 'form|patrimoine.contracts.default.montantRenteAnnuelle' : 'form|patrimoine.contracts.default.capitalDisponible')}/>
                </div>
              </div>
            </div>

          </div>
        )}
      </Fieldset>

      <Fieldset legend={ucfirst(trans(t, 'souscripteur', 2))} className={'mt-0 w-12'} toggleable>
        <ContractSouscripteursDatatable apim={apim} data={data} title={''} />
      </Fieldset>

      {getResourceType !== 'contractsCapitalisation' && (
        <Fieldset legend={ucfirst(trans(t, 'historique.versementsRetraits'))} className={'mt-4 w-12'} toggleable collapsed>
          <ContractCotisationsDatatable apim={apim} data={data} title={''} />
        </Fieldset>
      )}

      {getResourceType !== 'contractsCapitalisation' && (
        <Fieldset legend={ucfirst(trans(t, 'beneficiary', 2))} className={'mt-4 w-12'} toggleable>
          <ContractBeneficiairesDatatable apim={apim} data={data} title={''} />
        </Fieldset>
      )}

      {data?.description && (
        <Fieldset legend={ucfirst(trans(t, 'form|zone_note'))} className={'w-full mt-5'} toggleable>
          <div className={'a8-bien-description'} dangerouslySetInnerHTML={{__html: data.description}}></div>
        </Fieldset>
      )}

      <div className={'flex flex-row justify-content-end w-full mt-5'}>
        {isValidUUID(data?.author) && (
          <div className={'flex px-3 align-items-center'}>
            <strong className={'titre titre--gray pr-2'}>{ucfirst(trans(t, 'author'))}</strong>
            <div>
              <Userpicture apim={apim} user={data.author} size={'small'}/>
            </div>
          </div>
        )}

        {data?.created && (
          <div className={'flex px-3 align-items-center'}>
            <strong className={'titre titre--gray pr-2'}>{ucfirst(trans(t, 'created.at'))}</strong>
            <div>
              {dateString(data?.created, 'DD/MM/YYYY') + ' ' + trans(t, 'at') + ' ' + dateString(data?.created, 'HH:mm')!.replace(':', 'h')}
            </div>
          </div>
        )}

        {data?.updated && (
          <div className={'flex px-3 align-items-center'}>
            <strong className={'titre titre--gray pr-2'}>{ucfirst(trans(t, 'updated.at'))}</strong>
            <div>
              {dateString(data?.updated, 'DD/MM/YYYY') + ' ' + trans(t, 'at') + ' ' + dateString(data?.updated, 'HH:mm')!.replace(':', 'h')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
