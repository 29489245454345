import { hookstate, useHookstate, State } from '@hookstate/core';
import { unState } from 'states';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from 'utilities';

import { drop, forEach, isEmpty, trim } from 'lodash';

export const formKeyPrefix = 'a8form#';

export interface IFormData {
  key: string,
  fields: any;
  step: number;
  loading: boolean;
  subscribed: boolean;
}
export const emptyForm: IFormData = {
  key: 'default',
  fields: {},
  step: 0,
  loading: false,
  subscribed: false
} as IFormData;
interface IFormState {
  forms: IFormData[];
}
const emptyState: IFormState = {
  forms: []
} as IFormState;

const state: State<IFormState> = hookstate(Object.assign({}, emptyState));

const getFormDataByKey = (s: State<IFormState>, formKey: string) => {
  const forms = s.forms.get().filter((f: any) => f.key === formKey);
  return forms.length > 0 ? unState(forms[0]) : null;
};

const getFormStepByKey = (s: State<IFormState>, formKey: string) => {
  const form = getFormDataByKey(s, formKey);
  return !form ? 0 : form.step || 0;
};

const isLoadingByKey = (s: State<IFormState>, formKey: string) => {
  const form = getFormDataByKey(s, formKey);
  return !form ? false : form.loading || false;
};

const hasSubscribedByKey = (s: State<IFormState>, formKey: string) => {
  const form = getFormDataByKey(s, formKey);
  return !form ? false : form.subscribed || false;
};

const getFormFieldDataByKey = (s: State<IFormState>, formKey: string, fieldKey: string | null = null, parentKey: string | null = null) => {
  const form = getFormDataByKey(s, formKey);

  if (!form) return null;
  if (!parentKey) return fieldKey ? form.fields[fieldKey] : form.fields;

  const getDeep = (data: any, split: string[]): any => {
    const key = trim(split[0], '[]');
    if (split.length === 1) return fieldKey ? data[key][fieldKey] : data[key];

    return data[key] ? getDeep(data[key], drop(split)) : null;
  };

  const split = parentKey.split('][');
  const key1 = trim(split[0], '[]');
  if (split.length === 1) return form.fields[key1] ? (fieldKey ? form.fields[key1][fieldKey] : form.fields[key1]) : null;

  return form.fields[key1] ? getDeep(form.fields[key1], drop(split)) : null;
};

const updateFormData = (s: State<IFormState>, formData: IFormData, cache = true) => {
  const newFormData: IFormData = {...Object.assign({}, emptyForm), ...formData};
  const newForms = unState(s.forms.get()).filter((f: any) => f.key !== newFormData.key);
  newForms.push(newFormData);
  s.forms.set(newForms);

  if (cache) {
    if (Object.keys(newFormData.fields).length > 0) {
      // Do not cache the following elements : loading status & hub subscription status.
      setLocalStorageItem(
        {...newFormData, ...{loading: false, subscribed: false}},
        formKeyPrefix + newFormData.key
      );
    } else {
      removeLocalStorageItem(formKeyPrefix + newFormData.key);
    }
  }
};

const updateFieldData = (s: State<IFormState>, formKey: string, fieldKey: string, parentKey: string | null = null, fieldValue: any | null = null) => {
  const form = getFormDataByKey(s, formKey) ||  {...Object.assign({}, emptyForm), ...{key: formKey}};

  if (!parentKey) {
    // Should we remove the key or keep it with a null value ?
    // if (!fieldValue) {
    //   form.fields = omit(form.fields, [fieldKey]);
    // } else {
      form.fields[fieldKey] = fieldValue;
    // }

    return updateFormData(s, form);
  }

  const split = parentKey.split('][');
  const key1 = trim(split[0], '[]');
  if (!form.fields[key1]) {
    form.fields[key1] = {};
  }

  // Ugly switch solution due to too complex recursion.
  switch (split.length) {
    case 3:
      const key32 = trim(split[1], '[]');
      if (!form.fields[key1][key32]) {
        form.fields[key1][key32] = {};
      }

      const key3 = trim(split[2], '[]');
      if (!form.fields[key1][key32][key3]) {
        form.fields[key1][key32][key3] = {};
      }

      form.fields[key1][key32][key3][fieldKey] = fieldValue;
      break;

    case 2:
      const key2 = trim(split[1], '[]');
      if (!form.fields[key1][key2]) {
        form.fields[key1][key2] = {};
      }

      form.fields[key1][key2][fieldKey] = fieldValue;
      break;

    case 1:
      form.fields[key1][fieldKey] = fieldValue;
      break;
  }

  updateFormData(s, form);
};

const wrapper = (s: State<IFormState>) => ({
  getFormData: (formKey: string) => getFormDataByKey(s, formKey),
  // setFormData: (data: IFormData, cache = true) => updateFormData(s, data, cache),
  reloadFormData: (formKey: string, update = true): number => {
    const data = getLocalStorageItem(formKeyPrefix + formKey);

    if (data) {
      const formData: IFormData = data;

      if (update) updateFormData(s, formData);

      return formData.fields ? Object.keys(formData.fields).length : 0;
    }

    if (update) updateFormData(s, {...Object.assign({}, emptyForm), ...{key: formKey}});

    return 0;
  },

  isLoading: (formKey: string) => isLoadingByKey(s, formKey),
  setLoading: (formKey: string, loading: boolean) => {
    const formData = getFormDataByKey(s, formKey);
    if (!formData) return;

    formData.loading = loading;
    updateFormData(s, formData, false);
  },
  hasSubscribed: (formKey: string) => hasSubscribedByKey(s, formKey),
  setSubscribed: (formKey: string, subscribed: boolean) => {
    const formData = getFormDataByKey(s, formKey);
    if (!formData) return;

    formData.subscribed = subscribed;
    updateFormData(s, formData, false);
  },

  getFormStep: (formKey: string) => getFormStepByKey(s, formKey),
  setFormStep: (formKey: string, step: number) => {
    const formData = getFormDataByKey(s, formKey);
    if (!formData) return;

    formData.step = step;
    updateFormData(s, formData, false);
  },

  getFieldData: (formKey: string, fieldKey: string | null = null, parentKey: string | null = null) => getFormFieldDataByKey(s, formKey, fieldKey, parentKey),
  setFieldData: (formKey: string, fieldKey: string, parentKey: string | null = null, fieldValue: any | null = null) => updateFieldData(s, formKey, fieldKey, parentKey, fieldValue),

  resetFormData: (formKey: string) => {
    // Remove data from formState.
    updateFormData(s, Object.assign({}, emptyForm), false);
    // Remove data from local Storage.
    removeLocalStorageItem(formKeyPrefix + formKey);
  },
  submitForm: (formKey: string, originalData: any, additionalData: any, apiCall: any | null, fallback: any | null = null) => {
    let formData: any = {};

    // If PATCH mode, then we only care about changed values.
    forEach(getFormDataByKey(s, formKey).fields, (value: any, key: any) => {
      if (!originalData?.id || (value !== originalData[key])) {
        formData[key] = value;
      }
    });

    // Particular CREATE when pre-filled / default values (e.g. Shortcut entity).
    if (!isEmpty(originalData) && !originalData.id) {
      forEach(originalData, (value: any, key: any) => {
        if (value !== formData[key]) {
          formData[key] = value;
        }
      });
    }

    // Let's add additionalData if any.
    forEach(additionalData || [], (value: any, key: any) => {
      formData[key] = value;
    });

    // Return API-Call logic or fallback (if any).
    if (apiCall && !isEmpty(formData)) {
      return apiCall(formData);
    }

    if (fallback) fallback(formData);
  },
  state: () => s,
})

export const useFormState = () => wrapper(useHookstate(state));
