import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';

import { dialog, iri, trans, ucfirst } from 'utilities';
import { PageLoader } from 'components';
import { FormWrapper, UncontrolledStaticListField } from 'forms';
import { IRequestParams } from 'services';
import { useFormState } from 'states';

import { omit, replace } from 'lodash';
import appUri from 'config/appUri.json';

export const Step1 = (props: any) => {
  const { id, apim, baseUrl, cancelUrl, versions, data, baseDataPaths, lastUpdate } = props;
  const { t, navigate} = apim.di();
  const { modificationScope, vid } = useParams();
  const formState = useFormState();
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [editForm, setEditForm] = useState<boolean>(false);

  // Preload event types.
  const [eventTypes, setEventTypes] = useState<any[]>();
  useEffect(() => {
    apim.getList('dossierEventTypes', {
      success: (res: AxiosResponse) => {
        setEventTypes((res?.data['hydra:member'] || []).map((et: any) => {
          return {
            ...et,
            ...{translated: trans(t, 'form|dossierEventType.' + et.value)}
          }
        }));
      }
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const switchEvent = (eventId: string, modification: string) => navigate(`${replace(appUri.cie.cli.edit, ':id', id || '_')}/version/${eventId}/${modification ?? 'actuel'}?maj=${Date.now()}`);
  const onCompanyEventFormSubmit = (eventData: any) => {
    setVisible(false);
    setLoading(true);

    apim.postEntity({
      resourceType: 'dossierEvents',
      data: {
        ...eventData,
        ...{
          company: iri('dossierCompanies', id),
        }
      },
      success: (resEventData: AxiosResponse) => {
        if (resEventData?.data?.companyData?.id) {
          apim.patchEntity({
            resourceType: 'dossierCompanyData',
            id: resEventData.data.companyData.id,
            setLoading: setLoading,
            data: {
              ...omit(formState.getFormData('company#' + data?.id)?.fields || [], baseDataPaths),
              ...{
                company: iri('dossierCompanies', id),
                event: iri('dossierEvents', resEventData.data.id)
              }
            },
            success: () => switchEvent(resEventData.data.companyData.id, modificationScope ?? 'actuel')
          } as IRequestParams).then();
        }
      }
    } as IRequestParams).then();
  };

  const onCompanyEventEditFormSubmit = (eventData: any) => {
    setVisible(false);
    setLoading(true);

    apim.patchEntity({
      resourceType: 'dossierEvents',
      id: data?.event?.id,
      data: eventData,
      setLoading: setLoading,
      success: () => switchEvent(data?.id, modificationScope ?? 'actuel')
    } as IRequestParams).then();
  };

  const renderCompanyEventForm = useCallback(() => !editForm ?
      <FormWrapper formKeyPrefix={'company_' + id + '_EventType'} resourceType={'dossierEvents'} onSubmit={onCompanyEventFormSubmit}
                   classes={'grid p-fluid w-12'} hideReload listsOptions={{ societeClientEventTypes: eventTypes || [] }}/> :
      <FormWrapper formKeyPrefix={'company_' + id + '_EventType_' + data?.event?.id} resourceType={'dossierEvents'} onSubmit={onCompanyEventEditFormSubmit}
                   classes={'grid p-fluid w-12'} hideReload listsOptions={{ societeClientEventTypes: eventTypes || [] }} data={data?.event}/>
    , [data?.event?.id, eventTypes, lastUpdate, editForm]); // eslint-disable-line react-hooks/exhaustive-deps

  const modificationOptions = [
    {
      label: ucfirst(trans(t, 'ent.currentAG')),
      value: 'actuel'
    },
    {
      label: ucfirst(trans(t, 'ent.nextAG')),
      value: 'suivant'
    },
    {
      label: ucfirst(trans(t, 'ent.previousAG')),
      value: 'precedent'
    }
  ];

  return (data && !loading) ? (
    <div className={'a8-wizard wizard-societe-client card fadein animation-duration-500'}>
      <h5 className={'px-2 pt-3'}>
        {trans(t, 'menu|wizard.company.client.title.step1')}
      </h5>

      <p className={'px-2'}>
        {trans(t, 'form|text.company.helpStep1Line1')}<br/>
        <span dangerouslySetInnerHTML={{__html: trans(t, 'form|text.company.helpStep1Line2')}}></span><br/>
        <span dangerouslySetInnerHTML={{__html: trans(t, 'form|text.company.helpStep1Line3')}}></span>
      </p>
      <div className={'flex flex-row'}>
        <div className={'a8-form societe-client-context pt-5 max-w-30rem flex flex-row flex-wrap'}>
          <div className={'flex-none flex p-2 pr-0 w-full'}>
            <div className={'field p-inputgroup flex-grow-1'}>
            <span className={'p-float-label'}>
              <Dropdown id={'a8-event-select'} value={data?.id} options={versions} optionLabel={'event.formatted'} optionValue={'id'} className={'border-round-left'}
                        onChange={(e) => switchEvent(e.value, modificationScope ?? 'actuel')}
                        placeholder={ucfirst(trans(t, 'system|confirmations.context'))}/>
              <label htmlFor={'a8-event-select'} className={'text-ucfirst'}>{trans(t, 'dossierPermanent')}</label>
            </span>
              <span className={'p-inputgroup-addon'}><i className={'pi pi-calendar'}></i></span>
            </div>

            <Dialog visible={visible} modal header={ucfirst(trans(t, 'system|confirmations.context'))} onHide={() => setVisible(false)}>
              <div className={'m-0'}>{renderCompanyEventForm()}</div>
            </Dialog>
          </div>
          <UncontrolledStaticListField listsOptions={modificationOptions} optionLabel={'label'} fieldKey={'modificationScope'} value={modificationScope}
                                       classes={'w-full pl-2'} onFieldChange={(e: string) => switchEvent(vid ?? 'undefined', e)} label={trans(t, 'ent.modificationScope')}/>
          <span className={'help text-xs pt-2 ml-2 block'}>{trans(t, `form|text.company.modificationScope${ucfirst(modificationScope ?? 'actuel')}`)}</span>
        </div>
        <div className={'flex pt-5 pl-5'}>
          <span className={'p-2'}>
            <Button disabled={!data?.event?.id || (versions ?? []).length < 2} className={'a8-ef-delete'} icon={'pi pi-trash'} rounded outlined severity={'danger'} aria-label={trans(t, 'delete')}
                    onClick={() => dialog(t, {
                      message: ucfirst(trans(t, 'system|confirmations.dossier.delete-event')),
                      icon: 'pi pi-trash',
                      accept: () => {
                        apim.deleteEntity({
                          resourceType: 'dossierEvents',
                          id: data?.event?.id,
                          success: () => window.location.replace(appUri.cie.cli.edit.replace(':id', id || '_') + '/version'),
                        } as IRequestParams);
                      }
                    })}
            />
            <Tooltip target={'.a8-ef-delete'} position={'top'} content={ucfirst(trans(t, 'system|actions.delete-ag'))}/>
          </span>
          <span className={'p-2'}>
            <Button className={'a8-ef-edit'} icon={'pi pi-pencil'} rounded outlined aria-label={trans(t, 'edit')}
                    onClick={() => {
                      setEditForm(true);
                      setVisible(true)
                    }}/>
            <Tooltip target={'.a8-ef-edit'} position={'top'} content={ucfirst(trans(t, 'system|actions.edit-ag'))}/>
          </span>
          <span className={'p-2'}>
            <Button className={'a8-ef-create'} icon={'pi pi-plus'} rounded outlined aria-label={trans(t, 'add')}
                    onClick={() => {
                      setEditForm(false);
                      setVisible(true)
                    }}/>
            <Tooltip target={'.a8-ef-create'} position={'top'} content={ucfirst(trans(t, 'system|actions.create-ag'))}/>
          </span>
        </div>
      </div>

      <div className={'text-right m-2 mr-3'}>
        <>
          <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(cancelUrl)}/>
          <Button type={'submit'} className={'text-ucfirst p-button-text'} label={trans(t, 'next')} icon={'pi pi-arrow-right'} iconPos={'right'} onClick={() => navigate(baseUrl + appUri.cie.cli.wizard.step2)}/>
        </>
      </div>
    </div>
  ) : (<PageLoader />);
};
