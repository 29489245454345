import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { Message } from 'primereact/message';
import { ProgressBar } from 'primereact/progressbar';
import { Steps } from 'primereact/steps';

import { dateString, isAGE, trans, ucfirst } from 'utilities';
import { Error, NotFound, Unauthorized } from 'pages';
import { Loader, PageLoader } from 'components';
import { IRequestParams, isAdmin, isClient, isExpertAdmin, useApim } from 'services';
import { useAppState } from 'states';

import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { Step5 } from './Step5';

import appUri from 'config/appUri.json';
import { forEach, omit, replace } from 'lodash';

export const SocieteClientWizardForm = () => {
  const { id, vid, step, modificationScope } = useParams();
  const apim = useApim();
  const { t, navigate } = apim.di();
  const appState = useAppState();
  const [searchParams] = useSearchParams();
  const lastUpdate = searchParams.get('maj');

  const [loading, setLoading] = useState<boolean>(false);
  const [versions, setVersions] = useState<any[]>([]);
  const [data, setData] = useState<any>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false)
  const urlScope =   modificationScope ?? 'actuel';
  const currentScope = modificationScope === 'actuel' ? 'current' : modificationScope === 'suivant' ? 'next' : 'previous';
  const header= {'X-A8-Modification-Scope': currentScope};

  const baseDataPaths: string[] = [
    'activityStartDate',
    'creation',
    'firstFiscalYearEnd',
    'firstFiscalYearStart',
    'rcsImmatriculationDate',
    'rneImmatriculationDate',
    'rcsNumber',
    'siren',
  ];

  useEffect(() => fetchCompanyData(), [id, vid, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps
  const fetchCompanyData = () => {
    setLoading(true);
    const params: any[] = [
      {label: 'company', value: id},
      {label: 'order[event.date]', value: 'desc'},
      {label: 'expand[]', value: 'dossier_company_data:read_event'},
      {label: 'expand[]', value: 'dossier_event:read_list'},
      {label: 'expand[]', value: 'dossier_company_data:read_associates'},
      {label: 'expand[]', value: 'associate:read'},
      {label: 'expand[]', value: 'personne_physique:read_list'},
      {label: 'expand[]', value: 'dossier_company_data:read_personnes_morales'},
      {label: 'expand[]', value: 'personne_morale:read_list'},
      {label: 'expand[]', value: 'personne_physique:read_companies'},
      {label: 'expand[]', value: 'personne_physique:read_address'},
      {label: 'expand[]', value: 'personne_physique:read_birth_address'},
      {label: 'expand[]', value: 'personne_physique:read_latest_version'},
      {label: 'expand[]', value: 'personne_physique_data:read_list'},
      {label: 'expand[]', value: 'personne_physique_data:read_address'},
      {label: 'expand[]', value: 'personne_physique_address:read_list'},
      {label: 'expand[]', value: 'dossier_company_data:read_employees'},
      {label: 'expand[]', value: 'personne_physique_employee:read_list'},
      {label: 'expand[]', value: 'personne_physique_employee:read_person'},
      {label: 'expand[]', value: 'dossier_company:read_list'},
      {label: 'expand[]', value: 'dossier_company:read_latest_version'},
      {label: 'expand[]', value: 'dossier_company_data:read_company'},
      {label: 'expand[]', value: 'dossier_company_data:read_clauses'},
      {label: 'expand[]', value: 'dossier_company_clause:read'}
    ];

    apim.fetchEntities({
      resourceType: 'dossierCompanyData',
      params,
      cache: false,
      setLoading,
      setErrored,
      setNotFound,
      formatter: (items: any[]) => (items || []).map((i: any) => {
        return {
          ...omit(i, ['event']),
          ...{
            event: {
              ...i.event,
              ...{ formatted: i.event.label ? i.event.label : dateString(i.event.date) }
            }
          }
        };
      }),
      setter: setVersions,
      success: (res: AxiosResponse) => {
        if (!vid && (res.data['hydra:member'] ?? []).length > 0) {
          return navigate(replace(appUri.cie.cli.edit, ':id', id!) + '/version/' + res.data['hydra:member'][0].id + '/' + urlScope);
        }
        forEach(res.data['hydra:member'] ?? [], (event: any) => {
          // Merge company base fields into event fields to further needs.
          if (event?.id === vid) {
            let clauses: string[] = [];
            (event['clauses'] ?? []).forEach((item: any) => {
              clauses.push(item.clause);
            });

            setData({
              ...event?.company,
              ...{ clauses: clauses},
              ...omit(event, ['company', 'clauses']),
              ...{ companyId: event?.company?.id }
            })
          }
        });
      }
    } as IRequestParams).then();
  }

  const baseUrl = replace(appUri.cie.cli.edit, ':id', id || '_') + '/version/' + vid + '/' + urlScope;
  const cancelUrl = replace(appUri.cie.cli.page, ':id', id || '_') + '?maj=' + Date.now();

  // Update Page Header.
  useEffect(() => {
    appState.setPageTitle(ucfirst(trans(t, 'company')) + ' ' + (data?.nomCommercial || data?.raisonSociale || '') + ' | ' + trans(t, 'editing'));
    appState.setPageActions([]);
  }, [id, vid, step, data?.id, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  const [activeIndex, setActiveIndexState] = useState<number>();
  const setActiveIndex = (step: number) => setActiveIndexState(step);

  // Update active index, current company & breadcrumb.
  useEffect(() => {
    const defaultBC = [
      { label: trans(t, 'editing'), to: baseUrl },
      { label: ucfirst(trans(t, 'company')) + ' ' + (data?.nomCommercial || data?.raisonSociale || ''), to: baseUrl },
      { label: trans(t, 'context') + (data?.event?.id ? ' - ' + (data?.event?.label || dateString(data.event.date)) : ''), to: baseUrl }
    ];

    switch (step) {
      default:
        setActiveIndex(0);
        break;
      case 'immatriculation':
        setActiveIndex(1);
        defaultBC.push({
          label: trans(t, 'menu|wizard.company.client.title.step2'),
          to: baseUrl + appUri.cie.cli.wizard.step2
        });
        break;
      case 'personnes-physiques':
        setActiveIndex(2);
        defaultBC.push({
          label: trans(t, 'menu|wizard.company.client.title.step3'),
          to: baseUrl + appUri.cie.cli.wizard.step3
        });
        break;
      case 'personnes-morales':
        setActiveIndex(3);
        defaultBC.push({
          label: trans(t, 'menu|wizard.company.client.title.step4'),
          to: baseUrl + appUri.cie.cli.wizard.step4
        });
        break;
      case 'repartition-capital':
        setActiveIndex(4);
        defaultBC.push({
          label: trans(t, 'menu|wizard.company.client.title.step5'),
          to: baseUrl + appUri.cie.cli.wizard.step5
        });
        break;
    }

    appState.setBreadcrumb(defaultBC);
  }, [id, vid, step, data?.id, modificationScope]); // eslint-disable-line react-hooks/exhaustive-deps

  const wizardItems = [
    { label: ucfirst(trans(t, 'menu|wizard.company.client.title.step1')), command: () => navigate(baseUrl) },
    { label: ucfirst(trans(t, 'immatriculation')), command: () => navigate(baseUrl + appUri.cie.cli.wizard.step2) },
    { label: ucfirst(trans(t, 'personnePhysique', 2)), command: () => navigate(baseUrl + appUri.cie.cli.wizard.step3) },
    { label: ucfirst(trans(t, 'personneMorale', 2)), command: () => navigate(baseUrl + appUri.cie.cli.wizard.step4) },
    { label: ucfirst(trans(t, 'repartitionCapital')), command: () => navigate(baseUrl + appUri.cie.cli.wizard.step5) },
  ];

  const buildTabContent = useCallback(() => {
    switch (activeIndex) {
      case -1:
        return <Loader/>
      case 0:
        return <Step1 id={id} versions={versions} data={data} apim={apim} baseUrl={baseUrl} cancelUrl={cancelUrl} baseDataPaths={baseDataPaths}/>;
      case 1:
        return <Step2 data={data} apim={apim} baseUrl={baseUrl} cancelUrl={cancelUrl} baseDataPaths={baseDataPaths} header={header}/>;
      case 2:
        return <Step3 data={data} setData={setData} apim={apim} baseUrl={baseUrl} cancelUrl={cancelUrl} header={header}/>;
      case 3:
        return <Step4 data={data} setData={setData} apim={apim} baseUrl={baseUrl} cancelUrl={cancelUrl} header={header}/>;
      case 4:
        return <Step5 data={data} apim={apim} baseUrl={baseUrl} cancelUrl={cancelUrl} header={header} onRefresh={fetchCompanyData}/>;
      default:
        return <NotFound asBlock />
    }
  }, [id, vid, step, activeIndex, data, currentScope, urlScope, modificationScope, header]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isClient()) return <Unauthorized asBlock/>;
  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (loading || (undefined === activeIndex)) return <PageLoader/>;

  return (-1 !== activeIndex && versions?.length > 0) ? (
    <>
      <div className={'grid'}>
        <div className={'col'}>
          <ProgressBar value={20 + activeIndex * 20} showValue={false}/>
        </div>
      </div>
      <Steps model={wizardItems} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false}/>

      {!isAGE(data?.event) && (
        (isAdmin() || isExpertAdmin() ? (
            <Message className={'mb-4 flex justify-content-center'} severity={'info'} text={trans(t, 'form|info.age-unselected')}/>
        ) : (
            <Message className={'mb-4 flex justify-content-center'} severity={'warn'} text={trans(t, 'form|warning.age-unselected')}/>
        ))
      )}

      {buildTabContent()}
    </>
  ) : (
    <NotFound asBlock/>
  );
};
