import React from 'react';

import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';

import { trans } from 'utilities';
import { useApim } from 'services';
import { UncontrolledBaseField } from './UncontrolledBaseField';

import { isArray, isString, uniq } from 'lodash';

export const UncontrolledStaticListField = (props: any) => {
  const { fieldKey, value, listKey, listsOptions, onFieldChange, label, placeholder, classes, description, addon,
    addonPosition, optionLabel, optionValue, showClear, itemTemplate, multiple, uniqKeys, filter } = props;

  const apim = useApim();
  const { t } = apim.di();

  // Let's parse the value to properly handle multiple / single + array / !array cases.
  let v: any = value;
  if (multiple) {
    v = (isArray(value) ? value : [value ?? {}])
      .filter((_valid: any) => _valid !== undefined && _valid !== null)
      .map((_v: any) => isString(_v) ? _v : (_v[optionValue ?? 'value']));
    // Avoid duplicate items.
    if (uniqKeys !== false) v = uniq(v);
  } else {
    if (isArray(v)) {
      if (v.length > 0) {
        v = v[0];
      }

      v = isString(v) ? v : (v[optionValue ?? 'value']);
    }
  }

  return (
    <UncontrolledBaseField classes={classes} addon={addon} addonPosition={addonPosition} description={description} >
      <span className={'p-float-label'}>
        {true === multiple ? (
          <MultiSelect
            id={fieldKey} className={'w-full'} placeholder={placeholder ?? trans(t, 'form|select')} value={v} display={'chip'}
            options={(listsOptions ?? {})[listKey]} optionLabel={optionLabel ?? 'translated'} optionValue={optionValue ?? 'value'}
            onChange={(e) => onFieldChange(e.value)} showClear={showClear} itemTemplate={itemTemplate} filter={filter !== false}
          />
        ) : (
          <Dropdown
            id={fieldKey} className={'w-full'} placeholder={placeholder ?? trans(t, 'form|select')} value={v} filter={filter !== false}
            options={listKey ? (listsOptions ?? {})[listKey] : listsOptions} optionLabel={optionLabel ?? 'translated'} optionValue={optionValue ?? 'value'}
            onChange={(e) => onFieldChange(e.value)} showClear={showClear} itemTemplate={itemTemplate}
          />
        )}

        {(label || fieldKey) && (
          <label className={'text-ucfirst p-float-label-label'} htmlFor={fieldKey}>
            <span>{label ?? trans(t, fieldKey)}</span>
          </label>
        )}
      </span>
    </UncontrolledBaseField>
  );
};
