import React, { useEffect } from 'react';

import { trans, ucfirst } from 'utilities';
import { useAppState } from 'states';
import { isAdmin, useApim } from 'services';
import { SocietesClientDatatable } from 'components';

import appUri from 'config/appUri.json';

export const SocietesClientList = () => {
  const appState = useAppState();
  const apim = useApim();
  const { t } = apim.di();

  // Update Page Header.
  useEffect(() => {
    appState.setPageTitle(trans(t, 'menu|pages.title.societe.clients'));
    appState.setBreadcrumb([{ label: ucfirst(trans(t, 'company', 2)) }]);

    const newPA: any[] = [];
    if (isAdmin()) {
      newPA.push({ label: trans(t, 'cie.add'), icon: 'plus', to: appUri.cie.cli.add, className:'bg-green-500' });
    }
    appState.setPageActions(newPA);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <SocietesClientDatatable tableKey={'societes-clients'} noFilters title={trans(t, 'menu|pages.title.societe.clients')} apim={apim} />;
};
