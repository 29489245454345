import React, { useState } from 'react';

import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { SpeedDial } from 'primereact/speeddial';

import { dialog, shortNumber, trans, ucfirst } from 'utilities';
import { DatatableWrapper, dateTimeCell, validCell } from 'components';
import { IRequestParams, isAdmin, isExpertAdmin, isSuperAdmin } from 'services';

import { replace, union } from 'lodash';
import appUri from 'config/appUri.json';

export const SocietesExpertDatatable = (props: any) => {
  const { tableKey, title, params, lazyConfig, rowUri, noFilters, context, apim } = props;
  const { t, navigate } = apim.di();

  const filtersEnabled = (!noFilters || false === noFilters);
  const [disabledItems, setDisabledItems] = useState<any[]>([]);

  const lazyC = {...{
    sortField: 'nomCommercial',
    sortOrder: 1,
    filters: {
      nomCommercial: { value: '', matchMode: 'contains' },
      raisonSociale: { value: '', matchMode: 'contains' },
      siren: { value: '', matchMode: 'contains' },
      villeRCS: { value: '', matchMode: 'contains' }
    }
  }, ...lazyConfig};

  let newParams = params;

  if (tableKey === 'expert-societes-expert' && context.expert) {
    // We are displaying the dossiers for an expert entry.
    const expert = context.expert;
    newParams = union(params, [{label: 'experts.id', value: expert.id}]);
  }

  const capitalBodyTemplate = (rowData: any) => shortNumber(rowData.capital);
  const representantBodyTemplate = (rowData: any) => {
    if (rowData.representantLegal === null || rowData.representantLegal === undefined) {
      return 'N/A';
    }

    return <div className={'flex flex-column'}>
      <div>{rowData.representantLegal.name}</div>
      <div className={'text-sm mt-1'}>{rowData.representantLegal.phone}</div>
      <div className={'text-sm mt-1'}>{rowData.representantLegal.email}</div>
    </div>
  };

  const updatedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.updated);
  const createdBodyTemplate = (rowData: any) => dateTimeCell(rowData?.created);
  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);
  const deletedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.deleted);

  const actionsBodyTemplate = (rowData: any) => {
    let items = [];

    if (isAdmin() || isExpertAdmin()) {
      items.push({
        label: ucfirst(trans(t, 'system|actions.edit')),
        icon: 'pi pi-pencil',
        className: 'bg-indigo-500',
        command: () => {
          navigate(replace(appUri.cie.exp.edit, ':id', rowData?.id || '_'));
        }
      });
    }

    if (isAdmin()) {
      items.push({
        label: rowData?.active ? ucfirst(trans(t, 'system|actions.disable')) : ucfirst(trans(t, 'system|actions.enable')),
        icon: rowData?.active ? 'pi pi-ban' : 'pi pi-check',
        className: 'bg-orange-500',
        command: () => {
          if (rowData?.id) {
            const active= !rowData.active;
            const action= active ? 'enable' : 'disable';

            apim.patchEntity({
              resourceType: 'societesExperts',
              id: rowData.id,
              data: { active: active },
              notifSuccess: {
                summary: trans(t, 'notification|societeExpert.'+action+'.summary'),
                details: trans(t, 'notification|societeExpert.'+action+'.details'),
              },
              success: () => {
                rowData.active = active;
              },
            } as IRequestParams);
          }
        }
      });

      if (rowData.deleted === null || rowData.deleted === undefined) {
        items.push({
          label: ucfirst(trans(t, 'system|actions.archive')),
          icon: 'pi pi-folder',
          className:'bg-red-500',
          command: () => {
            dialog(t, {
              message: ucfirst(trans(t, 'system|confirmations.societeExpert.archive')),
              accept: () => {
                if (rowData?.id) {
                  const deleted = new Date();
                  apim.patchEntity({
                    resourceType: 'societesExperts',
                    id: rowData?.id,
                    data: {
                      active: false,
                      deleted: deleted
                    },
                    notifSuccess: {
                      summary: trans(t, 'notification|societeExpert.archive.summary'),
                      details: trans(t, 'notification|societeExpert.archive.details'),
                    },
                    success: () => {
                      rowData.deleted = deleted;

                      const _disabledItems: any[] = disabledItems.filter((d: string) => d !== rowData.id);
                      _disabledItems.push(rowData.id);
                      setDisabledItems(_disabledItems);
                    },
                  } as IRequestParams);
                }
              },
              acceptClassName: 'p-button-danger',
              rejectClassName: 'p-button-text p-button-primary'
            });
          }
        });
      }
    }

    if (isSuperAdmin()) {
      items.push({
        label: ucfirst(trans(t, 'system|actions.delete')),
        icon: 'pi pi-trash',
        className:'bg-red-500',
        command: () => {
          dialog(t, {
            message: ucfirst(trans(t, 'system|confirmations.societeExpert.delete')),
            accept: () => {
              if (rowData?.id) {
                apim.deleteEntity({
                  resourceType: 'societesExperts',
                  id: rowData?.id,
                  success: () => {
                    const _disabledItems: any[] = disabledItems.filter((d: string) => d !== rowData.id);
                    _disabledItems.push(rowData.id);
                    setDisabledItems(_disabledItems);
                  },
                } as IRequestParams);
              }
            },
            acceptClassName: 'p-button-danger',
            rejectClassName: 'p-button-text p-button-primary'
          });
        }
      });

      if (rowData.deleted !== null && rowData.deleted !== undefined) {
        items.push({
          label: ucfirst(trans(t, 'system|actions.unarchive')),
          icon: 'pi pi-folder-open',
          className: 'bg-red-500',
          command: () => {
            dialog(t, {
              message: ucfirst(trans(t, 'system|confirmations.societeExpert.unarchive')),
              accept: () => {
                if (rowData?.id) {
                  apim.patchEntity({
                    resourceType: 'societesExperts',
                    id: rowData?.id,
                    data: {
                      active: true,
                      deleted: null
                    },
                    notifSuccess: {
                      summary: trans(t, 'notification|societeExpert.unarchive.summary'),
                      details: trans(t, 'notification|societeExpert.unarchive.details'),
                    },
                    success: () => {
                      rowData.deleted = null;
                      rowData.active = true;
                    },
                  } as IRequestParams);
                }
              },
              acceptClassName: 'p-button-danger',
              rejectClassName: 'p-button-text p-button-primary'
            });
          }
        });
      }
    }

    return <>
      <Tooltip target={'.a8-speedial-datatable .p-speeddial-action'} position={'top'} mouseTrack />
      <SpeedDial className={'a8-speedial-datatable relative z-5'} model={items} direction={'left'} transitionDelay={40} showIcon={'pi pi-ellipsis-v'} hideIcon={'pi pi-times'} buttonClassName={'p-button-text'} />
    </>
  };

  return (
    <DatatableWrapper
      resourceType={'societesExpertsIndex'} tableKey={tableKey || 'societes-experts'} params={newParams} lazyConfig={lazyC} rowUri={rowUri || appUri.cie.exp.page} disabledItems={disabledItems}
      title={title || trans(t, 'menu|pages.title.societe.experts')} noFilters={noFilters} onNew={isAdmin() ? () => navigate(appUri.cie.exp.add) : null} addTitle={trans(t, 'se.add')}>
      <Column field={'nomCommercial'} header={trans(t, 'ent.nomCommercial')} sortable filter={filtersEnabled} />
      <Column field={'raisonSociale'} header={trans(t, 'ent.raisonSociale')} sortable filter={filtersEnabled} />
      <Column field={'type'} header={trans(t, 'type')} sortable filter={filtersEnabled} style={{ width: '80px' }} />
      <Column field={'siren'} header={trans(t, 'ent.siren')} align={'center'} sortable filter={filtersEnabled} style={{ width: '80px' }} />
      <Column field={'rcsCity'} header={trans(t, 'ent.rcsNumber')} align={'center'} sortable filter={filtersEnabled} style={{ width: '150px' }} />
      <Column field={'capital'} header={trans(t, 'ent.capital')} align={'center'} sortable body={capitalBodyTemplate} style={{ width: '150px' }} />
      <Column field={'representantLegal.firstName'} header={trans(t, 'representantLegal')} sortable filter={filtersEnabled} body={representantBodyTemplate} style={{ width: '225px' }} />
      <Column field={'updated'} header={trans(t, 'updated')} sortable align={'center'} body={updatedBodyTemplate} style={{ width: '225px' }} />
      <Column field={'created'} header={trans(t, 'created')} sortable align={'center'} body={createdBodyTemplate} style={{ width: '225px' }} />
      {isSuperAdmin() &&
        <Column field={'deleted'} header={trans(t, 'deleted')} sortable filter={filtersEnabled}
                align={'center'} body={deletedBodyTemplate} style={{ width: '100px' }} />}
      {(isAdmin()) &&
        <Column field={'active'} header={trans(t, 'active')} sortable filter={filtersEnabled}
                dataType={'boolean'} align={'center'} body={activeBodyTemplate} style={{ width: '100px' }} />}
      {(isAdmin() || isExpertAdmin()) && <Column header={trans(t, 'system|action', 2)} align={'right'} body={actionsBodyTemplate} style={{ width: '80px', maxWidth: '80px' }} />}
    </DatatableWrapper>
  );
};
