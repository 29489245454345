import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { FormWrapper } from 'forms';
import { iri, trans, ucfirst } from 'utilities';
import { IRequestParams } from 'services';
import { Error } from 'pages';
import { PageLoader } from 'components';

export const PatrimoineFoyer = (props: any) => {
  const { data, dossier, apim } = props;
  const { t } = apim.di();

  const [refresher, setRefresher] = useState<number>(Date.now);
  const [loading, setLoading] = useState<boolean>(false);
  const [patrimoineFoyer, setPatrimoineFoyer] = useState<any>(null);
  const [errored, setErrored] = useState<boolean>(false);

  const resourceType = 'patrimoineFoyers';

  useEffect(() => {
    setLoading(true);

    apim.fetchEntities({
      resourceType,
      params: [{label: 'patrimoine', value: data?.id}],
      cache: false,
      setErrored,
      success: (res: AxiosResponse) => {
        if ((res?.data['hydra:member'] ?? []).length === 0) {
          // Let's create data for this patrimoine foyer.
          apim.postEntity({
            resourceType,
            data: {patrimoine: iri('patrimoines', data?.id)},
            setErrored,
            setLoading,
            setter: setPatrimoineFoyer
          } as IRequestParams).then();
        } else {
          // Use existing patrimoine foyer.
          setLoading(false);
          setPatrimoineFoyer(res.data['hydra:member'][0]);
        }
      }
    } as IRequestParams).then();
  }, [data?.id, refresher]); // eslint-disable-line react-hooks/exhaustive-deps

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderForm = useCallback(() =>
      <FormWrapper classes={'grid px-3'} formKeyPrefix={'foyer'} resourceType={'patrimoineFoyers'} cancelLink data={patrimoineFoyer}
                   context={{patrimoine: data, dossier: dossier}} hideReload={true} callback={() => setRefresher(Date.now())}/>
    , [patrimoineFoyer]); // eslint-disable-line react-hooks/exhaustive-deps

  if (errored) return <Error asBlock/>;
  if (loading) return <PageLoader/>;

  return (
    <div className={'a8-form patrimoine-foyer'}>
      <h5 className={'mx-3 pb-5 flex flex-row align-items-center'}>
        {ucfirst(trans(t, 'menu|menu.schema_flux.patrimoine'))}
      </h5>

      {renderForm()}
    </div>
  );
};
