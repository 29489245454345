import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import { classNames } from 'primereact/utils';
import { AutoComplete, AutoCompleteCompleteEvent } from 'primereact/autocomplete';
import { Skeleton } from 'primereact/skeleton';

import { trans, ucfirst } from 'utilities';
import { IRequestParam, IRequestParams, useApim } from 'services';

import { forEach, includes, isArray, isString } from 'lodash';

export const APIAutocompleteField = (props: any) => {
  const { fieldKey, control, onFieldChange, errorMessage, rules, label, placeholder, classes, multiple, description,
    tooltip, tooltipOptions, disabled, getValues, resourceType, params, itemTemplate, optionKey, optionValue, formatter, action } = props;

  const apim = useApim();
  const { t } = apim.di();

  const [items, setItems] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // A field key can join multiple possibilities using '||' separator.
  const split = fieldKey.split('||');

  // Load initial field value as object.
  // We have UUID(s) and we want to loaded object(s).
  useEffect(() => {
    const v: any = (getValues() ?? {})[fieldKey] ?? [];
    const vs: any[] = isArray(v) ? v : [v];

    if (vs.length > 0) {
      setLoading(true);

      const initParams = params ?? [];
      initParams.push({label: 'itemsPerPage', value: v.length});
      forEach(vs, ((val: any) => initParams.push({ label: 'id[]', value: isString(val) ? val : val.id })));

      if (action) {
        apim.call({
          resourceType,
          action,
          method: 'get',
          params: initParams,
          notif: false,
          setter: setItems,
          formatter,
          setLoading
        } as IRequestParams).then();
      } else {
        apim.fetchEntities({
          resourceType: resourceType,
          params: initParams,
          setter: setItems,
          formatter,
          setLoading
        } as IRequestParams).then();
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const search = (event: AutoCompleteCompleteEvent) => {
    const fullParams = params || [];
    forEach([{label: 'itemsPerPage', value: 20}, {label: 'active', value: true}], ((p: IRequestParam) => {
      if (fullParams.filter((fp: IRequestParam) => fp.label === p.label).length === 0) {
        fullParams.push(p);
      }
    }));
    fullParams.push({label: 'search', value: event.query});

    if (action) {
      apim.call({
        resourceType: resourceType,
        action: action,
        method: 'get',
        params: fullParams,
        notif: false,
        setter: setItems,
        formatter: formatter,
      } as IRequestParams).then();
    } else {
      apim.fetchEntities({
        resourceType: resourceType,
        params: fullParams,
        setter: setItems,
        formatter: formatter,
      } as IRequestParams).then();
    }
  }

  const defaultItemTemplate = (option: any, props: any) => {
    if (option) {
      return (
        <div className={'flex align-items-center'}>
          <div>{option[optionKey ?? 'id']}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  return (<div className={classNames('field', classes ?? 'col-12 md:col-6 lg:col-4')}>
      {loading ?
        <Skeleton height={'2.8rem'}/> :
        <Controller
          name={split[0]}
          control={control}
          rules={rules}
          render={({ field, fieldState }) => {
            const v: any[] = (items ?? []).filter((i: any) => includes(isArray(field.value) ? field.value.map((fv: any) => fv[optionValue ?? 'id'] ?? fv) : [field.value], i[optionValue ?? 'id']));

            return <>
              <span className={'p-float-label'}>
                <AutoComplete id={field.name} className={classNames('w-full', {'p-invalid': fieldState.error})} placeholder={placeholder ?? ucfirst(trans(t, 'form|select'))} value={(multiple ? v : v[0]) ?? field.value}
                              suggestions={items} completeMethod={search} onChange={(e) => {multiple ?
                                onFieldChange(field, fieldState, (e.value ?? []).map((_v: any) => _v[optionValue ?? 'id'] ?? _v), 'join') :
                                onFieldChange(field, fieldState, e.value ? (e.value[optionValue ?? 'id'] ?? e.value) : e.value)
                              }}
                              field={optionKey ?? 'id'} itemTemplate={itemTemplate ?? defaultItemTemplate} dropdown forceSelection multiple={multiple}
                              tooltip={tooltip} tooltipOptions={tooltipOptions ?? {position: 'top'}} disabled={disabled}/>
                {(label ?? field.name) && (
                  <label className={'text-ucfirst p-float-label-label'} htmlFor={field.name}>
                    <span>{label ?? trans(t, field.name)}</span>
                  </label>
                )}
              </span>
              {description && (<div className={'pt-2'}><span className={'small'}>{description}</span></div>)}
              {errorMessage(field.name)}
            </>
          }}
        />
      }
  </div>
  );
};
