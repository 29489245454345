import { useState, useEffect } from 'react';

import { isValidUUID, trans } from 'utilities';
import { IRequestParams } from 'services';
import { useUserState } from 'states';

import appUri from 'config/appUri.json';

export const ShortcutsMenu = (props: any) => {
  const { apim } = props;
  const userState = useUserState();
  const { t } = apim.di();

  const [shortcuts, setShortcuts] = useState<any[]>([]);

  useEffect(() => {
    if (isValidUUID(userState.id())) {
      apim.fetchEntities({
        resourceType: 'shortcuts',
        params: [{label: 'user', value: userState.id()}],
        setter: setShortcuts,
        notif: false
      } as IRequestParams);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const shortcutItems = shortcuts.map((shortcut: any) => ({
    icon: 'bookmark',
    label: shortcut.name,
    to: shortcut.uri
  }));

  return shortcuts.length > 0 ? [{
    label: trans(t, 'menu|menu.shortcuts.list'),
    icon: 'bookmark',
    items: [
      ...(shortcuts.length > 0 ? [
        {
          icon: 'cog',
          label: trans(t, 'menu|menu.shortcuts.management'),
          to: appUri.short.list
        }
      ] : []),
      ...shortcutItems,
    ]
  }] : [];
};
