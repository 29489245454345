import React from 'react';
import { Link } from 'react-router-dom';

import { Fieldset } from 'primereact/fieldset';

import { APIAutocompleteField, APIListField, CheckboxField, MaskField, UncontrolledAPIListField, UncontrolledNumberField, UncontrolledTextField } from 'forms';
import { isValidUUID, trans, ucfirst, uuidFromIri } from 'utilities';
import { creditItemTemplate } from '../../credits';

import appUri from 'config/appUri.json';

export const BienEntrepriseForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, getValues, context } = props;
  const dossier = isValidUUID(context?.dossier?.id) ? context?.dossier?.id : uuidFromIri(context?.dossier);

  const {
    company = null,
    type = null,
    label = null,
    montantPleinePropriete = null,
    montantPleineProprieteImmobilier = null,
    fiscalYear = null,
    categorieRevenus = null,
    regimeImposition = null
  } = getValues();

  const economicDataEditUri = appUri.dos.edit.fiscalYear.uri
    .replace(':id', dossier)
    .replace(':year', fiscalYear)
    .replace(':company', company.id) + appUri.dos.edit.fiscalYear.steps.step1;
  const montantPleineProprieteLink = <span className={'block text-xs pt-1 pl-1 pb-2'}>{trans(t, 'form|patrimoine.biens.entreprise.montantHelp')} <Link to={economicDataEditUri}>{trans(t, 'form|patrimoine.biens.entreprise.economicDatas')}</Link></span>;
  const montantPleineProprieteImmobilierLink = <span className={'block text-xs pt-1 pl-1 pb-2'}>{trans(t, 'form|patrimoine.biens.entreprise.montantImmoHelp')} <Link to={economicDataEditUri}>{trans(t, 'form|patrimoine.biens.entreprise.economicDatas')}</Link></span>;

  const fiscalDataEditUri = appUri.dos.edit.fiscalYear.uri
    .replace(':id', dossier)
    .replace(':year', fiscalYear)
    .replace(':company', company.id) + appUri.dos.edit.fiscalYear.steps.step2;
  const categorieRevenusLink = <span className={'block text-xs pt-1 pl-1 pb-2'}>{trans(t, 'form|patrimoine.biens.entreprise.categorieRevenusHelp')} <Link to={fiscalDataEditUri}>{trans(t, 'form|patrimoine.biens.entreprise.fiscalDatas')}</Link></span>;
  const regimeImpositionLink = <span className={'block text-xs pt-1 pl-1 pb-2'}>{trans(t, 'form|patrimoine.biens.entreprise.regimeImpositionHelp')} <Link to={fiscalDataEditUri}>{trans(t, 'form|patrimoine.biens.entreprise.fiscalDatas')}</Link></span>;

  return <>
    <Fieldset legend={ucfirst(trans(t, 'general'))} className={'col-12 m-2'} toggleable>
      <div className={'grid w-full'}>
        <div className={'col-3'}>
          <APIListField listKey={'bienEntrepriseTypes'} fieldKey={'type'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                        label={trans(t, 'form|patrimoine.biens.default.type')} classes={'w-full'}/>
        </div>

        <div className={'col-3'}>
          <UncontrolledTextField
            fieldKey={'company'} value={label} classes={'w-full'}
            label={trans(t, 'form|patrimoine.biens.entreprise.company')} disabled/>
        </div>

        <div className={'col-3'}>
          <UncontrolledAPIListField listKey={'categoriesRevenus'} fieldKey={'categorieRevenus'} className={'w-full'} value={categorieRevenus}
                                    label={trans(t, 'form|patrimoine.biens.entreprise.categorieRevenus')} disabled description={categorieRevenusLink}/>
        </div>

        <div className={'col-3'}>
          <UncontrolledAPIListField listKey={'regimesImposition'} fieldKey={'regimeImposition'} className={'w-full'} value={regimeImposition}
                                    label={trans(t, 'form|patrimoine.biens.entreprise.regimeImposition')} disabled description={regimeImpositionLink}/>
        </div>
      </div>
      <div className={'grid w-full'}>
        <div className={'col-3'}>
          <MaskField fieldKey={'purchaseDate'} label={trans(t, 'form|patrimoine.biens.default.date')}
                     control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} isDate classes={'w-full'}/>
        </div>

        <div className={'col-3'}>
          <UncontrolledNumberField fieldKey={'montantPleinePropriete'} label={trans(t, 'form|patrimoine.biens.default.montant')} value={montantPleinePropriete}
                                   addon={'euro'} addonPosition={'after'} classes={'w-full'} disabled description={montantPleineProprieteLink}/>
        </div>

        {type === 'participation' && (
          <div className={'col-3'}>
            <UncontrolledNumberField fieldKey={'montantPleineProprieteImmobilier'} label={trans(t, 'form|patrimoine.biens.default.montantPleineProprieteImmobilier')} value={montantPleineProprieteImmobilier}
                                     addon={'euro'} addonPosition={'after'} classes={'w-full'} disabled description={montantPleineProprieteImmobilierLink}/>
          </div>
        )}
      </div>
      <div className={'grid w-full'}>
        <div className={'col-3'}>
          <CheckboxField fieldKey={'transmission'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                         label={trans(t, 'form|patrimoine.biens.default.transmission')} help={trans(t, 'form|patrimoine.biens.default.transmissionHelp')}/>
        </div>

        <div className={'col-3'}>
          <CheckboxField fieldKey={'securite'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                         label={trans(t, 'form|patrimoine.biens.default.securite')} help={trans(t, 'form|patrimoine.biens.default.securiteHelp')}/>
        </div>
      </div>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'form|patrimoine.biens.default.credit'))} className={'col-12 m-2'} toggleable>
      <div className={'col-12'}>
        <APIAutocompleteField fieldKey={'creditBiens'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'credit', 2)}
                              getValues={getValues} placeholder={ucfirst(trans(t, 'form|select'))} optionKey={'label'} itemTemplate={creditItemTemplate}
                              resourceType={'credits'} params={[{label: 'byDossierRelatives', value: dossier}]} multiple/>
      </div>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'civil'))} className={'col-12 m-2'} toggleable>
      <div className={'grid w-full'}>
        <div className={'col-3'}>
          <CheckboxField fieldKey={'impositionRepartieIfi'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                         label={trans(t, 'form|patrimoine.biens.default.ifi')}/>
        </div>

        <div className={'col-3'}>
          <CheckboxField fieldKey={'avantageMatrimonialPreciput'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                         label={trans(t, 'form|patrimoine.biens.default.matrimonialAdvantage')}/>
        </div>
      </div>
    </Fieldset>
  </>;
};
