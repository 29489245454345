import { IRequestParams, subscribe } from 'services';
import { dateString, iri } from 'utilities';
import { forEach } from 'lodash';

const debugMode = process.env.REACT_APP_DEBUG_MODE || '0';

export const formKey = 'a8_ged';
export const parentKey = 'uploaded';
export const parentKeyGed = 'ged';

export const getFiles = (formState: any, localParentKey: string = parentKey, selected: boolean = false, sort: boolean = true): any[] => {
  const files: any[] = formState.getFieldData(formKey, selected ? 'selectedFiles' : 'files', localParentKey) || [];

  return sort ? files.sort((a: any, b: any) => a.dateSort > b.dateSort ? -1 : 1) : files;
};

export const getFile = (formState: any, id: string, localParentKey: string = parentKey): any => {
  const relatedFile = getFiles(formState, localParentKey).filter((f: any) => f.id === id);

  return relatedFile.length > 0 ? relatedFile[0] : null;
};

export const updateFile = (formState: any, file: any, localParentKey: string = parentKey) => {
  let files: any[];

  // Handle particular id switch case.
  if (file.id.substring(0, 4) === 'NEW@') {
    files = getFiles(formState, localParentKey, false).filter((f: any) => f.id !== file.oldId);
    file.id = file.id.substring(4);
  } else {
    files = getFiles(formState, localParentKey, false).filter((f: any) => f.id !== file.id);
  }

  files.push(file);
  formState.setFieldData(formKey, 'files', localParentKey, files);
};

export const removeFile = (apim: any, formState: any, file: any, localParentKey: string = parentKey, distantRemove: boolean = true) => {
  const files: any[] = getFiles(formState, localParentKey, false).filter((f: any) => f.id !== file.id);
  formState.setFieldData(formKey, 'files', localParentKey, files);

  if (!distantRemove) return;

  apim.deleteEntity({
    resourceType: 'documents',
    id: file.id
  } as IRequestParams).then();
};

export const cancelFile = (apim: any, formState: any, file: any, localParentKey: string = parentKey) => {
  apim.call({
    resourceType: 'fileUploads',
    id: file.id,
    action: 'cancel',
    method: 'get',
    success: () => removeFile(apim, formState, file, localParentKey, false)
  } as IRequestParams).then();
};

export const subscribeToHub = (apim: any, formState: any) => {
  const userId = formState.getFieldData(formKey, 'userId');
  if (!userId) return;

  const subscribed = formState.hasSubscribed(formKey);
  if (subscribed) return;

  // Subscribe to the hub.
  subscribe(['/users/' + userId + '/ged'], ((m: any) => {
    if ('1' === debugMode) console.log(m);
    if (!m.data) return;

    const data = JSON.parse(m.data);
    if (!data) return;
    if (data.status !== 'processed') return;
    if (!data.id || !data.fileUuid) return;

    const relatedFile = getFile(formState, data.id);
    if (!relatedFile) return;

    relatedFile.active = true;
    relatedFile.status = 'processed';
    // /!\ Switch from fileUpload ID to DocumentID.
    relatedFile.id = 'NEW@' + (data.fileUuid ?? '');
    relatedFile.oldId = data.id;
    relatedFile.url = data.fileUrl;

    updateFile(formState, relatedFile);

    // Let's move this file from uploaded box to documents list.
    const newDocument: any = {
      ...data.fileData,
      ...{
        url: data.fileUrl,
        updated: data.updated,
        dateSort: dateString(data.updated, 'YYYY-MM-DD HH:mm:ss'),
        dossiers: relatedFile.dossiers,
        companies: relatedFile.companies
      },
    }

    const _data: any[] = (formState.getFieldData(formKey, 'files', parentKeyGed) ?? []).filter((f: any) => f.id !== data.id);
    formState.setFieldData(formKey, 'files', parentKeyGed, [newDocument].concat(_data));
    removeFile(null, formState, data.fileData, parentKey, false);

    // Handle pre-filled dossier / company.
    forEach(relatedFile?.dossiers ?? [], ((d: any) => {
      apim.postEntity({
        resourceType: 'dossierDocuments',
        notif: false,
        data: {
          document: data.fileUuid,
          dossier: iri('dossiers', d.id)
        }
      } as IRequestParams).then();
    }));

    forEach(relatedFile?.companies ?? [], ((c: any) => {
      apim.postEntity({
        resourceType: 'dossierCompanyDocuments',
        notif: false,
        data: {
          document: data.fileUuid,
          company: iri('dossierCompanies', c.id)
        }
      } as IRequestParams).then();
    }));
  }));

  formState.setSubscribed(formKey, true);
};
