export { RoutesAccount } from './Account';
export { RoutesDossier } from './Dossier';
export { RoutesGED } from './GED';
export { RoutesPerson } from './Person';
export { RoutesSettings } from './Settings';
export { RoutesShortcuts } from './Shortcut';
export { RoutesSociete } from './Societe';
export { RoutesSystem } from './System';
export { RoutesUser } from './User';
export { RoutesNotification } from './Notification';
