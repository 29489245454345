import { trans } from 'utilities';
import { isExpert, isAdmin, isSuperAdmin } from 'services';

import appUri from 'config/appUri.json';

export const AdminMenu = (props: any) => {
  const { apim } = props;
  const { t } = apim.di();
  if (!(isExpert() || isAdmin())) return [];

  const societesExperts: string[] = props?.userState?.societesExperts() ?? [];
  let items: any[] = [];
  if (isAdmin() || (societesExperts.length > 1)) {
    items.push({
      label: trans(t, 'menu|menu.societe.experts'),
      icon: 'briefcase',
      to: appUri.cie.exp.list
    });
  } else {
    if (isExpert() && (societesExperts.length === 1)) {
      items.push({
        label: trans(t, 'menu|menu.mon_cabinet'),
        icon: 'sitemap',
        to: appUri.cie.exp.page.replace(':id', societesExperts[0])
      });
    }
  }

  if (isAdmin() || isExpert()) {
    items.push({
      label: trans(t, 'menu|menu.user.experts'),
      icon: isExpert() ? 'briefcase' : 'users',
      to: appUri.usr.exp.list
    });

    items.push({
      label: trans(t, 'menu|menu.societe.clients'),
      icon: 'building',
      to: appUri.cie.cli.list
    });

    items.push({
      label: trans(t, 'menu|menu.user.clients'),
      icon: 'users',
      to: appUri.usr.dir.list
    });
  }
  if (isSuperAdmin()) {
    items.push({
      label: trans(t, 'menu|menu.user.personnes'),
      icon: 'users',
      to: appUri.cie.per.phy.list
    });
  }

  return [{
    label: trans(t, 'menu|menu.admin'),
    icon: 'desktop',
    items: items,
  }];
};
