import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Column } from 'primereact/column';

import { dialog, iri, isValidUUID, trans, triggerFormReset, triggerFormSubmit, ucfirst, uuidFromIri } from 'utilities';
import { DatatableWrapper, onPersonnePhysiqueAddSubmit, personCell, simpleCheckboxEditor, validCell } from 'components';
import { IRequestParam, IRequestParams } from 'services';
import { FormWrapper } from 'forms';

import appUri from 'config/appUri.json';

export const ContractSouscripteursDatatable = (props: any) => {
  const { apim, data, context, contractType, contractResourceType, title, editMode, setRefresher } = props;
  const { t } = apim.di();
  const dossier = isValidUUID(context?.dossier?.id) ? context?.dossier?.id : uuidFromIri(context?.dossier);

  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const resourceType: string = 'contractSouscripteurs';
  const params: IRequestParam[] = [{label: contractType, value: data?.id}];

  useEffect(() => {
    setLoading(true);
    apim.fetchEntities({
      resourceType,
      cache: false,
      params,
      setLoading,
      setter: setRows
    } as IRequestParams).then();
  }, [data, context]); // eslint-disable-line react-hooks/exhaustive-deps

  const labelBody = (rowData: any) => personCell({...rowData?.personnePhysique, ...{email: null, phone: null}},{
    url: appUri.cie.per.phy.page.replace(':id', rowData?.personnePhysique?.id),
    label: trans(t, 'seeDetails')
  });

  const addData: any = {};
  addData[contractType] = iri(contractResourceType, data?.id)

  const onNew = () => {
    dialog(t, {
      header: trans(t,'table|add.souscripteur'),
      icon: 'none',
      message: renderAddForm(),
      accept: () => triggerFormSubmit('a8-form-contrat-souscripteur-add'),
      reject: () => triggerFormReset('a8-form-contrat-souscripteur-reset')
    });
  };

  // Handle submission then refresh table rows.
  const onAddSubmit = useCallback(
    (formData: any) => onPersonnePhysiqueAddSubmit(apim, context, formData, rows, setRows, resourceType, setLoading, (_pp: any) => {
      if (!isValidUUID(_pp?.id)) return setLoading(false);

      // Avoid duplicates.
      if (rows.filter((_r: any) => _r?.personnePhysique?.id === _pp?.id).length > 0) {
        setLoading(false);

        return apim.displayError(trans(t, 'form|errors.alreadyExists.summary'), trans(t, 'form|errors.alreadyExists.detail'));
      }

      // Create the new row then refresh rows.
      apim.postEntity({
        resourceType,
        notif: false,
        data: {
          ...addData,
          ...{
            personnePhysique: iri('personnesPhysiques', _pp?.id),
            // Try to get last row indivision as model.
            indivision: rows.length > 0 ? (rows[0].indivision ?? true) : true
          }
        },
        setLoading,
        success: (resP: AxiosResponse) => {
          if (isValidUUID(resP?.data?.id)) {
            setRows([...rows, ...[resP.data]]);
            if (setRefresher) setRefresher(Date.now);
          }
        }
      } as IRequestParams).then();
    })
    , [context?.id, data?.id, rows]); // eslint-disable-line react-hooks/exhaustive-deps

  const onRefresh = (newRows: any[]) => {
    if (setRefresher) setRefresher(Date.now);
    setRows(newRows);
  };

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderAddForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid w-12'} resourceType={'personnesPhysiques'} formKeyPrefix={'add_pp_dialog'} cancelLink multiple context={{ patrimoine: context, dossierId: dossier }}
                   resetClass={'a8-form-contrat-souscripteur-reset'} submitClass={'a8-form-contrat-souscripteur-add'} onSubmit={onAddSubmit} hideReload/>
    , [context?.id, data?.id, rows]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DatatableWrapper resourceType={resourceType} tableKey={'souscripteurs'} title={title ?? trans(t, 'souscripteur', 2)}
                      noFilters noGlobalFilter paginator={false} params={params} isLoading={loading} editMode={editMode} onRefresh={onRefresh}
                      onNew={onNew} addTitle={trans(t, 'table|add.souscripteur')} additionalData={addData} editFields={['indivision']}
                      // Keep current dirigeant at the top of the list.
                      rows={(rows || []).sort((a: any) => a.personnePhysique?.id === context?.dirigeant?.id ? -1 : 1)}>
      <Column field={'personnePhysique'} header={ucfirst(trans(t, 'table|patrimoine_headers.personnePhysique'))} body={labelBody}/>
      <Column field={'indivision'} header={trans(t, 'table|patrimoine_headers.indivision')} align={'center'} alignHeader={'center'}
              body={(rowData: any) => validCell(rowData.indivision)}
              editor={(options) => simpleCheckboxEditor(options, {label: trans(t, 'form|patrimoine.contracts.default.indivision')})}/>
    </DatatableWrapper>
  );
};
