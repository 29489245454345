import React, { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { AxiosError, AxiosResponse } from 'axios';
import { useSearchParams } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';

import { asDate, dateString, dialog, getErrorMessage, humanFileSize, iri, isValidUUID, onApiError, removeLocalStorageItem, trans, ucfirst } from 'utilities';
import { useAppState, useFormState, useUserState } from 'states';
import { apiListAutocompleteEditor, dateTimeCell, DocumentViewer, Loader, maskEditor, multilinesCell, simpleCheckboxEditor, staticListEditor, textEditor, validCell } from 'components';
import { IRequestParam, IRequestParams, isAdmin, isClient, isSuperAdmin, useApim } from 'services';
import { UncontrolledAPIAutocompleteField, UncontrolledCheckboxField, UncontrolledStaticListField, UncontrolledTriStateField } from 'forms';
import { UploadedFiles } from './UploadedFiles';
import {
  formKey, parentKey as parentKeyUpload, parentKeyGed,
  getFile, getFiles, removeFile, updateFile,
  subscribeToHub
} from './GedHelper';

import { forEach, includes, isEmpty, isEqual, omit } from 'lodash';
import apimConfig from 'config/apimConfig.json';
import appUri from 'config/appUri.json';
import appConfig from 'config/appConfig.json';
import cloudFile from 'assets/images/baseline-cloud_upload-24px.svg';

export const DocumentsList = (props: any) => {
  const { dossier, company, module } = props;
  const apim = useApim();
  const userState = useUserState();
  const formState = useFormState();
  const appState = useAppState();

  const { t } = apim.di();

  const modalRef = useRef<any>(null);
  const viewerRef = useRef<any>(null);
  const [types, setTypes] = useState<any[]>([]);
  const [modules, setModules] = useState<any[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [documentPreview, setDocumentPreview] = useState<any>();

  let globalSearchTimer: ReturnType<typeof setTimeout>;
  const [fDossiers, setFDossiers] = useState<any[]>(isValidUUID(dossier?.id) ? [dossier?.id] : []);
  const [fCompanies, setFCompanies] = useState<any[]>(isValidUUID(company?.id) ? [company?.id] : []);
  const [fModules, setFModules] = useState<any[]>(isValidUUID(module) ? [module] : []);
  const [fTypes, setFTypes] = useState<any[]>([]);
  const [fActive, setFActive] = useState<boolean | null>(isSuperAdmin()? null : true);

  const [fIssued, setFIssued] = useState<boolean | null>(true);
  const [forceCheckIssued, setForceCheckIssued] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [lazyState, setlazyState] = useState<any>({
    first: searchParams.has('page') ? parseInt(searchParams.get('page') as string) * (searchParams.has('perPage') ? parseInt(searchParams.get('perPage') as string) : appConfig.pagination.default.itemsPerPage) : 0,
    rows: searchParams.has('perPage') ? parseInt(searchParams.get('perPage') as string) : appConfig.pagination.default.itemsPerPage,
    page: searchParams.has('page') ? parseInt(searchParams.get('page') as string) : 0,
    sortField: searchParams.has('sortField') ? searchParams.get('sortField') : 'created',
    sortOrder: searchParams.has('sortOrder') ? (searchParams.get('sortOrder') === 'ASC' ? 1 : -1) : -1,
    filters: { global: { value: null, matchMode: FilterMatchMode.CONTAINS } }
  });

  // Update Page Header & check hub subscription.
  useEffect(() => {
    let pageTitle: string = trans(t, 'menu|pages.title.ged');
    if (dossier?.id) pageTitle = trans(t, 'menu|pages.title.dossier.ged', 1, dossier.title);
    if (company?.id) pageTitle = trans(t, 'menu|pages.title.societe.ged', 1, company.latestVersion?.raisonSociale);
    appState.setPageTitle(pageTitle);

    let breadcrumb: any[] = [{ label: trans(t, 'ged') }];
    if (dossier?.id) breadcrumb = [
      isClient()
        ? { label: trans(t, 'dossier', 2) }
        : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: appState.dossier()?.title, to: appUri.dos.page.replace(':id', dossier?.id)},
      { label: trans(t, 'ged') }
    ];
    if (company?.id) breadcrumb = [
      { label: trans(t, 'company', 2), to: appUri.cie.cli.list },
      { label: company ? company.latestVersion?.raisonSociale : trans(t, 'company') }
    ];
    appState.setBreadcrumb(breadcrumb);

    // Check hub subscription.
    formState.setFieldData(formKey, 'userId', null, userState.id());
    subscribeToHub(apim, formState);

    if (dossier?.id || company?.id) return;

    appState.setPageActions([]);
  }, [dossier?.id, company?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Initial load of modules / types.
  useEffect(() => {
    setLoading(true);
    apim.fetchEntities({
      resourceType: 'documentTypes',
      params: [
        {label: 'order[name]', value: 'asc'},
        {label: 'itemsPerPage', value: 500}
      ],
      setter: setTypes
    } as IRequestParams).then(() => {
      apim.fetchEntities({
        resourceType: 'modules',
        params: [
          {label: 'exists[parent]', value: 'false'},
          {label: 'order[label]', value: 'asc'},
          {label: 'itemsPerPage', value: 500}
        ],
        setter: setModules,
        setLoading
      } as IRequestParams).then();
    });

    // Let's clean cached data.
    return () => {
      forEach(Object.keys(localStorage), (key: string) => {
        if (key.startsWith('a8form#a8_ged')) removeLocalStorageItem(key, false);
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Lazy load data from API.
  useEffect(() => {
    loadLazyData(forceCheckIssued);
  }, [lazyState, fDossiers, fCompanies, fModules, fTypes, fActive, fIssued, dossier?.id, company?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildSearchParams = (checkIssued: boolean | null = false) => {
    const params: IRequestParam[] = [];

    // Handle datatable filters / order / paginator
    if (lazyState.filters.global && !isEmpty(lazyState.filters.global.value)) {
      params.push({label: 'search', value: lazyState.filters.global.value});
    }

    // Handle filters.
    if (isValidUUID(dossier?.id)) {
      params.push({label: 'byDossier', value: dossier.id});
    } else {
      forEach((fDossiers ?? []), (d: any) => {
        if (isValidUUID(d?.id ?? d)) params.push({label: 'dossiers.id[]', value: d.id ?? d});
        if ('none' === d?.value) params.push({label: 'hasDossiers', value: false});
      });
    }

    if (isValidUUID(company?.id)) {
      params.push({label: 'companies.id[]', value: company.id});
    } else {
      forEach((fCompanies ?? []), (c: any) => {
        if (isValidUUID(c?.id ?? c)) params.push({label: 'companies.id[]', value: c.id ?? c});
        if ('none' === c?.value) params.push({label: 'hasCompanies', value: false});
      });
    }

    forEach((fModules ?? []), (m: any) => {
      if (isValidUUID(m?.id ?? m)) params.push({label: 'modules.id[]', value: m?.id ?? m});
      if ('none' === (m?.id ?? m)) params.push({label: 'hasModules', value: false});
    });
    forEach((fTypes ?? []), (t: any) => {
      if (isValidUUID(t?.id ?? t)) params.push({label: 'typeId[]', value: t?.id ?? t});
      if ('none' === (t?.id ?? t)) params.push({label: 'hasType', value: false});
    });

    if (null !== fActive) {
      params.push({label: 'active', value: fActive});
    }
    if (null !== fIssued) {
      params.push({label: 'issued', value: true === checkIssued ? true : fIssued});
    } else {
      if (true === checkIssued) params.push({label: 'issued', value: true});
    }

    return params;
  };

  const loadLazyData = (checkIssued: boolean | null = false) => {
    setLoading(true);

    const params: IRequestParam[] = [
      {label: 'page', value: lazyState.page + 1},
      {label: 'itemsPerPage', value: lazyState.rows},
      {label: 'order[' + lazyState.sortField + ']', value: lazyState.sortOrder === -1 ? 'desc' : 'asc'},
    ];

    // Handle datatable filters / order / paginator
    forEach((buildSearchParams(checkIssued)), (p: IRequestParam) => params.push(p));

    apim.fetchEntities({
      resourceType: 'documentsIndex',
      params,
      setLoading,
      cache: false,
      success: (res: AxiosResponse) => {
        if (!res?.data) return;

        // Handle issued check first.
        if (forceCheckIssued) {
          setForceCheckIssued(false);

          if ((res.data['hydra:member'] ?? []).length === 0) {
            // Let's trigger a new request if none of document are issued
            // by simply updating the filter state to null.
            return setFIssued(null);
          }
        }

        setTotalRecords(res.data['hydra:totalItems']);
        const _data: any[] = res.data['hydra:member'].map((r: any) => ({
          ...r,
          ...{ dateSort: dateString(r.updated, 'YYYY-MM-DD HH:mm:ss') }
        }));

        formState.setFieldData(formKey, 'files', parentKeyGed, _data);
      }
    } as IRequestParams).then();
  };

  const onPage = (event: any) => {
    event.filters = lazyState.filters;
    setlazyState({...lazyState, ...event});

    // Add the page parameter to the URL.
    let _searchParams = new URLSearchParams(searchParams);
    _searchParams.set('page', event.page);
    _searchParams.set('perPage', event.rows);
    setSearchParams(`?${_searchParams}`)
  };

  const onSort = (event: any) => {
    event.first = 0;
    event.page = 0;
    event.filters = lazyState.filters;
    setlazyState({...lazyState, ...event});

    // Add the sort parameter to the URL.
    let _searchParams = new URLSearchParams(searchParams);
    _searchParams.set('sortField', event.sortField);
    _searchParams.set('sortOrder', (event.sortOrder === -1 ? 'DESC' : 'ASC'));
    setSearchParams(`?${_searchParams}`)
  };

  const onGlobalFilter = (event: any) => {
    const value = event.target.value;
    if (value.length < 2) return;

    let _ls = { ...lazyState };
    _ls.filters['global'].value = value;

    if (globalSearchTimer) {
      clearTimeout(globalSearchTimer);
    }

    globalSearchTimer = setTimeout(() => {
      _ls.first = 0;
      _ls.page = 0;
      setlazyState(_ls);

      // Add the search parameter to the URL.
      let _searchParams = new URLSearchParams(searchParams);
      if (value.length > 0) {
        _searchParams.set('search', value);
        _searchParams.delete('page');
      } else {
        _searchParams.delete('search');
      }

      setSearchParams(`?${_searchParams}`)
    },500);
  };

  const onPreviewClick = (file: any) => {
    setDocumentPreview(null);

    const doc: any = getFile(formState, file.id, parentKeyGed);
    if (doc?.status === 'unloaded' || doc?.fileStatus === 'unloaded') {
      // We need to load the document.
      // When the document is not loaded yet (from Pappers, etc.).
      apim.call({
        resourceType: 'documents',
        action: 'load',
        id: doc.id,
        method: 'get',
        cache: false,
        success: (res: AxiosResponse) => {
          if (!res?.data) return;

          setDocumentPreview({...doc, ...{uri: res?.data.url}});
        }
      } as IRequestParams).then();

    } else if ((doc.urlExpiration === null || doc.urlExpiration === undefined) || Date.now() > asDate(doc.urlExpiration).getTime()) {
      // Document URL is expired and must be regenerated.
      // Call the document endpoint to refresh the URL.
      apim.fetchEntity({
        resourceType: 'documents',
        id: doc.id,
        cache: false,
        success: (res: AxiosResponse) => {
          if (!res?.data) return;

          setDocumentPreview({...doc, ...{uri: res?.data.url}});
        }
      } as IRequestParams).then();

    } else {
      // We can use the indexed document URL freely.
      setDocumentPreview({...doc, ...{uri: doc.url}});
    }

    setModalVisible(true);
  };

  const download = (file: any) => {
    const _download = (_file: any) => {
      const link = document.createElement('a');
      link.href = _file.url;
      link.download = _file.filename;
      link.target = '_blank';
      link.rel = 'noreferrer';

      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    };

    const doc: any = getFile(formState, file.id, parentKeyGed);
    if (doc?.status === 'unloaded' || doc?.fileStatus === 'unloaded') {
      // We need to load the document.
      // When the document is not loaded yet (from Pappers, etc.).
      apim.call({
        resourceType: 'documents',
        action: 'load',
        id: doc.id,
        method: 'get',
        cache: false,
        success: (res: AxiosResponse) => {
          if (!res?.data) return;

          _download({...doc, ...{url: res?.data.url}});
        }
      } as IRequestParams).then();

    } else if (doc?.urlExpiration !== null && Date.now() > asDate(doc?.urlExpiration).getTime()) {
      // Document URL is expired and must be regenerated.
      // Call the document endpoint to refresh the URL.
      apim.fetchEntity({
        resourceType: 'documents',
        id: doc.id,
        cache: false,
        success: (res: AxiosResponse) => {
          if (!res?.data) return;

          _download({...doc, ...{url: res?.data.url}});
        }
      } as IRequestParams).then();

    } else {
      // We can use the indexed document URL freely.
      _download(doc);
    }
  };

  const [uploadProgress, setUploadProgress] = useState<any[]>([]);
  const onDrop = (droppedFiles: any) => {
    formState.setLoading(formKey, true);
    let filesCount = 0;

    droppedFiles.map((file: any) => {
      filesCount++;

      if (getFiles(formState, parentKeyUpload, false, false).filter((f: any) => f.name === file.name).length === 0) {
        // Post (async) the file to APIM.
        const formData = new FormData();
        formData.append('file[]', file, file.name);
        formData.append('type', 'document');
        apim.postEntity({
          resourceType: 'fileUploads',
          data: formData,
          notif: false,
          paramType: 'postParams',
          headers: {'A8-Context': JSON.stringify({
              type: 'GED',
              userId: formState.getFieldData(formKey, 'userId')
            })},
          progress: (event: any) => {
            const _uploadProgress = uploadProgress;
            _uploadProgress[file.name] = (event.loaded / event.total) * 100;
            setUploadProgress(_uploadProgress);
          },
          success: (res: AxiosResponse) => {
            if (!res?.data?.id) return;

            const _file: any = {
              id: res.data.id,
              path: file.path,
              name: file.name,
              source: 'local',
              date: new Date().toISOString(),
              dateSort: new Date().toISOString(),
              active: false,
              status: 'uploading'
            };

            // Pre-fill dossier & company fields
            if (company?.id) {
              _file.companies = [{...company, ...{name: company.latestVersion?.raisonSociale}}];
            }
            if (dossier?.id) {
              _file.dossiers = [dossier];
            }

            updateFile(formState, _file);
          }
        } as IRequestParams).then();
      }

      if (filesCount === droppedFiles.length) {
        formState.setLoading(formKey, false);
        // Tricky DT refresh :p
        setUploadProgress(uploadProgress);
      }

      return true;
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    minSize: 0,
    maxSize: apimConfig.default.maxUploadSize * 1000000
  });

  const droppedFiles = () =>
      getFiles(formState, parentKeyUpload, false,false).length > 0 && (
        <div>
          <div className={'font-weight-bold text-center'}>
            {trans(t, 'system|dropzoneFilesToUploadTitle')}
          </div>

          <UploadedFiles formKey={formKey} formState={formState} apim={apim} uploadProgress={uploadProgress} types={types}/>
        </div>
      )
  ;

  // Body templates.
  const dossierBodyTemplate = (rowData: any) => multilinesCell(rowData.dossiers, 'name');
  const companyBodyTemplate = (rowData: any) => multilinesCell(rowData.companies, 'name');
  const labelBodyTemplate = (rowData: any) => {
    return <>
      {rowData?.deleting ? (
        <div className={'first-line flex align-items-center'}>
          <span className={'mr-3 qe-icon-wrapper'}><i className={'pi pi-spin pi-spinner'}/></span>
          <Button label={rowData?.type?.name ?? rowData.title ?? rowData.filename} link onClick={() => onPreviewClick(rowData)} className={'text-left'}/>
        </div>
      ) : (
        <div className={'flex'}>
          {!isClient() && rowData?.type?.name?.includes('FEC') && (
            <div className={'flex align-items-center'}>
              <Button className={'a8-analyze-btn'} icon={'pi pi-replay'} rounded text
                      disabled={!rowData?.type?.name?.includes('FEC')}
                      aria-label={ucfirst(trans(t, 'system|triggerAnalyze'))} onClick={() => {
                dialog(t, {
                  message: trans(t, 'system|analyze.confirmation.fec'),
                  accept: () => {
                    apim.patchEntity({
                      resourceType: 'documents',
                      id: rowData?.id,
                      data: {
                        metadata: {
                          trigger_fec_analyze: new Date().toISOString(),
                          trigger_fec_analyze_override: true
                        }
                      },
                    } as IRequestParams).then();
                  }
                })
              }}
              />
              <Tooltip target={'.a8-analyze-btn'} position={'right'} content={ucfirst(trans(t, 'system|triggerAnalyze'))}/>
            </div>
          )}

          <div className={'flex flex-column justify-content-center'}>
            <Button className={'text-left p-0'} style={{wordBreak: 'break-all'}} label={rowData?.type?.name ?? rowData.title ?? rowData.filename} link onClick={() => onPreviewClick(rowData)}/>
            {rowData?.type?.name && rowData?.title && (
              <div className={classNames('text-sm', 'mt-2')}>{rowData?.title}</div>
            )}
            {!rowData?.type?.name && (
              <div className={classNames('text-xs text-danger mt-2')}>{trans(t, 'missingType')}</div>
            )}
          </div>
        </div>
      )}
    </>
  }
  const filenameBodyTemplate = (rowData: any) => {
    return <div className={'block text-xs'} style={{wordBreak: 'break-all'}}>{rowData.filename}</div>;
  }
  const sizeBodyTemplate = (rowData: any) => <span>{rowData.size > 0 ? humanFileSize(rowData.size, true, 2) : 'N/A'}</span>;
  const dateBodyTemplate = (rowData: any) => dateTimeCell(rowData?.date, { format: rowData?.type?.dateYear === true ? 'YYYY' : 'DD/MM/YYYY' });
  const createdBodyTemplate = (rowData: any) => dateTimeCell(rowData?.created);
  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);
  const downloadBodyTemplate = (rowData: any) => {
    return <div className={'flex flex-row'}>
      <div className={'flex'}>
        <Button className={'a8-download-btn'} icon={'pi pi-cloud-download'} rounded text
                aria-label={ucfirst(trans(t, 'system|download'))} onClick={() => {download(rowData)}}/>
        <Tooltip target={'.a8-download-btn'} position={'right'} content={ucfirst(trans(t, 'system|download'))}/>
      </div>
    </div>;
  }

  // Editor templates.
  const resetDefault = () => {
    setlazyState({
      first: 0,
      rows: appConfig.pagination.default.itemsPerPage,
      page: 0,
      sortField: 'updated',
      sortOrder: -1,
      filters: { global: { value: null, matchMode: FilterMatchMode.CONTAINS } }
    });
    setFDossiers([]);
    setFCompanies([]);
    setFModules([]);
    setFTypes([]);
    setFActive(isSuperAdmin()? null : true);
    setFIssued(null);
    setSelected([]);
  };

  const getRowType = (_row: any, fullObject: boolean = false) => {
    if (isValidUUID(_row?.type?.id)) return fullObject ? _row.type : _row.type.id;

    if (isValidUUID(_row?.type ?? _row)) {
      const match: any[] = types.filter((_t: any) => _t.id === (_row?.type ?? _row));

      if (match.length > 0) return fullObject ? match[0] : match[0].id;
    }

    return null;
  };

  const typeEditorTemplate = (options: any) =>
    <div className={'flex flex-column align-items-center'}>
      <div className={'w-full'}>{staticListEditor(options, {
        label: trans(t, 'menu|tag.filetype'),
        resourceType: 'documentTypes',
        optionLabel: 'name',
        value: getRowType(options?.rowData),
        optionValue: 'id',
        multiple: false,
        listsOptions: {types},
        listKey: 'types',
        itemTemplate: typeEditorItemTemplate
      })}</div>
    </div>
  ;
  const typeEditorItemTemplate = (option: any, props: any) => {
    if (option) {
      return (
        <div className={'flex align-items-center'}>
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const dossiersEditorItemTemplate = (option: any, props: any) => {
    if (option) {
      return (
        <div className={'flex align-items-center'}>
          <div>{option.entrepreneurName}</div>
          {option.identifier && (<small className={'pl-2'}>({option.identifier})</small>)}
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const companiesEditorItemTemplate = (option: any, props: any) => {
    if (option) {
      return (
        <div className={'flex align-items-center'}>
          <div>{option.name}</div>
          {option.siren && (<small className={'pl-2'}>({option.siren})</small>)}
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const header = (
    <div>
      <div className={'flex flex-column md:flex-row md:flex-wrap md:justify-content-start md:align-items-center mb-4 gap-3'}>
        <Button id={'delete-selected-ged'} type={'button'} severity={'danger'} icon={'pi pi-trash'} outlined disabled={(selected || []).length === 0} onClick={() => {
          dialog(t, {
            message: ucfirst(trans(t, 'table|deleteSelected')),
            accept: () => {
              const _data: any[] = getFiles(formState, parentKeyGed).filter((f: any) => !includes((selected || []).map((s: any) => s.id), f.id));
              forEach(selected || [], ((d: any) => _data.push({...d, ...{deleting: true}})));
              formState.setFieldData(formKey, 'files', parentKeyGed, _data);
              forEach(selected || [], ((d: any) => removeFile(apim, formState, d, parentKeyGed)));
            },
            acceptClassName: 'p-button-danger',
            rejectClassName: 'p-button-text p-button-primary'
          });
        }}/>
        <Tooltip target={'#delete-selected-ged'} position={'right'} content={ucfirst(trans(t, 'table|deleteSelected'))}/>

        <span className={'block mt-2 md:mt-0 p-input-icon-left'}>
          <i className={'pi pi-search'}/>
          <InputText type={'search'} onChange={onGlobalFilter} placeholder={trans(t, 'search')}/>
        </span>

        {!dossier?.id && !isClient() && (
          <UncontrolledAPIAutocompleteField fieldKey={'dossier'} onFieldChange={(value: any) => setFDossiers(value ?? [])} label={trans(t, 'dossier', 2)} resourceType={'dossiersIndex'}
                                            placeholder={ucfirst(trans(t, 'form|select_dossier'))} optionKey={'entrepreneurName'} value={fDossiers} itemTemplate={dossiersEditorItemTemplate} multiple
                                            formatter={(_arr: any[]) => [...[{entrepreneurName: ucfirst(trans(t, 'nrFull')), value: 'none'}], ..._arr]}
                                            params={[{label: 'order[active]', value: 'asc'}, {label: 'order[entrepreneurName]', value: 'asc'}, (company?.id ? {label: 'companies.id', value: company?.id} : {})]}/>
        )}

        {!(company?.id || (dossier?.id && appState.dossier()?.companies?.length === 1)) && (
          <UncontrolledAPIAutocompleteField fieldKey={'company'} onFieldChange={(value: any) => setFCompanies(value ?? [])} label={trans(t, 'company', 2)} multiple
                                            formatter={(_arr: any[]) => [...[{name: ucfirst(trans(t, 'nrFull')), value: 'none'}], ..._arr]}
                                            placeholder={ucfirst(trans(t, 'form|select_company'))} optionKey={'name'} value={fCompanies} itemTemplate={companiesEditorItemTemplate}
                                            resourceType={'dossierCompaniesIndex'} params={[{label: 'order[name]', value: 'asc'}, (dossier?.id ? {label: 'dossiers.id', value: dossier?.id} : {})]}/>
        )}

        {modules?.length > 1 && (
          <UncontrolledStaticListField fieldKey={'module'} onFieldChange={(value: any) => setFModules(value ?? [])} label={trans(t, 'brique', 2)} resourceType={'modules'}
                                       placeholder={ucfirst(trans(t, 'form|select_brique'))} optionLabel={'label'} optionValue={'id'} value={fModules} multiple
                                       listsOptions={{modules: [...[{label: ucfirst(trans(t, 'nrFull')), id: 'none'}], ...modules]}} listKey={'modules'} classes={'max-w-15rem'} />
        )}

        {types?.length > 1 && (
          <UncontrolledStaticListField fieldKey={'type'} onFieldChange={(value: any) => setFTypes(value ?? [])} label={trans(t, 'type', 2)} resourceType={'documentTypes'}
                                       placeholder={ucfirst(trans(t, 'form|select_type'))} optionLabel={'name'} optionValue={'id'} value={fTypes} multiple
                                       listsOptions={{types: [...[{name: ucfirst(trans(t, 'nrFull')), id: 'none'}], ...types]}} listKey={'types'} classes={'max-w-15rem'} />
        )}

        {isAdmin() && (
          <UncontrolledTriStateField fieldKey={'active'} onFieldChange={(value: any) => setFActive(value)} label={ucfirst(trans(t, 'active'))} value={fActive}/>
        )}
        <UncontrolledCheckboxField classes={'flex-auto'} fieldKey={'issued'} onFieldChange={(value: any) => setFIssued(value)} label={ucfirst(trans(t, 'a_trier'))} value={fIssued}/>

        <Button id={'clear-filters-ged'} className={'align-self-end'} type={'button'} severity={'info'} icon={'pi pi-filter-slash'} outlined onClick={resetDefault}/>
        <Tooltip target={'#clear-filters-ged'} position={'left'} content={ucfirst(trans(t, 'table|clearFilters'))}/>
      </div>

      {getFiles(formState, parentKeyGed).filter((f: any) => !f.complete).length > 0 && (
        <div className={'flex justify-content-center'}>
          <Message severity={'warn'} className={'px-5 py-2 mb-2 rounded-md w-full'} text={ucfirst(trans(t, 'ged_issued_documents_text'))}/>
        </div>
      )}
    </div>
  );

  const isSelectable = (data: any) => (data.status !== 'uploading' && !data.deleting);
  const isRowSelectable = (event: any) => (event.data ? isSelectable(event.data) : true);
  const rowClassName = (data: any) => (isSelectable(data) ? 'un-highlight' : 'un-highlight p-disabled');

  const onRowEditComplete = (e: any) => {
    const { data, newData, index } = e;

    const patched: any = {};
    const patchedFields: string[] = ['dossiers', 'companies', 'type', 'date', 'description', 'active'];

    forEach(patchedFields, ((field: any) => {
      if (undefined !== newData[field] && data[field] !== newData[field]) {
        if (['type', 'companies', 'dossiers'].includes(field)) {
          if ('type' !== field && isEqual(
            (newData[field] ?? []).map((c: any) => c.id).sort((a: any, b: any) => a > b ? -1 : 1),
            (data[field] ?? []).map((c: any) => c.id).sort((a: any, b: any) => a > b ? -1 : 1)
          )) return;

          switch (field) {
            case 'type':
              if (data[field] && (newData[field]?.id ?? newData[field]) === data[field].id) return;

              patched.type = iri('documentTypes', newData[field]?.id ?? newData[field]);
              break;
            case 'companies': patched.companies = newData[field].map((c: any) => iri('dossierCompanies', c.id)); break;
            case 'dossiers': patched.dossiers = newData[field].map((d: any) => iri('dossiers', d.id)); break;
          }
        } else {
          patched[field] = newData[field];
        }
      }
    }));

    if (!newData?.id) return;

    /**
     * Terminate a row edit.
     * Updates the edited row data to display changes.
     */
    const terminateRowEdit = () => {
      let _currentPageRows = getFiles(formState, parentKeyGed, false, false);
      let _currentRowIndex = index - (lazyState.first ?? 0);
      let _rows: any[] = [..._currentPageRows];

      _rows[_currentRowIndex] = {
        ...omit(newData, ['dossiers', 'companies', 'type']),
        ...omit(patched, ['type']),
        ...{
          dossiers: (newData.dossiers ?? []).map((d: any) => {
            return {
              ...d,
              ...{ identifier: d.identifier ?? d.id }
            }
          }),
          companies: (newData.companies ?? []).map((c: any) => {
            return {
              ...c,
              ...{ name: c.name ?? c.raisonSociale }
            }
          }),
          type: getRowType(newData, true)
        }
      };
      _rows[_currentRowIndex].complete = isValidUUID(_rows[_currentRowIndex].type?.id) && _rows[_currentRowIndex].date && (_rows[_currentRowIndex].companies.length > 0 || _rows[_currentRowIndex].dossiers.length > 0);
      formState.setFieldData(formKey, 'files', parentKeyGed, _rows);
    };

    const handleCompaniesEdit = () => {
      return new Promise((resolve, reject) => {
        if (patched.companies) {
          // Reset the dossier company documents.
          apim.call({
            resourceType: 'dossierCompanyDocuments',
            method: 'post',
            action: 'reset',
            cache: false,
            notif: false,
            data: {
              document: newData.id,
              companies: patched.companies
            },
            success: (res: AxiosResponse) => {
              resolve(res.data.dossierCompanyDocuments);
            },
            error: (error: AxiosError) => {
              reject(error);
            },
          } as IRequestParams).then();
        } else {
          resolve([]);
        }
      });
    };

    const handleDossiersEdit = () => {
      return new Promise((resolve, reject) => {
        if (patched.dossiers) {
          // Reset the dossier documents.
          apim.call({
            resourceType: 'dossierDocuments',
            method: 'post',
            action: 'reset',
            cache: false,
            notif: false,
            data: {
              document: newData.id,
              dossiers: patched.dossiers
            },
            success: (res: AxiosResponse) => {
              resolve(res.data.dossierDocuments);
            },
            error: (error: AxiosError) => {
              reject(error);
            },
          } as IRequestParams).then();
        } else {
          resolve([]);
        }
      });
    };

    if (!isEmpty(patched)) {
      // Update the document entity.
      apim.patchEntity({
        resourceType: 'documents',
        id: newData?.id,
        data: patched,
        notif: false,
        success: (res: AxiosResponse) => {
          (async function() {
            try {
              const dossierPromise = handleDossiersEdit();
              const companiesPromise = handleCompaniesEdit();

              // Async update dossiers & companies which are linked to the document.
              Promise.all([dossierPromise, companiesPromise]).then(() => {
                // When done, update the row data & show message.
                terminateRowEdit();
                apim.toast('success')?.current?.show({
                  severity: 'success',
                  summary: trans(t, 'system|success.summary.default'),
                  detail: trans(t, 'system|success.details.default'),
                  life: 4000
                });
              });

            } catch (e) {
              console.error('error while updating document : ', e);
              apim.toast('error')?.current?.show({
                severity: 'error',
                summary: trans(t, 'system|error.summary.default'),
                detail: trans(t, 'system|error.details.default'),
                life: 4000
              });
            }
          })();
        },
        error: (error: AxiosError) => {
          onApiError(t, error, apim.toast('error'), getErrorMessage(t, error));
        },
      } as IRequestParams).then();
    }
  };

  return (
    <div className={'card fadein animation-duration-500'}>
      <div className={'grid p-fluid'}>
        {!formState.isLoading(formKey) ? (
          <>
            <div className={'col-12'}>
              <div className={'col-12 d-flex justify-content-center align-items-center'}>
                {droppedFiles()}
              </div>

              <div className={'dropzone'}>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />

                  <div className={'dz-message bgh-primary-lightest animated-non-stop'}>
                    <img alt={'upload'} className={'dz-teaser-img'} src={cloudFile as string} />

                    <div className={'dx-text'}>
                      {!isDragActive && trans(t, 'system|dropzoneMessageDefault')}
                      {isDragActive && trans(t, 'system|dropzoneMessageActive')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <Loader text={trans(t, 'system|loading')} />
        )}
      </div>

      <div className={'grid fadein animation-duration-500'}>
        <div className={'col-12'}>
          <div className={'card'}>
            <DataTable
              header={header} stripedRows value={getFiles(formState, parentKeyGed, false, false) as any} lazy dataKey={'id'} loading={loading} className={'datatable-responsive'}
              paginator first={lazyState.first} rows={lazyState.rows} totalRecords={totalRecords} rowsPerPageOptions={appConfig.pagination.default.rowsPerPageOptions}
              paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'}
              currentPageReportTemplate={trans(t, 'result', (totalRecords ?? 1)) + ' {first} à {last} (sur ' + (totalRecords ?? 0) + ')'} emptyMessage={trans(t, 'document', 0)}
              onPage={onPage} onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder} filterDelay={300}
              globalFilterFields={['filename', 'extension', 'dossiers.title', 'companies.name', 'type.name', 'date', 'active']} editMode={'row'} onRowEditComplete={onRowEditComplete}
              selectionMode={'checkbox'} selection={selected} onSelectionChange={(e: any) => setSelected(e.value)} isDataSelectable={isRowSelectable} rowClassName={rowClassName}>
              <Column selectionMode={'multiple'} headerStyle={{ width: '3rem' }}/>
              <Column body={downloadBodyTemplate} align={'center'} style={{ width: '60px', maxWidth: '60px' }} />
              <Column field={'type'} sortField={'type.name'} header={trans(t, 'file')} sortable body={labelBodyTemplate} style={{ maxWidth: '250px', width: '250px' }}
                      editor={typeEditorTemplate}/>
              <Column field={'date'} header={trans(t, 'date')} sortable body={dateBodyTemplate} align={'center'} alignHeader={'center'} style={{ width: '120px', minWidth: '120px' }}
                      editor={(options) => maskEditor(options, {label: trans(t, 'date'), isDate: true})}/>
              <Column field={'description'} header={trans(t, 'description')}
                      editor={(options) => textEditor(options, {label: trans(t, 'description')})}/>
              <Column field={'extension'} header={trans(t, 'extensionShort')} sortable align={'center'} style={{ width: '100px' }}/>
              <Column field={'size'} header={trans(t, 'size')} align={'center'} sortable body={sizeBodyTemplate} style={{ width: '100px' }} />
              {isAdmin() ? <Column field={'filename'} header={trans(t, 'filename')} sortable align={'center'} body={filenameBodyTemplate}
                                   style={{ width: '100px', maxWidth: '100px' }}/> : ''}
              <Column field={'created'} header={trans(t, 'upload_date')} sortable align={'center'} body={createdBodyTemplate} style={{ width: '225px' }}/>
              {!isClient() && (
                <Column field={'dossiers'} header={trans(t, 'dossier', 2)} body={dossierBodyTemplate} style={{ width: '250px' }}
                        editor={(options) => apiListAutocompleteEditor(options, {
                          label: trans(t, 'dossier', 2),
                          resourceType: 'dossiersIndex',
                          optionKey: 'name',
                          multiple: true,
                          displayMaxChar: 20,
                          itemTemplate: dossiersEditorItemTemplate,
                          forceSelection: false
                        })}/>
              )}
              <Column field={'companies'} header={trans(t, 'company', 2)} body={companyBodyTemplate} style={{ width: '250px' }}
                      editor={(options) => apiListAutocompleteEditor(options, {
                        label: trans(t, 'company', 2),
                        resourceType: 'dossierCompaniesIndex',
                        params: isValidUUID(dossier?.id) ? [{label: 'dossiers.id', value: dossier?.id}] : [],
                        optionKey: 'name',
                        multiple: true,
                        displayMaxChar: 20,
                        itemTemplate: companiesEditorItemTemplate,
                        forceSelection: false
                      })}/>
              {isAdmin() ? <Column field={'active'} header={trans(t, 'active')} sortable dataType={'boolean'} align={'center'} body={activeBodyTemplate}
                                   editor={(options) => simpleCheckboxEditor(options, {label: trans(t, 'active')})} style={{ width: '100px' }}/> : ''}
              <Column rowEditor align={'center'} style={{ maxWidth: '120px', width: '120px' }}/>
            </DataTable>
          </div>
        </div>
      </div>

      <Dialog content={''} ref={modalRef} header={ucfirst(trans(t, 'file_preview'))} visible={modalVisible} style={{ width: '80vw', height: '100%' }} onHide={() => setModalVisible(false)}>
        <DocumentViewer documentPreview={documentPreview} viewerRef={viewerRef}/>
      </Dialog>
    </div>
  );
};
