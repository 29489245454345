import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { dateString, isValidUUID, montant, trans, ucfirst } from 'utilities';
import { IRequestParams, isClient } from 'services';
import { getModuleId, PageLoader, Userpicture } from 'components';

import { isEmpty, replace, startCase, toLower } from 'lodash';
import appUri from 'config/appUri.json';

export const PatrimoineSuccession = (props: any) => {
  const { id, appState, apim, modulesMenu, lastUpdate } = props;
  const { t } = apim.di();

  const dossier = appState.dossier();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [successionOption, setSuccessionOption] = useState<any>( null);
  const [translatedSuccessionOption, setTranslatedSuccessionOption] = useState<any>( null)
  const [successionDonationOptions, setSuccessionDonationOptions] = useState<any[]>( []);
  const [successionDonationOptionsLoading, setSuccessionDonationOptionsLoading] = useState<boolean>(false);

  // Override Page Header (defaults into ModuleWrapper then ReservesRouter).
  useEffect(() => {
    appState.setBreadcrumb([
      isClient()
        ? { label: trans(t, 'dossier', 2) }
        : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier.title, to: replace(appUri.dos.page, ':id', id)},
      { label: trans(t, 'menu|menu.schema_flux.patrimoine') }
    ]);

    appState.setModule(getModuleId(modulesMenu, 'patrimoine.succession'));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = useCallback(async () => {
    if (!appState.patrimoine()) return null;

    setLoading(true);
    apim.call({
      resourceType: 'dossiers',
      action: 'module',
      id: id + '|' + getModuleId(modulesMenu, 'patrimoine.succession'),
      method: 'get',
      params: [{label: 'patrimoine', value: appState.patrimoine()}],
      cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
      setLoading,
      success: (res: AxiosResponse) => setData({...res.data, ...{
          patrimoine: ((res?.data?.metadata['hydra:member'] ?? []).filter((c: any) => c.key === 'patrimoine'))[0]?.data ?? null,
          heritiers: ((res?.data?.metadata['hydra:member'] ?? []).filter((c: any) => c.key === 'heritiers'))[0]?.data ?? [],
        }})
    } as IRequestParams);
  }, [appState.patrimoine(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData().then();
  }, [appState.patrimoine(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!appState.patrimoine()) return
    apim.getList('successionDonationOptions', {setter: setSuccessionDonationOptions, setLoading: setSuccessionDonationOptionsLoading} as IRequestParams).then();
    apim.fetchEntities({
      resourceType: 'patrimoineFoyers',
      params: [
        {label: 'patrimoine', value: appState.patrimoine()},
      ],
      cache: false,
      success: (res: AxiosResponse) => {
        setSuccessionOption(res?.data?.['hydra:member']?.[0]?.successionOptionSurvivant)
      }
    } as IRequestParams).then();
  }, [appState.patrimoine()]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!successionOption || !successionDonationOptions) return
    setTranslatedSuccessionOption(successionDonationOptions?.find(option => option.value === successionOption)?.translated);
  }, [successionOption, successionDonationOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader/>;

  return (
    <div className={'flex flex-column fadein animation-duration-500 flex-auto'}>
      <div className={'flex justify-content-center mt-3'}>
        {!successionDonationOptionsLoading && (translatedSuccessionOption ? <span>{`${trans(t, 'heritierHelper1')} ${startCase(toLower(dossier?.title))}${trans(t, 'heritierHelper2')} "${translatedSuccessionOption.toLowerCase()}".`}</span>
          : <span>{trans(t, 'incompletePatrimoine')}</span>)}
      </div>
      <div className={'flex flex-colum md:flex-row flex-wrap gap-5 flex-auto justify-content-center'}>
        <div className={'flex flex-column mt-5 mr-5'}>
          <h3 className={'text-xl m-0 mb-3 uppercase'}>{ucfirst(trans(t, 'doit'))}</h3>
          <h3 className={'text-xl m-0 mb-3 uppercase'}>{ucfirst(trans(t, 'recoit'))}</h3>
          <h3 className={'text-xl m-0 mb-3 uppercase'}>{ucfirst(trans(t, 'solde'))}</h3>
        </div>

        {data !== null && data.heritiers !== null && data.heritiers.length > 0 && data.heritiers.map((heritier: any, index: number) => {
          return <div key={index} className={'flex flex-column align-items-start w-2'}>
            <div className={'flex flex-row align-items-center justify-content-center mb-3 w-full'}>
              <div className={'mr-2'}>
                {heritier.sex === 'male' && (
                  <img src='/assets/images/avatar/husband.gif' style={{height: '3rem'}} alt={''}/>
                )}

                {heritier.sex === 'female' && (
                  <img src='/assets/images/avatar/woman.gif' style={{height: '3rem'}} alt={''}/>
                )}

                {heritier.sex !== 'female' && heritier.sex !== 'male' && (
                  <img src='/assets/images/avatar/profile.gif' style={{height: '3rem'}} alt={''}/>
                )}
              </div>

              <h3 className={'text-xl m-0'}>{heritier.name}</h3>
            </div>

            <div className={'card w-full'}>
              <div className={'text-xl m-0 mb-3 uppercase text-center'}>{montant(heritier.montantDu)}</div>
              <div className={'text-xl m-0 mb-3 uppercase text-center'}>{montant(heritier.montantRecu)}</div>
              <div className={'text-xl m-0 uppercase text-center text-primary font-bold'}>{montant(heritier.solde)}</div>
            </div>
          </div>;
        })}
      </div>

      <div className={'flex flex-row justify-content-end mt-4'}>
        {isValidUUID(data?.patrimoine?.author) && (
          <div className={'flex px-3 align-items-center'}>
            <strong className={'titre titre--gray pr-2'}>{ucfirst(trans(t, 'author'))}</strong>
            <div>
              <Userpicture apim={apim} user={data.patrimoine.author} size={'small'}/>
            </div>
          </div>
        )}

        {data?.patrimoine?.lastUpdate && (
          <div className={'flex px-3 align-items-center'}>
            <strong className={'titre titre--gray pr-2'}>{ucfirst(trans(t, 'lastUpdated.at'))}</strong>
            <div>
              {dateString(data?.patrimoine?.lastUpdate, 'DD/MM/YYYY') + ' ' + trans(t, 'at') + ' ' + dateString(data?.patrimoine?.lastUpdate, 'HH:mm')!.replace(':', 'h')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
