import React from 'react';

import { trans } from 'utilities';
import { AddPersonnePhysiqueForm, AddSocieteClientForm, APIListField } from 'forms';

export const AddDonationBeneficiaireForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, getValues, setValue, formKeyPrefix, context } = props;
  const { type } = getValues();

  return <div className={'grid pt-3 pr-3'} style={{width: '400px'}}>
    <APIListField listKey={'donationBeneficiaireTypes'} fieldKey={'type'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                  label={trans(t, 'type')} classes={'w-full'}/>

    {'personne_physique' === type && (
      <AddPersonnePhysiqueForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                               getValues={getValues} setValue={setValue} formKeyPrefix={formKeyPrefix} context={context}/>
    )}

    {'personne_morale' === type && (
      <AddSocieteClientForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                            getValues={getValues} setValue={setValue} formKeyPrefix={formKeyPrefix}/>
    )}
  </div>;
};
