import { trans } from 'utilities';
import { isAdmin, isClient, userId } from 'services';
import { trimStart } from 'lodash';

import appUri from 'config/appUri.json';

export const SettingsMenu = (props: any) => {
  const { apim } = props;
  const { t } = apim.di();

  return isAdmin() ? [
    {
      label: trans(t, 'menu|menu.settings.title'),
      items: [
        {
          label: trans(t, 'menu|menu.settings.list'),
          icon: 'cog',
          to: trimStart(appUri.set.general)
        },
        {
          label: trans(t, 'menu|menu.settings.status'),
          icon: 'bolt',
          to: trimStart(appUri.status.general)
        }
      ]
    }
  ] : (isClient() ? [
    {
      label: trans(t, 'menu|menu.notifications'),
      items: [
        {
          label: trans(t, 'menu|menu.mes_notifications'),
          icon: 'bell',
          to: appUri.not.list,
          badge: props.notificationState.getCountUnread(),
          badgeClassName: 'p-badge-danger'
        },
        {
          label: trans(t, 'menu|menu.mes_parametres'),
          icon: 'cog',
          to: appUri.usr.dir.edit.replace(':id', userId()) + '/notifications'
        }
      ]
    }
  ] : [])
};
