import React from 'react';
import { Link } from 'react-router-dom';

import Moment from 'react-moment';
import 'moment-timezone';

import { classNames } from 'primereact/utils';
import { formatListItem, montant, trans, ucfirst } from 'utilities';
import { isString, uniq } from 'lodash';

import appConfig from 'config/appConfig.json';
import appUri from 'config/appUri.json';

export const addressCell = (address: any) => {
  let countryLabel = address.country;
  if (address.country === 'FRA') {
    countryLabel = 'FRANCE';
  }

  return address ? <div className={'flex flex-column'}>
    <div>{address.address1}</div>
    <div className={'mt-1'}>{address.address2}</div>
    <div className={'mt-1'}>{address.postalCode}&nbsp;{address.city}</div>
    {countryLabel && (
      <div className={'mt-1'}>{countryLabel}</div>
    )}
  </div> : <></>;
};

export const amountCell = (amount: any, options: any | null = null, suffix: string | null = null) =>
  <div className={options?.classes || 'flex flex-column'}>
    {montant(amount, options)}{suffix && (suffix)}
  </div>
;

export const apiListCell = (value: any, listItems: any[], options: any | null = null) =>
  <div className={options?.classes || 'flex flex-column'}>
    {formatListItem(value, listItems)}
  </div>
;

export const companyCell = (company: any, t: any, displayLink: boolean = true) => {
  if (!company) return;

  let companyName = null;
  // Use current version if defined.
  if (company?.version) {
    if (company.version.raisonSociale?.length > 0) {
      // Use raison sociale.
      companyName = company.version.raisonSociale;

    } else if (company.version.nomCommercial?.length > 0) {
      // Use nom commercial, if no raison sociale.
      companyName = company.version.nomCommercial;
    }
  }

  // Use latest version if defined.
  if (companyName === null && company.latestVersion) {
    if (company.latestVersion.raisonSociale?.length > 0) {
      // Use raison sociale.
      companyName = company.latestVersion.raisonSociale;

    } else if (company.latestVersion.nomCommercial?.length > 0) {
      // Use nom commercial, if no raison sociale.
      companyName = company.latestVersion.nomCommercial;
    }
  }

  return <div className={'flex flex-column'}>
    {companyName !== null && (
      <div className={'font-medium'}>{companyName}</div>
    )}
    {company?.siren?.length > 0 && (
      <div className={'text-sm mt-1'}>{trans(t, 'siren')} : {company.siren}</div>
    )}
    {displayLink && (
      <a className={'block mt-1 text-sm'} href={appUri.cie.cli.page.replace(':id', company.id ?? '_')}>
        {trans(t, 'seeDetailsCompany')}
      </a>
    )}
  </div>;
};

export const dateTimeCell = (date: any, options: any | null = null) => {
  return <div className={options?.classes || 'flex flex-column'}>
    {date ? (
      <>
        <Moment utc={true} parse={options?.parse || appConfig.datetime.defaultDateFormats} tz={options?.tz || appConfig.datetime.defaultTimezone} locale={options?.locale || 'fr'} format={options?.format || 'DD/MM/YYYY HH:mm'}>
          {date}
        </Moment>

        {options?.subRow !== false && (
          <div className={'text-xs mt-1'}>
          <span>
            (</span>
            <Moment utc={true} parse={options?.parse || appConfig.datetime.defaultDateFormats} tz={options?.tz || appConfig.datetime.defaultTimezone} locale={options?.locale || 'fr'} fromNow={true}>
              {date}
            </Moment>
            <span>)
          </span>
          </div>
        )}
      </>
    ) : <>N/A</>}
  </div>
}

export const multilinesCell = (data: any[], fieldKey: string = 'label', sort: boolean = false, uniqItems = false) => {
  if (!data) return null;

  const validData: any[] = (data ?? []).filter((d: any) => d !== undefined && d !== null);
  if (validData.length === 1) return isString(validData[0]) ? validData[0] : validData[0][fieldKey] ?? null;
  if (validData.length > 1) {
    let dataNames: string[] = validData.map((vd: any) => isString(vd) ? vd : (vd[fieldKey] ?? null));

    if (uniqItems) {
      dataNames = uniq(dataNames);
    }
    if (sort) {
      dataNames = dataNames.sort((a: string, b: string) => a.localeCompare(b));
    }

    return <ul className={'list-none p-0 m-0'}>
      {dataNames.map((item: string, index: number) => {
        return <li key={index} className={'pb-1'}><span className={'block pb-1'}>{item}</span></li>;
      })}
    </ul>;
  }

  return null;
};

export const percentCell = (percentage: any, options: any | null = null) =>
  <div className={options?.classes ?? 'flex flex-column'}>
    {montant(percentage, {symbol: '%'})}
  </div>
;

export const personCell = (person: any, link: any | null = null) => {
  return person ? <div className={'flex flex-column'}>
    <div className={'person-fullname'}>{person.firstName + ' ' + person.lastName}</div>
    {person.phone !== null && person.phone !== undefined && person.phone.length > 0 && (
      <div className={'person-phone text-xs mt-1'}>{person.phone}</div>
    )}
    {person.email !== null && person.email !== undefined && person.email.length > 0 && (
      <div className={'person-email text-xs mt-1'}>{person.email}</div>
    )}
    {link && (
      <Link className={'person-link block mt-1 text-sm'} to={link.url}>{link.label}</Link>
    )}
  </div> : <></>;
};

export const rolesCell = (roles: string[], t: any) => {
  if ((roles || []).length === 0) return '';

  return <ul className={'p-0 m-0 list-none'}>
    {roles
      .filter((_r: string) => ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_EXPERT_ADMIN', 'ROLE_EXPERT', 'ROLE_CLIENT'/*, 'ROLE_USER'*/].includes(_r))
      .map((role: string, index: number) =>
        <li key={index} className={'pb-1'}>
          <span>{ucfirst(trans(t, 'system|role.' + role.toLowerCase()))}</span>
        </li>
      )
    }
  </ul>;
};

export const validCell = (data: any) =>
  <span>
    <i className={classNames('pi', {
      'text-green-500 pi-check-circle': data === true,
      'text-grey-500 pi-times-circle': data === undefined || data === null,
      'text-pink-500 pi-times-circle': data === false
    })}></i>
  </span>
;
