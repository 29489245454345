import React, { createRef, forwardRef, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { classNames } from 'primereact/utils';
import { CSSTransition } from 'react-transition-group';
import { Ripple } from 'primereact/ripple';
import { Tooltip } from 'primereact/tooltip';
import { Badge } from 'primereact/badge';

import { AdminMenu, ShortcutsMenu, MainMenu, MissionsMenu, NotificationsMenu, HelpMenu, SettingsMenu } from 'components';
import { isAdmin, isClient, isExpert, userId } from 'services';

const AppSubmenu = forwardRef((props: any, ref: any) => {
  const [activeIndex, setActiveIndex] = useState<any>(null);

  const onMenuItemClick = (event: any, item: any, index: any) => {
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    if (props.root && props.onRootItemClick) {
      props.onRootItemClick({
        originalEvent: event,
        item: item
      });
    }

    // execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
      event.preventDefault();
    }

    if (item.items) {
      setActiveIndex(activeIndex === index ? null : index);
    } else {
      if (props.menuMode !== 'static') {
        const ink = getInk(event.currentTarget);
        if (ink) {
          removeClass(ink, 'p-ink-active');
        }
      }
    }

    if (props.onMenuItemClick) {
      props.onMenuItemClick({
        originalEvent: event,
        item: item
      });
    }
  };

  const getInk = (el: any) => {
    for (let i = 0; i < el.children.length; i++) {
      if (typeof el.children[i].className === 'string' && el.children[i].className.indexOf('p-ink') !== -1) {
        return el.children[i];
      }
    }
    return null;
  };

  const removeClass = (element: any, className: string) => {
    if (element.classList) element.classList.remove(className);
    else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
  };

  // const onMenuItemMouseEnter = (index: any) => {
  //   if (props.root && props.menuActive && isSlim && !isMobile) {
  //     setActiveIndex(index);
  //   }
  // };

  const visible = (item: any) => typeof item.visible === 'function' ? item.visible() : item.visible !== false;

  const isMobile = useMemo(() => window.innerWidth < 1025, []);
  const isSlim = useMemo(() => props.menuMode === 'slim', [props.menuMode]);

  useEffect(() => {
    if (!props.menuActive && isSlim && !isMobile) {
      setActiveIndex(null);
    }
  }, [props.menuActive, props.menuMode, isMobile, isSlim]);

  const getLink = (item: any, index: any) => {
    const menuitemIconClassName = classNames('layout-menuitem-icon', 'pi pi-fw pi-' + item.icon);
    const content = (
      <>
        <i className={menuitemIconClassName}></i>
        <span className='layout-menuitem-text'>{item.label}</span>
        {(item.badge && item.badge > 0) ? (<Badge value={item.badge} style={item.badgeStyle} className={classNames(item.badgeClassName, 'p-badge-no-gutter')} />) : (<></>)}
        {item.items && <i className='pi pi-fw pi-angle-down layout-submenu-toggler'></i>}
        <Ripple />
      </>
    );
    const commonLinkProps = {
      style: item.style,
      className: classNames(item.className, 'p-ripple tooltip', {
        'p-disabled': item.disabled,
        'p-link': !item.to,
      }),
      target: item.target,
      id: item.id,
      onClick: (e: React.SyntheticEvent) => onMenuItemClick(e, item, index),
      // onMouseEnter: () => onMenuItemMouseEnter(index)
    };

    if (item.url) {
      return (
        <a data-pr-tooltip={props.root && item.label} href={item.url} rel='noopener noreferrer' {...commonLinkProps}>
          {content}
        </a>
      );
    } else if (!item.to) {
      return (
        <a data-pr-tooltip={props.root && item.label} type='button' {...commonLinkProps}>
          {content}
        </a>
      );
    }

    return (
      <NavLink
        data-pr-tooltip={props.root && item.label}
        to={item.to} {...commonLinkProps}
        className={({ isActive }) => classNames(commonLinkProps.className, isActive ? 'router-link-active' : undefined)}
      >
        {content}
      </NavLink>
    );
  };

  const getItems = () => {
    const transitionTimeout = props.root ? 0 : { enter: 1000, exit: 450 };
    return props.items.map((item: any, i: any) => {
      if (visible(item)) {
        const submenuRef = createRef();
        const active = activeIndex === i;
        let styleClass = classNames(item.badgeStyleClass, { 'active-menuitem': active }, { 'layout-root-menuitem': props.root });
        const link = getLink(item, i);
        let tooltip = props.root && (
          <div>
            <span className='layout-menuitem-text' style={{ textTransform: 'uppercase' }}>
              {item.label}
            </span>
          </div>
        );

        return (
          <li key={item.label || i} className={styleClass} role='menuitem'>
            {link}
            {tooltip}
            <CSSTransition
              // @ts-ignore
              nodeRef={submenuRef}
              classNames='layout-submenu-container'
              timeout={transitionTimeout}
              in={item.items && (props.root && !((isSlim) && !isMobile && (!isSlim || (isSlim && activeIndex !== null))) ? true : active)}
              unmountOnExit
            >
              <AppSubmenu ref={submenuRef} items={visible(item) && item.items} onMenuItemClick={props.onMenuItemClick} menuMode={props.menuMode} menuActive={props.menuActive} parentMenuItemActive={active}></AppSubmenu>
            </CSSTransition>
          </li>
        );
      }

      return null;
    });
  };

  if (!props.items) {
    return null;
  }

  const items = getItems();

  return (
    <>
      <ul ref={ref} role='menu' className={props.className}>{items}</ul>
      {isSlim && props.root && <Tooltip target='li:not(.active-menuitem)>.tooltip' />}
    </>
  );
});

export const AppMenu = (props: any) => {
  const { themeState } = props;

  // A furtive error is displayed during logout process without this.
  if (!userId()) return <></>;

  const onMenuItemClick = (event: any) => {
    if (!event.item.items && !themeState.isDesktop()) {
      themeState.toggleMobileMenu(false);
      themeState.toggleDesktopMenu(false);
    }
  };

  const menus: any[] = [MainMenu(props)];
  if (isAdmin() || isExpert()) menus.push(AdminMenu(props));
  if (isClient()) menus.push(MissionsMenu(props));
  menus.push(NotificationsMenu(props));
  if (isAdmin() || isExpert()) menus.push(ShortcutsMenu(props));
  if (isAdmin()) menus.push(SettingsMenu(props));


  return <>
    {menus.map((_items: any[], index: number) => (
      <AppSubmenu
        key={index}
        items={_items}
        className={'layout-menu layout-menu-noscroll'}
        menuActive={themeState.isMenuActive()}
        onRootItemClick={() => themeState.toggleMenu()}
        onMenuItemClick={onMenuItemClick}
        root={true}
        menuMode={themeState.menuMode()}
        parentMenuItemActive={true}
      />
    ))}

    {'1' === (process.env.REACT_APP_ENABLE_TICKET_COLLECTOR || '1') && (
      <AppSubmenu
        items={HelpMenu(props)}
        className={'layout-menu layout-menu-noscroll'}
        menuActive={themeState.isMenuActive()}
        onRootItemClick={() => themeState.toggleMenu()}
        onMenuItemClick={onMenuItemClick}
        root={true}
        menuMode={themeState.menuMode()}
        parentMenuItemActive={true}
      />
    )}
  </>
};
