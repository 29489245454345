import React, { useEffect } from 'react';

import { trans, ucfirst } from 'utilities';
import { useAppState } from 'states';
import { IRequestParam, isAdmin, isExpertAdmin, useApim } from 'services';
import { UsersExpertDatatable } from 'components';

import appUri from 'config/appUri.json';

export const ExpertsList = () => {
  const apim = useApim();
  const { t } = apim.di();
  const appState = useAppState();

  // Update Page Header.
  useEffect(() => {
    appState.setPageTitle(trans(t, 'menu|pages.title.user.experts'));
    appState.setBreadcrumb([{ label: ucfirst(trans(t, 'expert', 2)) }]);

    const newPA: any[] = [];
    if (isAdmin() || isExpertAdmin()) {
      newPA.push({ label: trans(t, 'exp.add'), icon: 'plus', to: appUri.usr.exp.add, className:'bg-green-500'});
    }
    appState.setPageActions(newPA);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const params: IRequestParam[] = [];
  ['ROLE_EXPERT', 'ROLE_EXPERT_ADMIN'].map((r: string) => {
    return params.push({label: 'roles[]', value: r})
  });

  return <UsersExpertDatatable tableKey={'users-experts'} noFilters title={trans(t, 'menu|pages.title.user.experts')} params={params} apim={apim} />;
};
