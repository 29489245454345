import React, { useEffect } from 'react';
import { Fieldset } from 'primereact/fieldset';

import { Address, APIListField, CheckboxField, MaskField, NumberField, TextField, ImageField, APIAutocompleteField } from 'forms';
import { isValidUUID, trans, ucfirst, uuidFromIri } from 'utilities';
import { useFormState } from 'states';
import { creditItemTemplate } from '../../credits';

export const BienImmobilierForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, formKey, parentKey, getValues, setValue, context } = props;
  const formState = useFormState();
  const { family, type } = getValues();
  const dossier = isValidUUID(context?.dossier?.id) ? context?.dossier?.id : uuidFromIri(context?.dossier);

  useEffect(() => {
    if (family !== 'residence_principale') {
      formState.setFieldData(formKey, 'avantageSuccessoralResidence', parentKey, false);
      setValue('avantageSuccessoralResidence', false);
    }
  }, [family]); // eslint-disable-line react-hooks/exhaustive-deps

  return <>
    <Fieldset legend={ucfirst(trans(t, 'general'))} className={'col-12 m-2'} toggleable>
      <div className={'grid w-full'}>
        <div className={'col-2'}>
          <ImageField fieldKey={'image'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} getValues={getValues} setValue={setValue}
                     label={trans(t, 'form|patrimoine.biens.default.image')} classes={'w-full'} hideLabel={true} formKey={formKey} parentKey={parentKey} />
        </div>

        <div className={'col-10'}>
          <div className={'grid w-full'}>
            <div className={'col-4'}>
              <TextField fieldKey={'label'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                         label={trans(t, 'form|patrimoine.biens.default.label')} classes={'w-full'}/>
            </div>

            <div className={'col-4'}>
              <APIListField listKey={'bienImmobilierTypes'} fieldKey={'type'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                            label={trans(t, 'form|patrimoine.biens.default.type')} classes={'w-full'} disabled/>
            </div>

            <div className={'col-4'}>
              <APIListField listKey={type === 'jouissance' ? 'bienImmobilierJouissanceFamilles' : 'bienImmobilierRapportFamilles'}
                            fieldKey={'family'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                            label={trans(t, 'form|patrimoine.biens.default.family')} classes={'w-full'}/>
            </div>
          </div>

          <div className={'grid w-full'}>
            <div className={'col-4'}>
              <APIListField listKey={'bienImmobilierClassifications'} fieldKey={'classification'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                            label={trans(t, 'form|patrimoine.biens.immobilier.classification')} classes={'w-full'}/>
            </div>

            <div className={'col-4'}>
              <MaskField fieldKey={'purchaseDate'} label={trans(t, 'form|patrimoine.biens.default.date')} addon={'calendar'} addonPosition={'after'}
                         control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} isDate classes={'w-full'}/>
            </div>

            <div className={'col-4'}>
              <NumberField fieldKey={'montantPleinePropriete'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                           label={trans(t, 'form|patrimoine.biens.default.montantPleinePropriete')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
            </div>
          </div>

          <div className={'grid w-full'}>
            <div className={'col-4'}>
              <APIListField listKey={'bienImmobilierAppartementClassifications'} fieldKey={'appartementClassification'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                            label={trans(t, 'form|patrimoine.biens.immobilier.appartementClassification')} classes={'w-full'}/>
            </div>

            <div className={'col-4'}>
              <APIListField listKey={'bienImmobilierUsages'} fieldKey={'usage'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                            label={trans(t, 'form|patrimoine.biens.immobilier.usage')} classes={'w-full'}/>
            </div>

            <div className={'col-4'}>
              <NumberField fieldKey={'surface'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} mode={'decimal'} min={0} classes={'w-full'}
                           showButtons maxFractionDigits={0} minFractionDigits={0} label={trans(t, 'form|patrimoine.biens.immobilier.surface')}/>
            </div>
          </div>

          <div className={'grid w-full'}>
            <div className={'col-4'}>
              <CheckboxField fieldKey={'transmission'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                             label={trans(t, 'form|patrimoine.biens.default.transmission')} help={trans(t, 'form|patrimoine.biens.default.transmissionHelp')}/>
            </div>

            <div className={'col-4'}>
              <CheckboxField fieldKey={'securite'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                             label={trans(t, 'form|patrimoine.biens.default.securite')} help={trans(t, 'form|patrimoine.biens.default.securiteHelp')}/>
            </div>

            <div className={'col-4'}>
              <CheckboxField fieldKey={'lieActivite'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                             label={trans(t, 'form|patrimoine.biens.default.lieActivite')} help={trans(t, 'form|patrimoine.biens.default.lieActiviteHelp')}/>
            </div>
          </div>
        </div>
      </div>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'form|patrimoine.biens.default.credit'))} className={'col-12 m-2'} toggleable>
      <div className={'col-12'}>
        <APIAutocompleteField fieldKey={'creditBiens'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'credit', 2)}
                              getValues={getValues} placeholder={ucfirst(trans(t, 'form|select'))} optionKey={'label'} itemTemplate={creditItemTemplate}
                              resourceType={'credits'} params={[{label: 'byDossierRelatives', value: dossier}]} multiple/>
      </div>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'form|patrimoine.biens.immobilier.address'))} className={'col-12 m-2'} toggleable>
      <div className={'grid w-full'}>
        <Address t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} getValues={getValues} setValue={setValue} formKey={formKey} parentKey={parentKey}/>
      </div>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'fiscal'))} className={'col-12 m-2'} toggleable>
      <div className={'grid w-full'}>
        {type !== 'jouissance' && (
          <>
            <div className={'col-3'}>
              <NumberField fieldKey={'montantRevenuBrut'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                           label={trans(t, 'form|patrimoine.biens.immobilier.montantRevenuBrut')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
            </div>

            <div className={'col-3'}>
              <NumberField fieldKey={'montantRevenuImposable'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                           label={trans(t, 'form|patrimoine.biens.immobilier.montantRevenuImposable')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
            </div>
          </>
        )}
        <div className={'col-3'}>
          <NumberField fieldKey={'montantTaxeFonciere'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'form|patrimoine.biens.immobilier.montantTaxeFonciere')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
        </div>

        <div className={'col-3'}>
          <NumberField fieldKey={'montantTaxeHabitation'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'form|patrimoine.biens.immobilier.montantTaxeHabitation')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
        </div>
      </div>

      {type !== 'jouissance' && (
        <div className={'grid w-full'}>
          <div className={'col-3'}>
            <NumberField fieldKey={'montantRevenuBrutN2'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                         label={trans(t, 'form|patrimoine.biens.immobilier.montantRevenuBrutN2')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
          </div>

          <div className={'col-3'}>
            <NumberField fieldKey={'montantRevenuImposableN2'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                         label={trans(t, 'form|patrimoine.biens.immobilier.montantRevenuImposableN2')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
          </div>
        </div>
      )}
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'civil'))} className={'col-12 m-2'} toggleable>
      <div className={'grid w-full'}>
        <div className={'col-3'}>
          <CheckboxField fieldKey={'impositionRepartieIfi'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                         label={trans(t, 'form|patrimoine.biens.default.ifi')}/>
        </div>
        <div className={'col-3'}>
          <CheckboxField fieldKey={'avantageMatrimonialPreciput'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                         label={trans(t, 'form|patrimoine.biens.default.matrimonialAdvantage')}/>
        </div>
        {family === 'residence_principale' && (
          <div className={'col-3'}>
            <CheckboxField fieldKey={'avantageSuccessoralResidence'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                           disabled={family !== 'residence_principale'}
                           label={trans(t, 'form|patrimoine.biens.immobilier.avantageSuccessoralResidence')}
                           help={trans(t, 'form|patrimoine.biens.immobilier.avantageSuccessoralResidence_detail')}/>
          </div>
        )}
      </div>
    </Fieldset>
  </>;
};
